import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
import "./SiaType.css";

const Nationalities = () => {
  const [nationality, setNationality] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [newName, setNewName] = useState("");
  
  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchNationalities();
  }, []);

  const fetchNationalities = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/nationality_show",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        throw new Error(response.data.message || "Failed to fetch nationalities");
      }
    } catch (error) {
      console.error("Error fetching nationalities:", error);
      setError("Error fetching nationalities: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNationality = async () => {
    if (nationality.trim() !== "") {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://srltd.megworld.in/api/nationality_store",
          {
            nationality_name: nationality,
            company_branch: "1",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.success) {
          setNationality("");
          Swal.fire({
            icon: 'success',
            title: 'Nationality Added',
            text: 'New nationality has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchNationalities();
        } else {
          throw new Error(response.data.message || "Failed to add nationality");
        }
      } catch (error) {
        console.error("Add Nationality Error:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An error occurred while adding nationality.'
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a nationality.'
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const response = await axios.post(
            `https://srltd.megworld.in/api/nationality_store`,
            { delete_id: id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data && response.data.success) {
            Swal.fire(
              'Deleted!',
              'The nationality has been deleted.',
              'success'
            );
            fetchNationalities(); // Refresh the table data
          } else {
            throw new Error(response.data.message || "Failed to delete nationality");
          }
        } catch (error) {
          console.error("Delete Nationality Error:", error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message || 'An error occurred while deleting nationality.'
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setNewName(data.nationality_name);
    setEditModalShow(true);
  };

  const handleSaveEdit = async () => {
    if (newName.trim() !== "") {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `https://srltd.megworld.in/api/nationality_store`,
          {
            id: editData.id,
            nationality_name: newName,
            company_branch: editData.company_branch,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data && response.data.success) {
          setEditModalShow(false);
          Swal.fire({
            icon: 'success',
            title: 'Nationality Updated',
            text: 'The nationality has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchNationalities(); // Refresh the table data
          setTableData(prevData => 
            prevData.map(item => 
              item.id === editData.id ? {...item, nationality_name: newName} : item
            )
          );
        } else {
          throw new Error(response.data.message || "Failed to update nationality");
        }
      } catch (error) {
        console.error("Edit Nationality Error:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An error occurred while updating nationality.'
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a new nationality name.'
      });
    }
  };

  return (
    <Container fluid className="sia-type-container">
      <Row>
        <Col md={4} className="sia-types-section">
          <h3>Nationalities</h3>
          <InputGroup className="Group mb-3">
            <FormControl
              placeholder="Enter Nationality Type"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            />
            <Button variant="primary" onClick={handleAddNationality} disabled={isLoading}>
              {isLoading ? 'Adding...' : 'ADD'}
            </Button>
          </InputGroup>
          {error && <p className="text-danger">{error}</p>}
        </Col>
        <Col md={8} className="table-container">
          <h3>Nationalities List</h3>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nationality</th>
                  <th>Branch</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((data,index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.nationality_name}</td>
                    <td>{data.company_branch}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEdit(data)}
                      >
                        <i className="fas fa-edit" style={{ color: "white" }}></i>
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="ms-2"
                        onClick={() => handleDelete(data.id)}
                      >
                        <i className="fas fa-trash" style={{ color: "white" }}></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Nationality</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Nationality Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter nationality name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Nationalities;