import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import Dashboard from "../../Dashboard/Dashboard";
import "./Invoice.css";
import SGSLogoImg from "../Invoice/patterns/logos/SGS-Logo-1-1024x387.webp";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';



const ViewInvoice = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const invoiceId = location.state?.id;
        if (!invoiceId) {
          setError("No invoice ID provided");
          setLoading(false);
          return;
        }
  
        const response = await axios.post(
          "https://srltd.megworld.in/api/view_invoice",
          { id: invoiceId },
          {
            headers: {
              Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
            }
          }
        );
        setInvoiceData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        setError("Failed to fetch invoice data. Please try again later.");
        setLoading(false);
      }
    };
  
    fetchInvoiceData();
  }, [location.state]);

  const getNavigationPath = () => {
    const breadcrumbPaths = [
      { name: 'Accounts', bold: false },
      { name: 'Invoice', bold: false },
      { name: 'Manage Invoice', path: '/manageinvoice', bold: false },
      { name: 'View Invoice', bold: true },
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!invoiceData) {
    return <div>No invoice data available.</div>;
  }

  const totalHours = invoiceData.shift_data.reduce((sum, item) => sum + parseFloat(item.total_time), 0);
  const totalExpenses = parseFloat(invoiceData.expense);
  const totalAmount = invoiceData.shift_data.reduce((sum, item) => sum + item.total_amount, 0);
  const totalNetAmount = parseFloat(invoiceData.net_total);
  const vat = parseFloat(invoiceData.vat);
  const totalAmountWithVAT = totalNetAmount + vat;
  const totalGrossAmount = parseFloat(invoiceData.gross_total);

  return (
    <>
      <Dashboard />
      <div className="toolbar py-3" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{ fontSize: '15px' }}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <Link
                to={part.path}
                onClick={(e) => handleClick(part.path, e)}
                style={{
                  fontWeight: part.bold ? 'bold' : 'normal',
                  color: 'white',
                  fontSize: part.bold ? '18px' : '15px',
                  textDecoration: 'none'
                }}
              >
                {part.name}
              </Link>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>

      <div className="invoice-container">
        <div className="invoice-header">
          <div className="company-info">
            <h2>{invoiceData.client_name}</h2>
            <p>{invoiceData.client_address}</p>
            <p>Mobile No: {invoiceData.client_contact_number}</p>
            <p>E-mail: {invoiceData.client_contact_email}</p>
          </div>

          <div className="image-preview-container">
            <div className="image-preview">
              <img src={SGSLogoImg} alt="" />
            </div>
          </div>

          <div className="invoice-info">
            <p>Invoice No: {invoiceData.invoice_no}</p>
            <p>Invoice Date: {new Date(invoiceData.created_at).toLocaleDateString()}</p>
            <p>Service descriptions: Security Services</p>
            <p>Service Period: {invoiceData.invoice_start_date} to {invoiceData.invoice_end_date}</p>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>SITE NAME</th>
              <th>SHIFT DATE</th>
              <th>SHIFT START TIME</th>
              <th>SHIFT END TIME</th>
              <th>SHIFT HOURS</th>
              <th>CHARGE RATE</th>
              <th>TOTAL SHIFT AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.shift_data.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{invoiceData.shift_site_name}</td>
                <td>{item.shift_start_date}</td>
                <td>{item.shift_start_time}</td>
                <td>{item.shift_end_time}</td>
                <td>{item.total_time}</td>
                <td>£{item.hr_amount}</td>
                <td>£{item.total_amount.toFixed(2)}</td>
              </tr>
            ))}
            <tr className="total-row">
              <td colSpan="5">Total</td>
              <td>{totalHours.toFixed(2)}</td>
              <td></td>
              <td>£{totalAmount.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <div className="invoice-summary">
          <table className="summary-table">
            <tbody>
              <tr>
                <td>NET AMOUNT</td>
                <td>£{totalNetAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <td>VAT @ 20%</td>
                <td>£{vat.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Amount With VAT</td>
                <td>£{totalAmountWithVAT.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Billable Expenses</td>
                <td>£{totalExpenses.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Gross Amount</td>
                <td>£{totalGrossAmount.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
       
        </div>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<VisibilityIcon />}
          >
            Export Invoice
          </Button>
        </div>
      </div>
    </>
  );
};

export default ViewInvoice;