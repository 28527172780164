import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Box 
} from '@mui/material';
import SGSLogo from  "../Logout Report/logos/SGS-Logo-1-1024x387.webp"
const PrintLayout = React.forwardRef(({ filteredLogData }, ref) => {
  return (
    <div ref={ref}>
      <style type="text/css" media="print">
        {`
          @page { size: auto; margin: 0mm; }
          body { margin: 0; }
          .print-container { width: 100vw; height: 100vh; display: flex; flex-direction: column; }
          .header { display: flex; justify-content: space-between; align-items: center; padding: 15px; }
          .logo { height: 50px;  }
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
          th { background-color: #f2f2f2; }
          .table-container { flex-grow: 1; overflow: auto; }
        `}
      </style>
      <div className="print-container">
        <div className="header">
          <img src={SGSLogo}  className="logo"/>
          <h2>Log Report</h2>
        </div>
        <div className="table-container">
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="log report table">
              <TableHead>
                <TableRow>
                  <TableCell>S.NO</TableCell>
                  <TableCell>Reference Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Message</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogData.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{log.ref_id}</TableCell>
                    <TableCell>{log.date}</TableCell>
                    <TableCell>{log.type}</TableCell>
                    <TableCell>{log.msg}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
});

export default PrintLayout;