import React, { useState } from "react";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';

const AddressInfo = () => {
  const [addressData, setAddressData] = useState({
    current_address: "",
    city: "",
    post_code: "",
    from_month: "",
    from_year: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddressData({ ...addressData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_addresses",
        {
          ...addressData,
          employee_id: 1,
          company_branch: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Address added successfully:", response.data);
      if(response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'Address Updated',
          text: 'Address has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
      setLoading(false);
      // Clear form after successful submission
      setAddressData({
        current_address: "",
        city: "",
        post_code: "",
        from_month: "",
        from_year: "",
      });
    } catch (err) {
      setError("Error adding address");
      setLoading(false);
    }
  };

  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  return (
    <Container className="px-0">
      <Row className="mx-0">
        <Col md={10} className="main-content">
          <Row>
            <Col md={9} className="form-content">
              <h3>Add Current Address</h3>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={10} className="mt-10">
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Current Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="current_address"
                        value={addressData.current_address}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={addressData.city}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Post Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="post_code"
                        value={addressData.post_code}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>From Month</Form.Label>
                      <Form.Select
                        name="from_month"
                        value={addressData.from_month}
                        onChange={handleInputChange}
                        style={inputStyle}
                      >
                        <option value="">Select Month</option>
                        <option value="Jan">January</option>
                        <option value="Feb">February</option>
                        {/* Add other months */}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>From Year</Form.Label>
                      <Form.Select
                        name="from_year"
                        value={addressData.from_year}
                        onChange={handleInputChange}
                        style={inputStyle}
                      >
                        <option value="">Select Year</option>
                        {/* Add years dynamically */}
                        {Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                          <option key={year} value={year.toString()}>
                            {year}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "dodgerblue",
                    color: "white",
                    border: "0",
                    borderRadius: "5px",
                  }}
                  disabled={loading}
                >
                  {loading ? "Adding..." : "ADD"}
                </Button>
              </Form>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressInfo;