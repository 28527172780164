import React, { useState, useEffect } from "react";
import axios from "axios";

const Addresses = () => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_addresses",
        { employee_id: "1" },
      
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response Data:",response)
      setAddresses(response.data.data);
      setLoading(false);
    } catch (err) {
      setError("Error fetching addresses");
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container">
      <h3>Address Information</h3>
      {addresses && addresses.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Current Address</th>
              <th>City</th>
              <th>Post Code</th>
              <th>From Month</th>
              <th>From Year</th>
            </tr>
          </thead>
          <tbody>
            {addresses.map((address, index) => (
              <tr key={index}>
                <td>{address.current_address}</td>
                <td>{address.city}</td>
                <td>{address.post_code}</td>
                <td>{address.from_month}</td>
                <td>{address.from_year}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No addresses available.</p>
      )}
    </div>
  );
};

export default Addresses;