import React, { useState } from 'react'
import { FormControl, InputGroup, ListGroup, Table } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";

const InvoiceInformation = () => {

  const [invoiceInfo,setInvoiceInfo] = useState(false);

  const handleShowInvoice =()=>{
    setInvoiceInfo(true);
  }
  const handleCloseInvoice =()=>{
    setInvoiceInfo(false);
  }
  return (
    <div>
    <Table striped bordered hover style={{ marginTop: "20px" }}>
      <thead>
        <tr style={{ fontSize: "16px" }}>
          <th>ID</th>
          <th>Invoice Information</th>
          <th>Branch</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ fontSize: "15px" }}>
          <td>1</td>
          <td></td>
          <td>Head Office</td>

          <td>
            <button className="btn-success" onClick={handleShowInvoice}>
              <i
                className="fas fa-pen"
                style={{
                  color: "white",
                  padding: "5px",
                  fontSize: "16px",
                }}></i>
            </button>
          </td>
        </tr>
      </tbody>
    </Table>
    
    <Modal show={invoiceInfo} onHide={handleCloseInvoice}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Invoice Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Invoice Information</Form.Label>
              <Form.Control
              as="textarea"
                style={{ width: "100%" }}
                placeholder="Invoice Information"
              />
            </Form.Group>
         
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseInvoice}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
  </div>
  
  )
}

export default InvoiceInformation
