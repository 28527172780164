import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Form } from "react-bootstrap";
import Dashboard from "../Dashboard/Dashboard";
import axios from "axios";
import { Link } from "react-router-dom";
import headerBackground from "../patterns/header-bg.png";
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const ViewSite = () => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        {},
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Fetched sites:", response.data.data);
      const fetchedSites = response.data.data || [];
      setSites(fetchedSites);
      setFilteredSites(fetchedSites);
    } catch (error) {
      console.error("Error fetching sites:", error);
      setSites([]);
      setFilteredSites([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filtered = sites.filter((site) =>
      Object.values(site).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredSites(filtered);
    setCurrentPage(0);
  }, [searchTerm, sites]);

  // useEffect(() => {
  //   if (sites.length > 0) {
  //     const filtered = sites.filter((site) =>
  //       Object.values(site).some((value) =>
  //         String(value).toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //     );
  //     setFilteredSites(filtered);
  //     setCurrentPage(1);
  //   }
  // }, [searchTerm, sites]);

  // const totalEntries = filteredSites.length;
  // const totalPages = Math.ceil(totalEntries / entriesPerPage);
  // const indexOfLastEntry = currentPage * entriesPerPage;
  // const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  // const currentEntries = filteredSites.slice(indexOfFirstEntry, indexOfLastEntry);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/site_store",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        console.log("Response:",response.data)
        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Site has been deleted.',
            'success'
          );
          fetchSites();
        } else {
          throw new Error(response.data.message || "Failed to delete Site");
        }
      }
    } catch (error) {
      console.error("Delete Site Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Site.'
      });
    }
  };
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  const [clients, setClients] = useState({});
  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      if (response.data && response.data.success) {
        const clientData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const clientMap = {};
        clientData.forEach(client => {
          clientMap[client.id] = client.client_name;
        });
        setClients(clientMap);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Sites', bold: false },
      { name: 'View Sites', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredSites);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sites");
    XLSX.writeFile(workbook, "Sites_data.xlsx");
  }
  
  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>          
        </div>
  
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>SEARCH SITE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Sites</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter site name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              CLOSE
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
              SEARCH
            </Button>
          </Modal.Footer>
        </Modal>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={(e) => setEntriesPerPage(Number(e.target.value))}
                >
                  {[10,50,100,500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
              <Button
                  variant="primary"
                  className="m-0"
                  // onClick={handleSearch}>   
                  >               
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Client Name</th>
                    <th>Site Group</th>
                    <th>Site Name</th>
                    <th>Contact Person</th>
                    <th>Contact Number</th>
                    <th>Address</th>
                    <th>Post Code</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((site, index) => (
                    <tr key={index} style={{ fontSize: "15px" }}>
                      <td>{index + 1}</td>
                      <td>{clients[site.site_client_name] || site.site_client_name}</td>                      <td>{site.site_group_name}</td>
                      <td>{site.site_name}</td>
                      <td>{site.site_contact_person}</td>
                      <td>{site.site_contact_number}</td>
                      <td>{site.site_address}</td>
                      <td>{site.site_post_code}</td>
                      <td>
                        <Link to="/sitedetails">
                        <button className=" btn-sm btn-success me-1">
                              <i
                                className="fas fa-file"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                        </Link>
                        <button className="btn btn-sm btn-danger me-1"
                                   onClick={() => handleDelete(site.id)}

                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>
                Showing {totalEntries > 0 ? indexOfFirstEntry + 1 : 0} to {Math.min(indexOfLastEntry, totalEntries)} of {totalEntries} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Group</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Person</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Address</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Postcode</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredSites
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((site, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell>{clients[site.site_client_name] || site.site_client_name}</TableCell>
                    <TableCell >{site.site_group_name}</TableCell>
                    <TableCell >{site.site_name}</TableCell>
                    <TableCell >{site.site_contact_person}</TableCell>
                    <TableCell >{site.site_contact_number}</TableCell>
                    <TableCell >{site.site_address}</TableCell>
                    <TableCell >{site.site_post_code}</TableCell>
                    <TableCell >
                    <Tooltip 
    title="View Sites" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    component={Link} 
    to="/sitedetails" 
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton>
  </Tooltip>

  <Tooltip 
    title="Delete Sites" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
onClick={() => handleDelete(site.id)}   size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredSites.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ViewSite;