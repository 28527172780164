import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import axios from "axios";
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  TextField,
} from '@mui/material';
import {
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import { Container, Row, Col, Form, Button as BootstrapButton, Modal } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import Dashboard from "../../Dashboard/Dashboard";

const StaffList = () => {
  const location = useLocation();

  const [subcontractors, setSubcontractors] = useState([]);
  const [filteredSubcontractors, setFilteredSubcontractors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [employeeName, setEmployeeName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  useEffect(() => {
    fetchSubcontractors();
  }, []);

  const fetchSubcontractors = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/stafflist_report', {}, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      if (response.data.success) {
        setSubcontractors(response.data.data);
        setFilteredSubcontractors(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching subcontractors:", error);
    }
  };

  const handleFilter = () => {
    const filtered = employeeName.filter(employeeName => 
      employeeName.subcon_company_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!startDate || !endDate || (new Date(employeeName.created_at) >= startDate && 
                                  new Date(employeeName.updated_at) <= endDate))
    );
    setFilteredSubcontractors(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setSearchTerm("");
    setDateRange([null, null]);
    setFilteredSubcontractors(subcontractors);
    setCurrentPage(0);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Subcontractor Sia Expiry',
    onAfterPrint: () => alert('Print successful!'),
  });

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredSubcontractors);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "StaffList");
    XLSX.writeFile(workbook, "StaffList.xlsx");
  };

  const componentRef = useRef();
  const getNavigationPath = () => {
    return [
      { name: 'Report', bold: false },
      { name: 'Workforce Report', bold: false },
      { name: 'Staff List', bold: true },

    ];
  };

  const navigationPath = getNavigationPath();
  return (
    <>
    <Dashboard/>
    <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
      <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
        {navigationPath.map((part, index) => (
          <React.Fragment key={index}>
            <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
              {part.name}
            </span>
            {index < navigationPath.length - 1 && " / "}
          </React.Fragment>
        ))}
      </h3>
    </div>
<div className="container w-100">
    <Form>
      <Row className="mb-3">
      <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>Full Name</Form.Label>
      <Form.Control 
        type="text" 
        value={employeeName}
        onChange={(e) => setEmployeeName(e.target.value)}
style={{width:'200px',padding:'5px'}} 
      />
    </Form.Group>
  </Col>


  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>Date</Form.Label>
      <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  style={{width:'200px'}}
                />
    </Form.Group>
  </Col>
</Row>
        <Row className="mb-3">
          <Col>
            <BootstrapButton type="submit" variant="primary" className="me-2" style={{padding:'5px 20px'}}>Filter</BootstrapButton>
            <BootstrapButton type="button" variant="secondary" className="me-2" style={{padding:'5px 20px'}}>Reset</BootstrapButton>
          </Col>
        </Row>
      </Form>
 
      </div>
      <Box sx={{ width: '100%', mt:5, display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'84%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileDownloadIcon />}
                onClick={exportToExcel}
                sx={{ mr: 1 }}
              >
                Export to Excel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PrintIcon />}
                onClick={handlePrint}
              >
              </Button>
            </Box>
            <TableContainer ref={componentRef}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <TableHead>
                  <TableRow>
                    {['S.NO', 'Staff Pin', 'Full Name','Gender','DOB','Contact Number','Employee Type', 'SIA Number', 'SIA Type','Created At'].map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredSubcontractors
                    .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                    .map((employee, index) => (
                      <TableRow key={employee.id} hover>
                        <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_staff_pin}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>
                      {employee.employee_forename}
                      {employee.employee_middname && ` ${employee.employee_middname}`}
                      {employee.employee_surname && ` ${employee.employee_surname}`}
                    </TableCell>  
                  
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_gender}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_dob}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_contactno}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_type}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_sia_no}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.employee_sia_type}</TableCell>
                        <TableCell sx={{ padding: '4px 8px' }}>{employee.created_at}</TableCell>

                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredSubcontractors.length}
              rowsPerPage={entriesPerPage}
              page={currentPage}
              onPageChange={(event, newPage) => setCurrentPage(newPage)}
              onRowsPerPageChange={(event) => {
                setEntriesPerPage(parseInt(event.target.value, 10));
                setCurrentPage(0);
              }}
            />
          </Paper>
          </Box>
    </>
  )
}

export default StaffList;
