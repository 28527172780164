import React, { useState, useEffect } from "react";
import axios from "axios";

const SIAQues = () => {
  const [siaData, setSiaData] = useState([]);

  useEffect(() => {
    fetchSIAData();
  }, []);

  const fetchSIAData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_sia_knowledge",
        {employee_id:"1"},
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      
      setSiaData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching SIA data:", error);
    }
  };

  return (
    <div className="container">
      <h3>SIA Questionare</h3>
      <div
        className="Ques"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <h4>Question</h4>
        <h4>Answer Given</h4>
      </div>
      {siaData.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <p style={{ fontSize: "15px", flex: 1 }}>
            {index + 1}- {item.questions}
          </p>
          <p style={{ fontSize: "15px", marginLeft: "20px" }}>
            {item.answers}
          </p>
        </div>
      ))}
    </div>
  );
};

export default SIAQues;