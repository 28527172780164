import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Swal from 'sweetalert2';

const PayrollInfo = () => {
  const [payrollData, setPayrollData] = useState({
    hourly_pay_rate: "",
    payment_period: "",
    fixed_pay: "",
    travel_expense: "",
    bank_acc_name: "",
    bank_acc_no: "",
    bank_sort_code: "",
    bank_name: "",
    other_information: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayrollData({ ...payrollData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_payroll_information",
        {
          ...payrollData,
          employee_id: "1", // Assuming this is constant
          company_branch: 1, // Assuming this is constant
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response from API:", response);
      if(response.data && response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'Payroll information saved',
          text: 'Payroll information has been saved successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });

      }
    } catch (err) {
      setError("Error saving payroll information");
      
    }
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;

  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  return (
    <Container className="px-0">
      <Row className="mx-0">
        <Col md={10} className="main-content">
          <Row>
            <Col md={9} className="form-content">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Hourly Pay Rate</Form.Label>
                      <Form.Control
                        type="text"
                        name="hourly_pay_rate"
                        value={payrollData.hourly_pay_rate}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Payment Period</Form.Label>
                      <Form.Select
                        name="payment_period"
                        value={payrollData.payment_period}
                        onChange={handleInputChange}
                        style={inputStyle}
                      >
                        <option value="">Select Period</option>
                        <option value="1">Weekly</option>
                        <option value="2">Bi-weekly</option>
                        <option value="3">Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Fixed Pay (if any)</Form.Label>
                      <Form.Control
                        type="text"
                        name="fixed_pay"
                        value={payrollData.fixed_pay}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Travel Expense</Form.Label>
                      <Form.Control
                        type="text"
                        name="travel_expense"
                        value={payrollData.travel_expense}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Col md={6}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Bank Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_acc_name"
                      value={payrollData.bank_acc_name}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Bank Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_acc_no"
                      value={payrollData.bank_acc_no}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Bank Sort Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_sort_code"
                      value={payrollData.bank_sort_code}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_name"
                      value={payrollData.bank_name}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Other Information</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="other_information"
                      value={payrollData.other_information}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Button
                  type="submit"
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "dodgerblue",
                    color: "white",
                    border: "0",
                    borderRadius: "5px",
                  }}
                >
                  SAVE
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PayrollInfo;