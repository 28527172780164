import React from "react";
import SignIn from "./Components/SignIn/SignIn";
import SignUp from "./Components/SignUp Page/SignUp";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import CompanyDetails from "./Components/Settings/CompanyDetails";
import DashboardPage from "./Components/Dashboard/DashboardPage";
import GeneralSetting from "./Components/Settings/WorkForce/Employee/GeneralSetting";
import Add from "./Components/Workforce/Employee/Add";
import View from "./Components/Workforce/Employee/View";
import Guard from "./Components/Workforce/Employee/Guard";
import Hrdocuments from "./Components/Workforce/Employee/Hrdocuments";
import EmployeeInfo from "./Components/Workforce/Employee/EmployeeInfo";
import OnlineApp from "./Components/Workforce/Employee/OnlineApp";
import SiaType from "./Components/Settings/WorkForce/Employee/SiaType";
import SubAdd from "./Components/Workforce/Employee/Subcontractors/SubAdd";
import Subcontractors from "./Components/Settings/WorkForce/Subcontractors/Subcontractors";
import SubView from "./Components/Workforce/Employee/Subcontractors/SubView";
import Trash from "./Components/Workforce/Employee/Subcontractors/Trash";
import DormantSub from "./Components/Workforce/Employee/Subcontractors/DormantSub";
import UnApproved from "./Components/Workforce/Employee/Subcontractors/UnApproved";
import ViewClient from "./Components/Clients/ViewClient";
import ViewDetails from "./Components/Clients/ViewDetails";
import AddClient from "./Components/Clients/AddClient";
import TrashClient from "./Components/Clients/TrashClient";
import Events from "./Components/Events/Events";
import CallOut from "./Components/Events/CallOut";
import ViewSite from "./Components/Sites/ViewSite";
import AddSite from "./Components/Sites/AddSite";
import SiteDetails from "./Components/Sites/SiteDetails";
import TrashSite from "./Components/Sites/TrashSite";
import DormantSite from "./Components/Sites/DormantSite";
import ManageInvoice from "./Components/Finance/Invoice/ManageInvoice";
import ViewInvoice from "./Components/Finance/Invoice/ViewInvoice";
import ManagePayments from "./Components/Finance/Invoice/ManagePayments";
import CreditNotes from "./Components/Finance/Invoice/CreditNotes";
import ManagePayroll from "./Components/Finance/Payroll/ManagePayroll";
import PayrollPayments from "./Components/Finance/Payroll/ManagePayments";
import AdvancePayment from "./Components/Finance/Payroll/AdvancePayment";
import ViewPayment from "./Components/Finance/Payroll/ViewPayment";
import ClientRoster from "./Components/Roster/ClientRoster";
import AddShift from "./Components/Roster/AddShift";
import AddGuard from "./Components/Roster/AddGuard";
import StaffRoster from "./Components/Roster/StaffRoster";
import SubStaffRoster from "./Components/Roster/SubStaffRoster";
import MultipleEdit from "./Components/Roster/MultipleEdit";
import ViewEmployeeInfo from "./Components/Workforce/Employee/ViewEmployeeInfo";
import EmployeeChecklist from "./Components/Workforce/Employee/EmployeeChecklist";
import EmployeeForm from "./Components/Workforce/Employee/EmployeeForm";
// import ProtectedRoute, { AuthProvider } from "./Components/SignIn/AuthContext";
import ClientSettings from "./Components/Settings/ClientSettings";
import RosterSettings from "./Components/Settings/RosterSettings";
import CallSettings from "./Components/Settings/CallSettings";
import FinanceSettings from "./Components/Settings/FinanceSettings";
import ProtectedRoute from "./Components/SignIn/ProtectedRoute";
import Holiday from "./Components/Workforce/Employee/Holiday";
import SubViewDetails from "./Components/Workforce/Employee/Subcontractors/SubViewDetails";
import Timestamp from "./Components/Timestamp/Timestamp";
import TrashEmployee from "./Components/Workforce/Employee/TrashEmployee";
import NavigationPath from "./Components/Dashboard/Navigation";
import DormantStaff from "./Components/Workforce/Employee/DormantStaff";
import ExportEmployee from "./Components/Workforce/Employee/ExportEmployee";
import AttendanceReport from "./Components/Timestamp/AttendanceReport";
import EditClientRoster from "./Components/Roster/EditClientRoster";
import StaffList from "./Components/Reports/Workforce Report/StaffList";
import ApplicationForm from "./Components/Reports/Workforce Report/ApplicationForm";
import DisclaimerReport from "./Components/Reports/Workforce Report/DisclaimerReport";
import SubcontractsList from "./Components/Reports/Workforce Report/SubcontractsList";
import ClientRosterEmployeeList from "./Components/Reports/Workforce Report/ClientRosterEmployeeList";
import NewEmployeeForm from "./Components/Reports/Workforce Report/NewEmployeeForm";
import SiteDistance from "./Components/Reports/Workforce Report/SiteDistance";
import ClientDetails from "./Components/Reports/Workforce Report/Client and Site Report/ClientDetails";
import ClientList from "./Components/Reports/Workforce Report/Client and Site Report/ClientList";
import SiteDetailsReport from "./Components/Reports/Workforce Report/Client and Site Report/SiteDetails";
import UserDetails from "./Components/SignIn/UserDetails";
import SiteList from "./Components/Reports/Workforce Report/Client and Site Report/SiteList";
import EmployeeClientRoster from "./Components/Reports/Workforce Report/Roster Report/EmployeeClientRoster";
import LatenessReport from "./Components/Reports/Workforce Report/Roster Report/LatenessReport";
import SubcontractorReport from "./Components/Reports/Workforce Report/Roster Report/SubcontractorReport";
import MasterClientRosterReport from "./Components/Reports/Workforce Report/Roster Report/MasterClientRosterReport";
import SiteRoster from "./Components/Reports/Workforce Report/Roster Report/SiteRoster";
import ShiftStatusReport from "./Components/Reports/Workforce Report/Roster Report/ShiftStatusReport";
import WeeklyTimeSheet from "./Components/Reports/Workforce Report/Roster Report/WeeklyTimeSheet";
import WeeklyRosterView from "./Components/Reports/Workforce Report/Roster Report/WeeklyRosterView";
import MasterRosterReport from "./Components/Reports/Workforce Report/Roster Report/MasterRosterReport";
import ContractorHoursReport from "./Components/Reports/Workforce Report/Roster Report/ContractorHoursReport";
import ChaseupHistory from "./Components/Reports/Workforce Report/Timestamp Report/ChaseupHistory";
import TimestampReport from "./Components/Reports/Workforce Report/Timestamp Report/TimestampReport";
import LogoutReport from "./Components/Reports/Workforce Report/Logout Report/LogoutReport";
import ClientInvoice from "./Components/Reports/Workforce Report/Finance Report (Invoice)/ClientInvoice";
import ClientInvoicePayments from "./Components/Reports/Workforce Report/Finance Report (Invoice)/ClientInvoicePayments";
import SiteWiseInvoice from "./Components/Reports/Workforce Report/Finance Report (Invoice)/SiteWiseInvoice";
import SubcontractorInvoice from "./Components/Reports/Workforce Report/Finance Report (Invoice)/SubcontractorInvoice";
import MissingShift from "./Components/Reports/Workforce Report/Finance Report (Invoice)/MissingShift";
import SiteShiftReport from "./Components/Reports/Workforce Report/Finance Report (Invoice)/SiteShiftReport";
import Profit from "./Components/Reports/Workforce Report/Finance Report (Invoice)/Profit";
import TimestampInvoiceReport from "./Components/Reports/Workforce Report/Finance Report (Invoice)/TimestampInvoiceReport";
import TotalNumber from "./Components/Reports/Workforce Report/Finance Report (Invoice)/TotalNumber";
import ClientHoursAnalysis from "./Components/Reports/Workforce Report/Finance Report (Invoice)/ClientHoursAnalysis";
import EmployeeWisePayroll from "./Components/Reports/Workforce Report/Finance Report (Payroll)/EmployeeWisePayroll";
import AllEmployeePayroll from "./Components/Reports/Workforce Report/Finance Report (Payroll)/AllEmployeePayroll";
import PaidUnPaidShift from "./Components/Reports/Workforce Report/Finance Report (Payroll)/PaidUnpaidShift";
import EmployeeSiteWiseHours from "./Components/Reports/Workforce Report/Finance Report (Payroll)/EmployeeSiteWiseHours";
import TimeSheet from "./Components/Reports/Workforce Report/Finance Report (Payroll)/TimeSheet";
import MasterPayrollInvoiceReport from "./Components/Reports/Workforce Report/Finance Report (Payroll)/MasterPayrollInvoiceReport";
import PaymentCalculation from "./Components/Reports/Workforce Report/Finance Report (Payroll)/PaymentCalculation";
import SubcontractorHoursReport from "./Components/Reports/Workforce Report/Finance Report (Payroll)/SubcontractorHoursReport";
import EmployeeSiaExpiry from "./Components/Reports/Workforce Report/Hr Report/EmployeesSiaExpiry";
import SiaLicenseExpiry from "./Components/Reports/Workforce Report/Hr Report/SiaLicenseCheck";
import SiaLicenseCheck from "./Components/Reports/Workforce Report/Hr Report/SiaLicenseCheck";
import SubcontractorSiaExpiry from "./Components/Reports/Workforce Report/Hr Report/SubcontractorSiaExpiry";
import EmployeeVisaExpiry from "./Components/Reports/Workforce Report/Hr Report/EmployeeVisaExpiry";
import HrDocumentreport from "./Components/Reports/Workforce Report/Hr Report/HrDocumentReport";
import SubEmployeeDocumentReport from "./Components/Reports/Workforce Report/Hr Report/SubEmployeeDocumentReport";
import ScreeningAndVettingReport from "./Components/Reports/Workforce Report/Hr Report/ScreeningandVettingReport";
import EventsReport from "./Components/Reports/Workforce Report/Events Report/EventsReport";
import LogReport from "./Components/Reports/Workforce Report/Logout Report/LogoutReport";
import MonthlyCalender from "./Components/Workforce/Employee/MonthlyCalender";
import MonthlyCalendar from "./Components/Workforce/Employee/MonthlyCalender";
import HolidayRequest from "./Components/Workforce/Employee/HolidayRequest";
function App() {
  // useEffect(()=>{
  //         localStorage.setItem("role",admin);
  //       })
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route element={<ProtectedRoute />}>
          <Route path="/dashboardpage" element={<DashboardPage />} />
          <Route path="/add" element={<Add />} />
          <Route path="/view" element={<View />} />
          <Route path="/companydetails" element={<CompanyDetails />} />
          <Route path="/generalsetting" element={<GeneralSetting />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/siatype" element={<SiaType />} />
          <Route path="/guard" element={<Guard />} />
          <Route path="/hrdocuments" element={<Hrdocuments />} />
          <Route path="/employeeinfo" element={<EmployeeInfo />} />
          <Route path="/onlineapp" element={<OnlineApp />} />
          <Route path="/subcontractors" element={<Subcontractors />} />
          <Route path="/subadd" element={<SubAdd />} />
          <Route path="/subview" element={<SubView />} />
          <Route path="/trash" element={<Trash />} />
          <Route path="/dormantsub" element={<DormantSub />} />
          <Route path="/unapproved" element={<UnApproved />} />
          <Route path="/viewclient" element={<ViewClient />} />
          <Route path="/viewdetails" element={<ViewDetails />} />
          <Route path="/addclient" element={<AddClient />} />
          <Route path="/trashclient" element={<TrashClient />} />
          <Route path="/events" element={<Events />} />
          <Route path="/callout" element={<CallOut />} />
          <Route path="/viewsite" element={<ViewSite />} />
          <Route path="/addsite" element={<AddSite />} />
          <Route path="/sitedetails" element={<SiteDetails />} />
          <Route path="/trashsite" element={<TrashSite />} />
          <Route path="/dormantsite" element={<DormantSite />} />
          <Route path="/manageinvoice" element={<ManageInvoice />} />
          <Route path="/viewinvoice" element={<ViewInvoice />} />
          <Route path="/managepayments" element={<ManagePayments />} />
          <Route path="/creditnotes" element={<CreditNotes />} />
          <Route path="/managepayroll" element={<ManagePayroll />} />
          <Route path="/payrollpayments" element={<PayrollPayments />} />
          <Route path="/advancepayment" element={<AdvancePayment />} />
          <Route path="/viewpayment" element={<ViewPayment />} />
          <Route path="/clientroster" element={<ClientRoster />} />
          <Route path="/addshift" element={<AddShift />} />
          <Route path="/addguard" element={<AddGuard />} />
          <Route path="/staffroster" element={<StaffRoster />} />
          <Route path="/substaffroster" element={<SubStaffRoster />} />
          <Route path="/multipleedit" element={<MultipleEdit />} />
          <Route path="/viewemployeeinfo" element={<ViewEmployeeInfo />} />
          <Route path="/employeechecklist" element={<EmployeeChecklist />} />
          <Route path="/employeeform" element={<EmployeeForm />} />
          <Route path="/clientsettings" element={<ClientSettings />} />
          <Route path="/rostersettings" element={<RosterSettings />} />
          <Route path="/callsettings" element={<CallSettings />} />
          <Route path="/financesettings" element={<FinanceSettings />} />
          <Route path="/holiday" element={<Holiday/>} />
          <Route path="/subviewdetails" element={<SubViewDetails/>} />
          <Route path="/timestamp" element={<Timestamp/>} />
          <Route path="/trashemployee" element={<TrashEmployee/>} />
          <Route path="/dormantstaff" element={<DormantStaff/>} />
          <Route path="/exportemployee" element={<ExportEmployee/>} />
          <Route path="/attendancereport" element={<AttendanceReport/>} />
          <Route path="/stafflist" element={<StaffList/>} />
          <Route path="/userdetails" element={<UserDetails/>} />
          <Route path="/applicationform" element={<ApplicationForm/>} />
          <Route path="/disclaimerreport" element={<DisclaimerReport/>} />
          <Route path="/subcontractslist" element={<SubcontractsList/>} />
          <Route path="/clientrosteremployeelist" element={<ClientRosterEmployeeList/>} />
          <Route path="/newemployeeform" element={<NewEmployeeForm/>} />
          <Route path="/sitedistance" element={<SiteDistance/>} />
          <Route path="/clientdetails" element={<ClientDetails/>} />
          <Route path="/clientlist" element={<ClientList/>} />
          <Route path="/sitedetailsreport" element={<SiteDetailsReport/>} />
          <Route path="/sitelist" element={<SiteList/>} />
          <Route path="/employeeclientroster" element={<EmployeeClientRoster/>} />
          <Route path="/latenessreport" element={<LatenessReport/>} />
          <Route path="/subcontractorreport" element={<SubcontractorReport/>} />
          <Route path="/masterclientrosterreport" element={<MasterClientRosterReport/>} />
          <Route path="/siteroster" element={<SiteRoster/>} />
          <Route path="/shiftstatusreport" element={<ShiftStatusReport/>} />
          <Route path="/weeklytimesheet" element={<WeeklyTimeSheet/>} />
          <Route path="/weeklyrosterview" element={<WeeklyRosterView/>} />
          <Route path="/masterrosterreport" element={<MasterRosterReport/>} />
          <Route path="/contractorhoursreport" element={<ContractorHoursReport/>} />
          <Route path="/chaseuphistory" element={<ChaseupHistory/>} />
          <Route path="/timestampreport" element={<TimestampReport/>} />
          <Route path="/clientinvoice" element={<ClientInvoice/>} />
          <Route path="/clientinvoicepayments" element={<ClientInvoicePayments/>} />
          <Route path="/sitewiseinvoice" element={<SiteWiseInvoice/>} />
          <Route path="/subcontractorinvoice" element={<SubcontractorInvoice/>} />
          <Route path="/missingshift" element={<MissingShift/>} />
          <Route path="/siteshiftreport" element={<SiteShiftReport/>} />
          <Route path="/profit" element={<Profit/>} />
          <Route path="/timestampinvoicereport" element={<TimestampInvoiceReport/>} />
          <Route path="/totalnumber" element={<TotalNumber/>} />
          <Route path="/clienthoursanalysis" element={<ClientHoursAnalysis/>} />
          <Route path="/employeewisepayroll" element={<EmployeeWisePayroll/>} />
          <Route path="/allemployeepayroll" element={<AllEmployeePayroll/>} />
          <Route path="/painunpaidshift" element={<PaidUnPaidShift/>} />
          <Route path="/employeesitewisehours" element={<EmployeeSiteWiseHours/>} />
          <Route path="/timesheet" element={<TimeSheet/>} />
          <Route path="/masterpayrollinvoicereport" element={<MasterPayrollInvoiceReport/>} />
          <Route path="/paymentcalculation" element={<PaymentCalculation/>} />
          <Route path="/subcontractorhoursreport" element={<SubcontractorHoursReport/>} />
          <Route path="/employeesiaexpiry" element={<EmployeeSiaExpiry/>} />
          <Route path="/sialicensecheck" element={<SiaLicenseCheck/>} />
          <Route path="/subcontractorsiaexpiry" element={<SubcontractorSiaExpiry/>} />
          <Route path="/employeevisaexpiry" element={<EmployeeVisaExpiry/>} />
          <Route path="/htdocumentreport" element={<HrDocumentreport/>} />
          <Route path="/subemployeedocumentreport" element={<SubEmployeeDocumentReport/>} />
          <Route path="/screeningandvettingreport" element={<ScreeningAndVettingReport/>} />
          <Route path="/eventsreport" element={<EventsReport/>} />
          <Route path="/monthlycalendar" element={<MonthlyCalendar/>} />
          <Route path="/holidayrequest" element={<HolidayRequest/>} />




<Route path="/logreport" element={<LogReport/>} />

        </Route>
        <Route path="/editclientroster/" element={<EditClientRoster />} />
        <Route path="signin" element={<Navigate to="/signin" replace />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/dashboardpage" element={<DashboardPage />} /> */}
          
        </Routes>
      </BrowserRouter>
     
    </>
  );
}

export default App;
