import React,{useState} from 'react'
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Nav, Form, Button } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';



const PreviousEducation = () => {
  const [formData, setFormData] = useState({
    employee_id: "1",
    education_name: "",
    education_description: "",
    institution_name: "",
    address: "",
    description: "",
    contact_name: "",
    contact_number: "",
    contact_email: "",
    start_date: "",
    end_date: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://srltd.megworld.in/api/add_pervious_education', formData, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log('Data submitted successfully:', response);
      if(response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'Previous Education Added',
          text: 'Previous Education has been Added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      // You can add an error message here
    }
  };

  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };
  return (
    <Form onSubmit={handleSubmit}>
              
    <Row>
      <Col md={10} className="mt-10">
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Education Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Education Name"
            style={inputStyle}
            name="education_name"
            value={formData.education_name}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>
          Education Description
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Education Description"
            style={inputStyle}
            name="education_description"
            value={formData.education_description}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>

      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Institution Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Institution Name"
            style={inputStyle}
            name="institution_name"
            value={formData.institution_name}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Address"
            style={inputStyle}
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>
           Description
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Description"
            style={inputStyle}
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
    
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Contact Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Contact Name"
            style={inputStyle}
            name="contact_name"
            value={formData.contact_name}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Contact Number</Form.Label>
          <Form.Control
            type="number"
            placeholder="Contact Number"
            style={inputStyle}
            name="contact_number"
            value={formData.contact_number}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Contact Email</Form.Label>
          <Form.Control
            type="mail"
            placeholder="Contact Email"
            style={inputStyle}
            name="contact_email"
            value={formData.contact_email}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Start Date</Form.Label>
          <Form.Control
            type="date"
            placeholder="Start Date"
            style={inputStyle}
            name="start_date"
            value={formData.start_date}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>End Date</Form.Label>
          <Form.Control
            type="date"
            placeholder="End Date"
            style={inputStyle}
            name="end_date"
            value={formData.end_date}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
    </Row>
    <Button
              onClick={handleSubmit}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "dodgerblue",
                  color: "white",
                  border: "0",
                  borderRadius: "5px",
                }}
              >
                SAVE
              </Button>
              <Button
                style={{
                  padding: "10px 20px",
                  backgroundColor: "Green",
                  color: "white",
                  border: "0",
                  borderRadius: "5px",
                  marginLeft: "5px",
                }}
              >
                NEXT
              </Button>

   
  </Form>
  )
}

export default PreviousEducation
