import React, { useState, useEffect } from "react";
import { Button as BootstrapButton, Modal, Image } from "react-bootstrap";
import axios from "axios";
import Dashboard from "../Dashboard/Dashboard";
import { Container, Row, Col, Form } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons'; 
import L from 'leaflet';
import DatePicker from "react-datepicker";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button as MUIButton
} from '@mui/material';
import {
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const AttendanceReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [mapCenter, setMapCenter] = useState([10.375000, 77.972800]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const fetchAttendanceData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_timestamp",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );

      if (response.data && response.data.success) {
        setAttendanceData(response.data.data);
        setFilteredAttendanceData(response.data.data);
      } else {
        console.error("Failed to fetch attendance data");
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const isValidCoordinate = (coord) => {
    return typeof coord === 'number' && !isNaN(coord);
  };

  const handleImageClick = (imageUrl, latitude, longitude) => {
    if (imageUrl) {
      const fullImageUrl = imageUrl.startsWith('http') ? imageUrl : `https://srltd.megworld.in/${imageUrl}`;
      setSelectedImage(fullImageUrl);

      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);

      if (isValidCoordinate(lat) && isValidCoordinate(lng)) {
        setSelectedLocation({ lat, lng });
        setMapCenter([lat, lng]);
      } else {
        setSelectedLocation(null);
        setMapCenter([10.375000, 77.972800]); // Default to Dindigul coordinates
      }

      setShowImageModal(true);
    } else {
      console.error("Image URL is undefined or null");
    }
  };



  
  const handleFilter = () => {
    const filtered = attendanceData.filter(emp => {
      const nameMatch = emp.name.toLowerCase().includes(filterName.toLowerCase());
      const dateMatch = (!startDate || new Date(emp.date) >= startDate) &&
                        (!endDate || new Date(emp.date) <= endDate);
      return nameMatch && dateMatch;
    });
    setFilteredAttendanceData(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setFilterName("");
    setFilterSite("");
    setFilterDate("");
    setFilteredAttendanceData(attendanceData);
  };

  const createCustomIcon = () => {
    return L.divIcon({
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#007bff" width="24px" height="24px">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
        </svg>
      `,
      className: '',
      iconSize: [24, 24],
      iconAnchor: [12, 24],
    });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(attendanceData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Timestamp");
    XLSX.writeFile(workbook, "Timestamp Report.xlsx");
  }
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}
      >
        <div className="">
          <h3 className="text-white" style={{ fontSize: "15px" }}>
            Attendance Report
          </h3>
        </div>
      </div>
      <Container fluid className="mt-4">
      <Form onSubmit={handleFilter}>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label style={{float:'left'}}>NAME</Form.Label>
              <Form.Control 
                type="text" 
                className="rounded-input"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label style={{float:'left'}}>SITE NAME</Form.Label>
              <Form.Control 
                type="text" 
                className="rounded-input"
                value={filterSite}
                onChange={(e) => setFilterSite(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            {/* <Form.Group>
              <Form.Label style={{float:'left'}}>DATE</Form.Label>
              <Form.Control 
                type="date" 
                className="rounded-input"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </Form.Group> */}

            <Form.Group style={{marginTop:'30px'}}>
                <Form.Label style={{float:'left'}}>DATE</Form.Label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  style={{width:'200px'}}
                />
              </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <BootstrapButton type="submit" variant="primary" className="me-2" style={{padding:'8px 30px'}}>FILTER</BootstrapButton>
            <BootstrapButton type="button" variant="secondary" className="me-2" style={{padding:'8px 30px'}} onClick={handleReset}>RESET</BootstrapButton>

          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
        <Modal
              show={showImageModal}
              onHide={() => setShowImageModal(false)}
              size="medium"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Captured Image with Location</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{  position: 'relative',display:'flex',justifyContent:'center',alignItems:'center' }}>
                <div className="position-relative" style={{ width: '80%', height: '300px' }}>
                  {selectedImage && (
                    <Image 
                      src={selectedImage} 
                      fluid 
                      alt="Captured Document"
                      style={{ width: '100%',objectFit: 'cover' }}
                      />
                  )}
                  <div style={{ 
                    position: 'absolute', 
                    top: 10, 
                    right: 10, 
                    width: '30%',
                    height:'120px', 
                    zIndex: 1000,
                    border: '2px solid white',
                    borderRadius: '5px',
                    overflow: 'hidden'
                  }}>
                    <MapContainer 
                      key={`${mapCenter[0]}-${mapCenter[1]}`}
                      center={mapCenter} 
                      zoom={13} 
                      style={{ height: "100%", width: "100%" }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                                        <Marker position={mapCenter} icon={createCustomIcon()} />

                      {selectedLocation && (
                        <Marker position={[selectedLocation.lat, selectedLocation.lng]}>
                          <Popup>
                            Attendance Location
                          </Popup>
                        </Marker>
                      )}
                    </MapContainer>
                  </div>
                </div>
              </Modal.Body>
            </Modal>



      <Box sx={{ width: '100%', mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Paper sx={{ width: '95%', mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
    
      <MUIButton
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={exportToExcel}
      >
        Export to Excel
      </MUIButton>
    </Box>
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {['S.NO', 'Site', 'Name','Date', 'Time','Description', 'Image'].map((header) => (
              <TableCell key={header} sx={{ fontSize: '13px', padding: '4px 8px' }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAttendanceData
            .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
            .map((item, index) => (
              <TableRow key={index} hover>
                <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{item.site}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{item.name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{item.date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{item.time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{item.description}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                <BootstrapButton
  variant="link"
  onClick={() => handleImageClick(item.file)}
>
  <FontAwesomeIcon icon={faImage} size="lg" />
</BootstrapButton>


                  
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 50, 100, 500]}
      component="div"
      count={filteredAttendanceData.length}
      rowsPerPage={entriesPerPage}
      page={currentPage}
      onPageChange={(event, newPage) => setCurrentPage(newPage)}
      onRowsPerPageChange={(event) => {
        setEntriesPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
      }}
    />
  </Paper>
</Box>
      </Col>
      </Row>
      </Container>
    </>
  );
};

export default AttendanceReport;