import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ViewEmployee.css";
import defaultPreviewLogo from "../Employee/patterns/previewimg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faCalendar,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const Basic = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [previewImg, setPreviewImg] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/show_basic_information",
          { employee_id: "1" },
          {
            headers: {
              Authorization:
                "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            },
          }
        );

        console.log("Fetched Basic Data:", response);
        setEmployeeData(response.data.data);
        setLoading(false);
        if (response.data.data[0] && response.data.data[0].employee_profile) {
          setPreviewImg(`https://srltd.megworld.in/${response.data.data[0].employee_profile}`);
        }
      } catch (error) {
        console.error("Error:", error);
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
      <div className="row">
        <div className="col-md-3">
        {employeeData.map((employee) => (
          <div key={employee.staff_id} className="profile-section" style={{marginLeft:'0',marginTop:'90px'}}>
            <h2>Profile Information</h2>
            <div className="profile-image-placeholder">
              <img
                style={{
                  width: "140px",
                  height: "140px",
                  objectFit: "cover",
                }}
                src={previewImg || defaultPreviewLogo}
                alt={previewImg ? "Profile Picture" : "Default Profile Picture"}
              />
            </div>
              <h2>{`${employee.fore_name} ${employee.middle_name} ${employee.sur_name}`}</h2>
              <div className="profile-info">
                <p>
                  <FontAwesomeIcon icon={faPhone} /> {employee.contact_number}
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> {employee.email}
                </p>
                <p>
                  <FontAwesomeIcon icon={faCalendar} /> {employee.d_o_b}
                </p>
              </div>
              <h3>Joining</h3>
            </div>
          ))}
        </div>
        <div className="col-md-9">
          <div className="employee-info-container">
            <div className="main-content">
              <div className="info-section">
                <h2>Employee Information</h2>
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : (
                  <table className="table">
                    <tbody>
                      {employeeData.length > 0 ? (
                        employeeData.map((employee, index) => (
                          <React.Fragment key={employee.staff_id}>
                            <tr>
                              <td>Staff ID:</td>
                              <td>{employee.staff_id}</td>
                            </tr>
                            <tr>
                              <td>PIN:</td>
                              <td>{employee.pin}</td>
                            </tr>
                            <tr>
                              <td>Date of Registration:</td>
                              <td>{employee.date_of_registration}</td>
                            </tr>
                            <tr>
                              <td>Job Title:</td>
                              <td>{employee.job_title}</td>
                            </tr>
                            <tr>
                              <td>Holiday Entitlement:</td>
                              <td>{employee.holiday_entitlement}</td>
                            </tr>
                            <tr>
                              <td>Contract Hours:</td>
                              <td>{employee.contract_hours}</td>
                            </tr>
                            <tr>
                              <td>Reference:</td>
                              <td>{employee.reference}</td>
                            </tr>
                            <tr>
                              <td>Full Name:</td>
                              <td>{`${employee.fore_name} ${employee.middle_name} ${employee.sur_name}`}</td>
                            </tr>
                            <tr>
                              <td>NI Number:</td>
                              <td>{employee.ni_number}</td>
                            </tr>
                            <tr>
                              <td>Gender:</td>
                              <td>{employee.gender}</td>
                            </tr>
                            <tr>
                              <td>Date of Birth:</td>
                              <td>{employee.d_o_b}</td>
                            </tr>
                            <tr>
                              <td>Nationality:</td>
                              <td>{employee.nationality}</td>
                            </tr>
                            <tr>
                              <td>EDBS Number:</td>
                              <td>{employee.edbs_number}</td>
                            </tr>
                            <tr>
                              <td>Contact Number:</td>
                              <td>{employee.contact_number}</td>
                            </tr>
                            <tr>
                              <td>Alternate Contact Number:</td>
                              <td>{employee.alt_contact_no}</td>
                            </tr>
                            <tr>
                              <td>Email:</td>
                              <td>{employee.email}</td>
                            </tr>
                            <tr>
                              <td>Next Kin:</td>
                              <td>{employee.next_kin}</td>
                            </tr>
                            <tr>
                              <td>Relationship:</td>
                              <td>{employee.relationship}</td>
                            </tr>
                            <tr>
                              <td>Next Kin Contact:</td>
                              <td>{employee.next_kin_contact}</td>
                            </tr>
                            <tr>
                              <td>SIA License:</td>
                              <td>{employee.sia_license}</td>
                            </tr>
                            <tr>
                              <td>License Type:</td>
                              <td>{employee.license_type}</td>
                            </tr>
                            <tr>
                              <td>License Expiry:</td>
                              <td>{employee.license_expiry}</td>
                            </tr>
                            <tr>
                              <td>PMVA Training:</td>
                              <td>{employee.pmva_training}</td>
                            </tr>
                            <tr>
                              <td>Joining Date:</td>
                              <td>{employee.joining_date}</td>
                            </tr>
                            <tr>
                              <td>Comments:</td>
                              <td>{employee.comments}</td>
                            </tr>
                            <tr>
                              <td>Profile Picture:</td>
                              <td>{employee.employee_profile}</td>
                            </tr>
                            <tr>
                              <td>Visa To Work:</td>
                              <td>{employee.visa_to_work}</td>
                            </tr>
                            <tr>
                              <td>p45 p46:</td>
                              <td>{employee.p45_p46}</td>
                            </tr>
                            <tr>
                              <td>Other Employment:</td>
                              <td>{employee.other_employment}</td>
                            </tr>
                            <tr>
                              <td>Leisure:</td>
                              <td>{employee.leisure}</td>
                            </tr>
                            <tr>
                              <td>Criminal Record:</td>
                              <td>{employee.criminal_record}</td>
                            </tr>
                            <tr>
                              <td>CCJ:</td>
                              <td>{employee.ccj}</td>
                            </tr>
                            <tr>
                              <td>Any Days:</td>
                              <td>{employee.any_days}</td>
                            </tr>
                            <tr>
                              <td>Holiday Commit:</td>
                              <td>{employee.holiday_commit}</td>
                            </tr>
                            <tr>
                              <td>Own Transport:</td>
                              <td>{employee.own_transport}</td>
                            </tr>
                            <tr>
                              <td>Driving License Type:</td>
                              <td>{employee.drive_license_type}</td>
                            </tr>
                            <tr>
                              <td>Employee Start Date:</td>
                              <td>{employee.employee_start_date}</td>
                            </tr>
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Basic;
