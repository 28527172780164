import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faChevronLeft, faCalendar, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Table,
  Dropdown,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import Dashboard from "../Dashboard/Dashboard";
import "./Roster.css";
import headerBackground from "../patterns/header-bg.png";

const MultipleEdit = () => {
  const [showAssign, setShowAssign] = useState(false);
  const [showBook, setShowBook] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowConfirm = () => setShowConfirm(true);
  const handleCloseConfirm = () => setShowConfirm(false);

  const handleShowBook = () => setShowBook(true);
  const handleCloseBook = () => setShowBook(false);

  const handleShowAssign = () => setShowAssign(true);
  const handleCloseShowAssign = () => setShowAssign(false);
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        {/* <div className="toolbar-content p-4">
          <div className="d-flex">
            <h5 className="text-white" style={{ margin: "0" }}>
              Edit Multiple Shifts
            </h5>
            <span
              className="badge bg-success me-2"
              style={{ textAlign: "center", marginLeft: "10px" }}
            >
              Confirmed Shifts: 0
            </span>
            <span className="badge bg-danger me-2">Unconfirmed Shifts: 0</span>
            <span className="badge bg-primary">Unassigned Shifts: 0</span>
          </div>
          <p className="text-white" style={{ margin: "0" }}>
            Clients: ALL | Site Groups: ALL | Sites: ALL | Roster Period:
            15-01-2024 to 21-01-2024
          </p>
        </div>

        <div
          className="d-flex align-items-center"
          style={{ marginLeft: "70px", marginTop: "-35px" }}
        >
          <span
            className="me-3 mt-4 Roster-btn"
            style={{ backgroundColor: "yellowgreen" }}
          >
            SUBCONTRACTOR FILTER
          </span>
          <button
            variant="primary"
            size="sm"
            className="me-2 Roster-btn"
            style={{ backgroundColor: "rebeccapurple" }}
          >
            STAFF FILTER
          </button>

          <button
            variant="outline-primary"
            size="sm"
            className="me-2 Roster-icon"
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            variant="outline-primary"
            size="sm"
            className="me-2 Roster-icon1"
          >
            <i className="fas fa-calendar"></i>
          </button>
          <button
            variant="outline-primary"
            size="sm"
            className="me-2 Roster-icon"
          >
            <i className="fas fa-chevron-right"></i>
          </button>
          <button
            variant="primary"
            size="sm"
            className="me-2 Roster-btn"
            style={{ backgroundColor: "violet" }}
          >
            OPERATION FILTER
          </button>
        </div> */}

        <div className="px-4">
        <div className="row gy-3">
          <div className="col-12">
                      <div className="d-flex flex-wrap align-items-center mb-3">
            <h5 className="text-white me-3"> Edit Multiple Shifts</h5>
            <span className="badge bg-success me-2">Confirmed Shifts: 0</span>
            <span className="badge bg-danger me-2">Unconfirmed Shifts: 0</span>
            <span className="badge bg-primary">Unassigned Shifts: 0</span>
          </div>
          <p className="text-white mb-0" style={{fontSize:'15px'}}>
          Clients: ALL | Site Groups: ALL | Sites: ALL | Roster Period:
          15-01-2024 to 21-01-2024       </p>
        </div>
        </div>
        </div>

        <div className="d-flex flex-wrap align-items-center mt-3 justify-content-between">
          <button
            className="btn btn-outline-danger btn-sm me-2"
            // style={{ backgroundColor: 'lightgrey', color: 'white', borderRadius: '5px' }}
          >
            {/* <FontAwesomeIcon icon={faTrash} /> */}
          </button>
          <div className="d-flex flex-wrap align-items-center">
            {/* <span
              className="me-3 badge"
              style={{ backgroundColor: 'yellowgreen', padding: '5px 10px', borderRadius: '5px' }}
            >
              Total Hours: 0
            </span> */}
             <button
              className="btn btn-primary btn-sm me-2"
              style={{ backgroundColor: 'rebeccapurple' ,padding:'6px',marginRight:'20px'}}
            >
              SUBCONTRACTOR FILTER
            </button>
            <button
              className="btn btn-primary btn-sm me-2"
              style={{ backgroundColor: 'rebeccapurple',padding:'6px' }}
            >
              STAFF FILTER
            </button>
            {/* <button
              className="btn btn-primary btn-sm me-2"
              style={{ backgroundColor: 'rebeccapurple' }}
              onClick={handleShowFilterStaff}            >
             FILTER ROSTER
            </button> */}
            <button className="btn btn-outline-primary btn-sm me-1">
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button className="btn btn-outline-primary btn-sm me-1">
              <FontAwesomeIcon icon={faCalendar} />
            </button>
            <button className="btn btn-outline-primary btn-sm me-1">
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <button
              className="btn btn-success btn-sm"
              style={{ backgroundColor: 'green',padding:'6px' }}
            >
              OPERATION FILTER
            </button>
          </div>
        </div>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            size="sm"
            className="Roster-btn Action"
            style={{
              backgroundColor: "#ffa500",
              padding: "5px",
              marginTop:'10px',
              marginLeft:'8px'
            }}
          >
            ACTIONS
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              style={{ backgroundColor: "#5cb85c", color: "white" }}
            >
              Copy Roster
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleShowAssign}
              style={{ backgroundColor: "#5bc0de", color: "white" }}
            >
              Assign
            </Dropdown.Item>

            <Modal show={showAssign} onHide={handleCloseShowAssign} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Assign Staff</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ float: "left" }}>Staff</Form.Label>
                    <Form.Select style={{ width: "100%" }}>
                      <option>Select Staff...</option>
                      <option>Employee</option>
                      <option>Subcontractor</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Check
                    style={{ marginTop: "10px" }}
                    label="Unpaid Shift"
                    inline
                  />
                  <Form.Check
                    style={{ marginTop: "10px" }}
                    type="radio"
                    label="Confirm Shift"
                    inline
                  />
                  <Form.Check
                    style={{ marginTop: "10px" }}
                    type="radio"
                    label="Unconfirm Shift"
                    defaultChecked
                    inline
                  />

                  <Form.Group className="mb-3 mt-10">
                    <Form.Label
                      className="text-warning"
                      style={{ float: "left" }}
                    >
                      Payable Amount
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="Payable Rate"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className="text-warning"
                      style={{ float: "left" }}
                    >
                      Payable Expenses
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="Shift Expenses"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className="text-danger"
                      style={{ float: "left" }}
                    >
                      Penalty / Deduction
                    </Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="Penalty / Deduction"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary">UPDATE</Button>
                <Button variant="secondary" onClick={handleCloseShowAssign}>
                  CLOSE
                </Button>
              </Modal.Footer>
            </Modal>

            <Dropdown.Item
              style={{ backgroundColor: "#ff69b4", color: "white" }}
            >
              Change Time
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleShowBook}
              style={{ backgroundColor: "#8a2be2", color: "white" }}
            >
              Book On / Off
            </Dropdown.Item>

            <Modal show={showBook} onHide={handleCloseBook} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Update Shift(s)</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <p style={{ color: "red" }}>
                    Change Book On/ Book Off Time For All Selected shifts!
                  </p>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ float: "left" }}>
                      Book On Time
                    </Form.Label>
                    <Form.Control type="time" style={{ width: "100%" }} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ float: "left" }}>
                      Book Off Time
                    </Form.Label>
                    <Form.Control type="time" style={{ width: "100%" }} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary">CONFIRM</Button>
                <Button variant="secondary" onClick={handleCloseBook}>
                  CLOSE
                </Button>
              </Modal.Footer>
            </Modal>

            <Dropdown.Item
              style={{ backgroundColor: "#191970", color: "white" }}
            >
              Un Assign
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleShowConfirm}
              style={{ backgroundColor: "#228b22", color: "white" }}
            >
              Confirm
            </Dropdown.Item>
            <Modal show={showConfirm} onHide={handleCloseConfirm} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Update Shift(s)</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <p>Confirm All Selected shifts!</p>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary">CONFIRM</Button>
                <Button variant="secondary" onClick={handleCloseConfirm}>
                  CLOSE
                </Button>
              </Modal.Footer>
            </Modal>
            <Dropdown.Item
              style={{ backgroundColor: "#b22222", color: "white" }}
            >
              Un Confirm
            </Dropdown.Item>
            <Dropdown.Item
              style={{ backgroundColor: "#ffa500", color: "white" }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Table bordered hover className="mb-0 table">
        <thead>
          <tr className="bg-teal text-white">
            <th>CLIENT</th>
            <th>SID</th>
            <th>SITE NAME</th>
            <th>Mon, 15 Jan 24</th>
            <th>Tue, 16 Jan 24</th>
            <th>Wed, 17 Jan 24</th>
            <th>Thu, 18 Jan 24</th>
            <th>Fri, 19 Jan 24</th>
            <th>Sat, 20 Jan 24</th>
            <th>Sun, 21 Jan 24</th>
          </tr>
        </thead>
        <tbody></tbody>
      </Table>
    </>
  );
};

export default MultipleEdit;
