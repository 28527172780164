import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import "./Timestamp.css";
import Dashboard from "../Dashboard/Dashboard";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import SGS_LogoImg from "../Timestamp/patterns/logos/SGS_LogoImg.png";
import CompassImg from "../Timestamp/patterns/logos/CompassImg.jpeg"
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Expand } from 'lucide-react';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const Timestamp = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const [captureDetails, setCaptureDetails] = useState({ date: "", time: "" });
  const [formData, setFormData] = useState({
    name: "",
    site: "",
    description: "",
    file: null
  });

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [address, setAddress] = useState("");
  const [mapCenter, setMapCenter] = useState([40.7128, -74.0060]); // Default to New York City
  const mapRef = useRef(null);

  useEffect(() => {
    startCamera();

    // Set up geolocation
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          getAddress(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => {
      stopCamera();
    };
  }, []);

  const createCustomIcon = () => {
    return L.divIcon({
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#007bff" width="24px" height="24px">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
        </svg>
      `,
      className: '',
      iconSize: [24, 24],
      iconAnchor: [12, 24],
    });
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
      Swal.fire("Error!", "Unable to access the camera. Please check your permissions.", "error");
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  };

  const getAddress = (latitude, longitude) => {
    console.log("Fetching address for:", latitude, longitude);
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("Received address data:", data);
        if (data.address) {
          let addressString = `${data.address.county || ""}, ${data.address.state_district || ""}, ${data.address.state || ""}, ${data.address.country || ""}`;
          setAddress(addressString.trim());
          setMapCenter([latitude, longitude]);
          console.log("Set address to:", addressString.trim());
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const MapView = () => {
    const map = useMap();
    useEffect(() => {
      map.setView(mapCenter, 13);
    }, [mapCenter]);
    return null;
  };

  // const toggleFullScreen = () => {
  //   const mapContainer = document.getElementById('map-container');
  //   if (mapContainer) {
  //     if (!isFullScreen) {
  //       if (mapContainer.requestFullscreen) {
  //         mapContainer.requestFullscreen();
  //       } else if (mapContainer.mozRequestFullScreen) {
  //         mapContainer.mozRequestFullScreen();
  //       } else if (mapContainer.webkitRequestFullscreen) {
  //         mapContainer.webkitRequestFullscreen();
  //       } else if (mapContainer.msRequestFullscreen) {
  //         mapContainer.msRequestFullscreen();
  //       }
  //     } else {
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen();
  //       } else if (document.mozCancelFullScreen) {
  //         document.mozCancelFullScreen();
  //       } else if (document.webkitExitFullscreen) {
  //         document.webkitExitFullscreen();
  //       } else if (document.msExitFullscreen) {
  //         document.msExitFullscreen();
  //       }
  //     }
  //     setIsFullScreen(!isFullScreen);
  //   } else {
  //     console.error("Map container element not found");
  //   }
  // };
  

 
  const capturePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
  
    // Draw the video frame onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);



    const text = "SGS Security";
    const textSize = 16;
    const textPadding = 20;
    context.font = `bold ${textSize}px Arial`;
    context.fillStyle = "white";
    context.textBaseline = "top";
    context.fillText(text, 70, 15);

  const textWidth = context.measureText(text).width;
  const textHeight = textSize; 
  context.fillText(text, 70, 15);
    // Get current date and time
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
  
    // Set font and alignment for additional information
    context.font = "bold 16px Arial";
    context.textAlign = "left";
    context.textBaseline = "middle";
    
    const rightPadding = 20;
    const bottomPadding = 20;
    const lineHeight = 25;
  
    const dateTimeLine = `${currentDate} ${currentTime}`;
    const addressLine = address || "Address not available";
    const latLongLine = `Lat: ${location.latitude?.toFixed(6) || 'N/A'}, Long: ${location.longitude?.toFixed(6) || 'N/A'}`;
  
    // Calculate the maximum width needed for the text background
    const maxWidth = Math.max(
      context.measureText(dateTimeLine).width,
      context.measureText(addressLine).width,
      context.measureText(latLongLine).width
    );
  
    // Draw background for additional information
    context.fillStyle = "rgba(0, 0, 0, 0.5)";
    context.fillRect(
      canvas.width - maxWidth - rightPadding - 10,
      canvas.height - bottomPadding - lineHeight * 3 - 10,
      maxWidth + 20,
      lineHeight * 3 + 20
    );
  
    // Draw additional information
    context.fillStyle = "white";
    context.fillText(dateTimeLine, canvas.width - maxWidth - rightPadding, canvas.height - bottomPadding - lineHeight * 2);
    context.fillText(latLongLine, canvas.width - maxWidth - rightPadding, canvas.height - bottomPadding - lineHeight);
    context.fillText(addressLine, canvas.width - maxWidth - rightPadding, canvas.height - bottomPadding);
  
    const drawCompass = (context, x, y, radius) => {
      const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  
      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI);
      context.strokeStyle = 'white';
      context.lineWidth = 2;
      context.stroke();
      
      context.font = `${radius / 5}px Arial`;
      context.fillStyle = 'white';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      
      directions.forEach((dir, index) => {
        const angle = (index * Math.PI) / 4;
        const textX = x + (radius - 10) * Math.sin(angle);
        const textY = y - (radius - 10) * Math.cos(angle);
        context.fillText(dir, textX, textY);
      });
  
      // Draw compass needle
      context.beginPath();
      context.moveTo(x, y - radius + 5);
      context.lineTo(x - 5, y);
      context.lineTo(x + 5, y);
      context.closePath();
      context.fillStyle = 'red';
      context.fill();
      
      context.beginPath();
      context.moveTo(x, y + radius - 5);
      context.lineTo(x - 5, y);
      context.lineTo(x + 5, y);
      context.closePath();
      context.fillStyle = 'white';
      context.fill();
    };
  
    // Draw compass in bottom-left corner
    drawCompass(context, 60, canvas.height - 60, 30);
  
    const image = canvas.toDataURL();
    setCapturedImage(image);
    setCaptureDetails({ date: currentDate, time: currentTime });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!capturedImage) {
        Swal.fire("Error!", "Please capture an image first.", "error");
        return;
      }
  
      // Convert base64 image to blob
      const base64Response = await fetch(capturedImage);
      const blob = await base64Response.blob();
      const imageFile = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
  
      const payload = new FormData();
      // payload.append('site_id', formData.site_id ? parseInt(formData.site_id) : '');
      payload.append('site', formData.site || '');
      // payload.append('name_id', formData.name_id ? parseInt(formData.name_id) : '');
      payload.append('name', formData.name || '');
      payload.append('date', captureDetails.date || '');
      payload.append('time', captureDetails.time || '');
      payload.append('description', formData.description || '');
      payload.append('file', imageFile);
  
      console.log("Payload before submission:", Object.fromEntries(payload));
  
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_timestamp",
        payload,
        {
          headers: {
            'Authorization': "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            'Content-Type': 'multipart/form-data'
          }
        }
      );
  
      console.log("API Response:", response.data);
  
      if (response.data && response.data.success) {
        Swal.fire("Success!", "Attendance Added Successfully!", "success");
        // Reset form or redirect as needed
      } else {
        Swal.fire("Error!", "Failed to add attendance.", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire("Error!", "An error occurred while submitting the form.", "error");
    }
  };
  

  const [sites, setSites] = useState([]);
  const [names, setNames] = useState([]);

  useEffect(() => {
    fetchNames();
  }, []);
  
  const fetchNames = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_timestamp_staff",
        {},
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const options = response.data.data.map((item) => ({
          value: item.value,
          label: item.label,
        }));
        setNames(options);
        console.log("Names Options:", options);
      } else {
        console.error("Failed to fetch names");
      }
    } catch (error) {
      console.error("Error fetching names:", error);
    }
  };
  
  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        {},
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success)
          ? response.data.success
          : Object.values(response.data.data);
        const options = successData.map((type) => ({
          value: type.site_name,
          label: type.site_name,
        }));
       
        setSites(options);
        console.log("Employee Options:", options);
      } else {
        console.error("Failed to fetch Site types");
      }
    } catch (error) {
      console.error("Error fetching Site types:", error);
    }
  };

  const handleSiaTypeChange = (selectedOption, { name }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption.label,
      [`${name}_id`]: selectedOption.value // Store the value directly, no parseInt here
    }));
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}>
        <div className="">
          <h3 className="text-white"style={{fontSize:'15px'}}>Timestamp</h3>
        </div>
      
      </div>
      <Container fluid className="mt-4">
        <Card className="shadow-sm">
          <Card.Body>
            <h4 className="text-center text-success mb-4">STAFF ATTENDANCE</h4>
            <Form>
              <Row className="g-4 align-items-stretch">
                <Col md={5}>
                  <Card className="bg-light h-100">
                    <Card.Body className="d-flex flex-column">
                      <video
                        ref={videoRef}
                        autoPlay
                        className="w-100 rounded mb-3"
                        style={{ maxHeight: "300px", objectFit: "cover" }}
                      />
                      <Button
                        variant="primary"
                        onClick={capturePicture}
                        className="mt-auto">
                        <i className="fas fa-camera me-2"></i>Take Snapshot
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={7}>
                  <Card className="bg-light h-100">
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <canvas
                        ref={canvasRef}
                        width={480}
                        height={360}
                        style={{ display: "none" }}
                      />
                     {capturedImage ? (
  <div className="position-relative" style={{ width: '60%', height: '300px' }}>
    <img
      src={capturedImage}
      alt="Captured"
      className="img-fluid rounded mb-3"
      style={{ width: '100%',objectFit: 'cover' }}
    />
    
    <div
                            className="map-view position-absolute"
                            style={{
                              top: '10px',
                              right: '10px',
                              height: '150px',
                              width: '150px',
                              overflow:'-moz-hidden-unscrollable',
                              borderRadius: '5px',
                              border: '2px solid white',
                            }}
                          >                <MapContainer
                  center={mapCenter}
                  zoom={13}
                  style={{ height: '100%', width: '100%' }}
                  ref={mapRef}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Marker position={mapCenter} icon={createCustomIcon()} />
                  {/* <Marker position={mapCenter} /> */}
                  <MapView />
                </MapContainer>
                {/* <Button 
                  variant="light" 
                  className="position-absolute top-0 end-0 m-2"
                  onClick={toggleFullScreen}
                >
                  <Expand size={24} />
                </Button> */}
              </div>

    <div className="text-center w-100">
      <p className="mb-1">
        <small className="text-muted">Captured on:</small>
      </p>
      <p className="mb-1">
        <span className="badge bg-primary me-2">
          {captureDetails.date}
        </span>
        <span className="badge bg-secondary">
          {captureDetails.time}
        </span>
      </p>
      <p className="mb-0">
        <small className="text-muted">{address}</small>
      </p>
    </div>
  </div>
) : (
                        <p className="text-muted">
                          Captured image will appear here
                        </p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4 g-3">
                <Col md={3}>
                
                  <Form.Group>
                    <Form.Label
                      style={{
                        float: "left",
                        display: "block",
                        marginBottom: "10px",
                        marginLeft: "10px",
                      }}>
                      Site
                    </Form.Label>
                    <Select
                           name="site"
                           value={sites.find(option => option.value === formData.site)}
                           onChange={handleSiaTypeChange}
                           options={sites}
                      isSearchable
                      placeholder="Select Sites..."
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                        }),
                      }}
                    />
                  </Form.Group>
                  {/* <input type="hidden" name="site_id" value={0} /> */}

                  </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label
                      style={{
                        float: "left",
                        display: "block",
                        marginBottom: "10px",
                      }}>
                      Name
                    </Form.Label>
                    <Select
                          name="name"
                          value={names.find(option => option.value === formData.name)}
  onChange={handleSiaTypeChange}
  options={names}
                      isSearchable
                      placeholder="Select Name..."
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                        }),
                      }}
                    />
                  </Form.Group>
                  {/* <input type="hidden" name="name_id" value={0} /> */}
                </Col> 
                <Col md={3}>
                  <Form.Group>
                    <Form.Label style={{ float: "left" }}>Date</Form.Label>
                    <Form.Control
                      type="text"
                      name="date"
                      value={new Date().toLocaleDateString()}
                      size="sm"
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label style={{ float: "left" }}>Time</Form.Label>
                    <Form.Control
                      type="text"
                      value={currentTime}
                      size="sm"
                      name="time"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label style={{ float: "left" }}>
                      Work Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      placeholder="Enter Work Description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12} className="text-center">
                  <Button variant="success" onClick={handleSubmit} size="lg">
                    <i className="fas fa-check-circle me-2"></i>Submit
                    Attendance
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Timestamp;
