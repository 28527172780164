import React ,{useState}from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useLocation} from "react-router-dom";
import InvoiceInformation from './InvoiceInformation';
import Deduct from './Deduct';
import UpdateRates from './UpdateRates';
import ClientPayment from './ClientPayment';
import ManageTravel from './ManageTravel';
import Dashboard from '../Dashboard/Dashboard';

const FinanceSettings = () => {
    const [activeSection, setActiveSection] = useState("Invoice Information");

    const tabs = [
        "Invoice Information",
        "Deduct Break Time",
        "Update Rates",
        "Client Payment Terms",
        "Manage Travel Expense",
    ]

    const renderActiveSection = () => {
        switch (activeSection) {
          case "Invoice Information":
            return <InvoiceInformation />;
            case "Deduct Break Time":
                return <Deduct/>
                case "Update Rates":
                    return <UpdateRates/>
                    case "Client Payment Terms":
                        return <ClientPayment/>
                        case "Manage Travel Expense":
                            return <ManageTravel/>
          default:
            return null;
        }
      };
      const location = useLocation();

      const getNavigationPath = () => {
        const pathParts = location.pathname.split('/').filter(Boolean);
        return [
          { name: 'Settings', bold: false },
          { name: 'Finance Settings', bold: true },
    
    
        ];
      };
      
      const navigationPath = getNavigationPath();
      return (
        <>
          <Dashboard />
          <div
            className="toolbar py-2"
            id="kt_toolbar"
            style={{backgroundColor: '#BF0404' }}>
            <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
                <React.Fragment key={index}>
                  <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                    {part.name}
                  </span>
                  {index < navigationPath.length - 1 && " / "}  
                </React.Fragment>
              ))}</h3>        </div>
   
    <div className="container">
            <div className="tabs">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`tab ${activeSection === tab ? "active" : ""}`}
                  onClick={() => setActiveSection(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
            {renderActiveSection()}
          </div>
</>
  )
}

export default FinanceSettings
