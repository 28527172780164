import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import Dashboard from "../Dashboard/Dashboard";
import "./Roster.css";
import axios from "axios";
import headerBackground from "../patterns/header-bg.png";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { useLocation ,useNavigate} from "react-router-dom";
import { format, parseISO  } from 'date-fns';
import Swal from 'sweetalert2';
import { Autocomplete, TextField } from '@mui/material';
import { Plus, X } from 'lucide-react';
import { CheckBox } from "@mui/icons-material";


const AddShift = () => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'yyyy-MM-dd');
    } catch (error) {
      return '';
    }
  };

  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);

  useEffect(() => {
    fetchStaffData();
  }, []);

  const fetchStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/shift_staff",
        { },
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Staff Data:", response);
      setStaffOptions(response.data.data);
      staffOptions.map(staff=>({
        value: staff.name,
        label: staff.name,     
      }))
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const handleStaffChange = (selectedOptions) => {
    setSelectedStaff(selectedOptions);
    setFormData(prevState => ({
      ...prevState,
      shift_assign_staff: selectedOptions.map(option => option.label).join(', ')
    }));
  };

  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubStaff, setSelectedSubStaff] = useState([]);
  
  useEffect(() => {
    fetchSubStaffData();
  }, []);
  
  const fetchSubStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Subcontractor Data:", response);
      const formattedOptions = response.data.data.map(staff => ({
        value: staff.subcon_company_name,
        label: staff.subcon_company_name,
      }));
      setSubOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };
  
  const handleSubStaffChange = (selectedSubOptions) => {
    setSelectedSubStaff(selectedSubOptions);
    setFormData(prevState => ({
      ...prevState,
      shift_assign_subemp: selectedSubOptions.map(option => option.label).join(', ')
    }));
  };
 
  const [shiftType, setShiftType] = useState('sameMultipleShifts');
  const [shifts, setShifts] = useState([{ shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '' }]);
  const [formData, setFormData] = useState({
    staff:"",
    shift_title:"",
    shift_client_name: "",
    shift_site_name: "",
    shift_assign_staff: "",
    shift_guard_group:"",
    shift_assign_sub:"",
    // shift_pay_rate:"",
    shift_assign_subemp:"",
    shift_pay_amount: "",
    shift_pay_expenses: "",
    shift_bill_rate: "",
    shift_bill_amount: "",
    shift_bill_expenses: "",
    shift_type: "",
    shift_penalty: "",
    shift_start_date: [],
    shift_end_date: [],
    shift_start_time: [],
    shift_end_time: [],
    shift_break: [],
    shift_check_call: false,
    shift_unpaid: false,
    shift_training: false,
    shift_confirm: false,
    // shift_completed:false,
    // shift_cancelled:false,  
    shift_unconfirm: false,
    shift_comments: "",
    shift_instructions: "",
    check_break:"false",
    company_branch: "1",
  });

  
  // const [shifts, setShifts] = useState([]);
  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState([]);



  const handleShiftTypeChange = (e) => {
    setShiftType(e.target.value);
    if (e.target.value === 'differentMultipleShifts') {
      setShifts([{ shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '' }]);
    }
  };

  // const handleShiftChange = (index, field, value) => {
  //   const newShifts = [...shifts];
  //   newShifts[index][field] = value;
  //   setShifts(newShifts);
  // };


  const addShift = () => {
    setShifts([...shifts, { shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '', selected: false }]);
  };

  const toggleSelectShift = (index) => {
    const updatedShifts = shifts.map((shift, i) => i === index ? { ...shift, selected: !shift.selected } : shift);
    setShifts(updatedShifts);
  };

  const removeSelectedShifts = () => {
    const newShifts = shifts.filter(shift => !shift.selected);
    setShifts(newShifts);
  };

  const handleShiftChange = (index, field, value) => {
    const updatedShifts = shifts.map((shift, i) => i === index ? { ...shift, [field]: value } : shift);
    setShifts(updatedShifts);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    const requiredFields = [
      "shift_client_name",
      // "shift_title",
      "shift_site_name",
      // "shift_type",
      // "staff",
      // "shift_comments",
      // "shift_instructions",
    ];
  
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });
  
    if (shiftType === 'sameMultipleShifts') {
      if (!formData.shift_start_date || !formData.shift_end_date || !formData.shift_start_time || !formData.shift_end_time) {
        newErrors.shift_dates = "All shift dates and times are required";
      }
    } else if (shiftType === 'differentMultipleShifts') {
      if (shifts.some(shift => !shift.shift_start_date || !shift.shift_start_time || !shift.shift_end_time)) {
        newErrors.shifts = "All shift dates and times are required for different multiple shifts";
      }
    }
  
    // if (formData.staff === 'Employee' && (!formData.shift_assign_staff || formData.shift_assign_staff.length === 0)) {
    //   newErrors.shift_assign_staff = "Please select at least one employee";
    // }
  
    // if (formData.staff === 'Subcontractor' && (!formData.shift_assign_sub || formData.shift_assign_sub.length === 0)) {
    //   newErrors.shift_assign_sub = "Please select at least one subcontractor";
    // }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        let shiftData = { ...formData };

        if (shiftType === 'sameMultipleShifts') {
          // Create arrays for the date range
          const startDate = new Date(formData.shift_start_date);
          const endDate = new Date(formData.shift_end_date);
          const dateArray = [];
          for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            dateArray.push(new Date(date).toISOString().split('T')[0]);
          }

          shiftData.shift_start_date = dateArray;
          shiftData.shift_end_date = dateArray;
          shiftData.shift_start_time = new Array(dateArray.length).fill(formData.shift_start_time);
          shiftData.shift_end_time = new Array(dateArray.length).fill(formData.shift_end_time);
          shiftData.shift_break = new Array(dateArray.length).fill(formData.shift_break);
        } else if (shiftType === 'differentMultipleShifts') {
          shiftData.shift_start_date = shifts.map(shift => shift.shift_start_date);
          shiftData.shift_start_time = shifts.map(shift => shift.shift_start_time);
          shiftData.shift_end_time = shifts.map(shift => shift.shift_end_time);
          shiftData.shift_break = shifts.map(shift => shift.shift_break);
          shiftData.shift_end_date = shiftData.shift_start_date; 
        }

        const response = await axios.post(
          "https://srltd.megworld.in/api/add_shift_new",
          shiftData,
          {
            headers: {
              Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
              "Content-Type": "application/json",
            },
          }
        );
        
        console.log("Add Shift Response:", response);
        if (response.data && response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Shift Added',
            text: 'Shift has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          navigate('/clientroster');
        }
      } catch (error) {
        console.error("Error adding shift:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while adding the shift. Please try again.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Form Validation',
        text: 'Please correct the errors in the form before submitting.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    }
  };

 
const [clientName,setClientName] = useState([])
const [siteName,setSiteName] = useState([])

const [siteRates, setSiteRates] = useState({});

  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.client_name,
          label: type.client_name,
        }
      ));
 
    setClientName(options);
        console.log("Client Options:", options);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };

  
  useEffect(() => {
    fetchSite();
  }, []);

  const fetchSite = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(site => ({
          value: site.site_name,
          label: site.site_name,
          rates: {
            guarding: site.site_bill_guarding,
            supervisor: site.site_bill_supervisor
          }
        }));
 
        setSiteName(options);
        console.log("Site Options:", options);    

        // Create a map of site names to their rates
        const ratesMap = {};
        options.forEach(site => {
          ratesMap[site.value] = site.rates;
        });
        setSiteRates(ratesMap);
      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };

  const handleSiteChange = (selectedOption) => {
    setFormData(prevState => ({
      ...prevState,
      shift_site_name: selectedOption ? selectedOption.value : '',
      shift_bill_rate: '', // Reset the bill rate when site changes
      shift_bill_amount: '' // Reset the bill amount when site changes
    }));
  };

  const handleBillRateChange = (e) => {
    const selectedRate = e.target.value;
    const selectedSite = formData.shift_site_name;
    
    setFormData(prevState => ({
      ...prevState,
      shift_bill_rate: selectedRate,
      shift_bill_amount: siteRates[selectedSite] ? siteRates[selectedSite][selectedRate === 'Site Guard' ? 'guarding' : 'supervisor'] : ''
    }));
  };
  const handleSiaTypeChange = (selectedOption, { name }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };
  
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Roster', bold: false },
      { name: 'Add Multiple Guard Shift', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_emp_auto',
        {},
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      shift_assign_staff: selectedOptions.map(option => option.label).join(', ')
    }));
    setSelectedEmployees(selectedOptions);
  };

  const [subcontractors, setSubcontractors] = useState([]);
  const [selectedSubcontractors, setSelectedSubcontractors] = useState([]);

  useEffect(() => {
    fetchSubcontractors();
  }, []);

  const fetchSubcontractors = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_sub_auto',
        {},
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      setSubcontractors(response.data);
    } catch (error) {
      console.error('Error fetching subcontractors:', error);
    }
  };

  const handleSubChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      shift_assign_sub: selectedOptions.map(option => option.label).join(', ')
    }));
    setSelectedSubcontractors(selectedOptions);
  };
 

  const [subEmployees, setSubEmployees] = useState([]);
  const [selectedSubEmployees, setSelectedSubEmployees] = useState([]);

  useEffect(() => {
    fetchSubEmployees();
  }, []);

  const fetchSubEmployees = async (id) => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_subemp_auto',
        {id:1},  
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      console.log("Sub employee auto :",response)
      setSubEmployees(response.data);
    } catch (error) {
      console.error('Error fetching sub employees:', error);
    }
  };

  const handleSubEmpChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      shift_assign_subemp: selectedOptions.map(option => option.label).join(', ')
    }));
    setSelectedSubEmployees(selectedOptions);
  };


  const [guardGroup, setGuardGroup] = useState([]);
  const [selectedGuardGroup, setSelectedGuardGroup] = useState([]);

  useEffect(() => {
    fetchGuardGroup();
  }, []);

  const fetchGuardGroup = async (id) => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/guard_group_show',
        {id:id},  
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      console.log("Guard Group auto :",response)
      const formattedGuardGroups = response.data.data.map(group => ({
        value: group.guard_group,
        label: group.guard_group
      }));
      
      setGuardGroup(formattedGuardGroups);     
    } catch (error) {
      console.error('Error fetching Guard Group:', error);
    }
  };

  const handleGuardGroupChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      shift_guard_group: selectedOptions.map(option => option.value).join(', ')
    }));
    setSelectedGuardGroup(selectedOptions);
  };

  

  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>      
        <div className="col-auto">
          <Link to="/addguard">
            <button
          style={{padding:'8px 8px',marginLeft:'250px'}}
          className="btn btn-primary">
              
              Add Single Guard
            </button>
          </Link>
       
        </div>
      </div>

      <div className="container mt-4">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
         
            <Form.Group as={Col} md={6} controlId="clientName">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Client Name</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                name="shift_client_name"
                value={clientName.find(option => option.value === formData.shift_client_name)}
                onChange={handleSiaTypeChange}
                options={clientName}
                isSearchable
                placeholder="Select Client Name..."
                            isInvalid={!!errors.shift_client_name}
          
              />
            </Form.Group>
            
            {/* <Form.Group as={Col} md={3} controlId="payableRates"></Form.Group> */}
            <Form.Group as={Col} md={6} controlId="shiftTitle">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Shift Title</Form.Label>
              <Form.Control
              name="shift_title"
                value={formData.shift_title}
                onChange={handleInputChange}
                placeholder="Enter Shift Title..."
                            isInvalid={!!errors.shift_title}
          
              />
            </Form.Group>
             {/* <Form.Group as={Col} md={3} controlId="staffRate">
               <Form.Label style={{ float: "left" }} className="text-warning">
                Payable Rates
              </Form.Label>
              <Form.Select
                style={{ width: "100%" }}
                name="shift_pay_rate"
                value={formData.shift_pay_rate}
                onChange={handleInputChange}
                isInvalid={!!errors.shift_pay_rate}>    
                  <option>Select Rate</option>
                <option>Staff Rate</option>
                <option>Site Rate</option>
              </Form.Select>
            </Form.Group> */}
            {/* <Form.Group as={Col} md={3} controlId="staffRateSelected">
              <Form.Label style={{ float: "left" }}>
                Staff Rate Selected
              </Form.Label>
              <Form.Control
                //  name="shift_client_name"
                //  value={formData.shift_client_name}
                //  onChange={handleInputChange}
                placeholder="Staff Rate Selected"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group> */}
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="siteName">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Site Name</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                 name="shift_site_name"
                value={siteName.find(option => option.value === formData.shift_site_name)}
                onChange={handleSiteChange}
                options={siteName}
                isSearchable
                placeholder="Select Site Name..."
                isInvalid={!!errors.shift_site_name}
                
              />
            </Form.Group>
            {/* <Form.Group as={Col} md={3} controlId="amount"></Form.Group> */}
            
            <Form.Group as={Col} md={6} controlId="payableRate">
    <Form.Label style={{ float: "left" }} className="text-warning">
      <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
      Amount
    </Form.Label>
    <div className="input-group">
      <span className="input-group-text">£</span>
      <Form.Control
        name="shift_pay_amount"
        value={formData.shift_pay_amount}
        isInvalid={!!errors.shift_pay_amount}
        onChange={handleInputChange}
        placeholder="0.00"
        type="number"
        step="0.01"
        min="0"
        required

      />
      <span className="input-group-text">.00</span>
    </div>
  </Form.Group>
          </Row>
          <Row>
          <Col md={6}></Col>
  <Col md={6}>
    <Form.Group controlId="payableExpensesInput" className="mb-3">
      <Form.Label style={{ float: "left" }} className="text-warning">
        <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
        Payable Expenses
      </Form.Label>
      <div className="input-group">
        <span className="input-group-text">£</span>
        <Form.Control
          name="shift_pay_expenses"
          value={formData.shift_pay_expenses}
          onChange={handleInputChange}
          isInvalid={!!errors.shift_pay_expenses}
          placeholder="0.00"
          type="number"
          step="0.01"
          min="0"
        />
        <span className="input-group-text">.00</span>
      </div>
    </Form.Group>
    </Col>
        </Row>
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
          <Form.Group controlId="payableExpensesInput">
            <Form.Label style={{ float: "left" }} >
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Payable Expenses Details
            </Form.Label>
            <Form.Control
              name="shift_pay_expenses"
              value={formData.shift_pay_expenses}
              onChange={handleInputChange}
              isInvalid={!!errors.shift_pay_expenses}
              placeholder="Enter Payable Expenses Details"
              type="text"
            />
          </Form.Group>
        </Col>
        </Row>
        <Row>
        <Col md={6}></Col>
        <Col md={6}>
          <Form.Group controlId="billableRates">
            <Form.Label style={{ float: "left" }} className="text-success">
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Billable Rates
            </Form.Label>
            <Form.Select
              style={{ width: "100%" }}
              name="shift_bill_rate"
              value={formData.shift_bill_rate}
              onChange={handleBillRateChange}
                            isInvalid={!!errors.shift_bill_rate}
            >
              <option value="">Select Rate</option>
              <option value="Site Guard">Site Guard</option>
              <option value="Site Supervisor">Site Supervisor</option>
            </Form.Select>
          </Form.Group>
        </Col>
        </Row>
        <Row>
        <Col md={6}></Col>
  <Form.Group as={Col} md={6} controlId="billableExpenses">
    <Form.Label style={{ float: "left" }} className="text-success">
      <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
      Amount
    </Form.Label>
    <InputGroup>
    <InputGroup.Text>£</InputGroup.Text>
      <Form.Control
        name="shift_bill_amount"
        value={formData.shift_bill_amount}
        onChange={handleInputChange}
        isInvalid={!!errors.shift_bill_amount}
        placeholder="0.00"
        type="number"
        step="0.01"
        min="0"
      />
    <InputGroup.Text>.00</InputGroup.Text>
    </InputGroup>
  </Form.Group>
            </Row>
          <Row className="mb-3">
          <Form.Group as={Col} md={6} controlId="billableAmount"></Form.Group>
  <Form.Group as={Col} md={6} controlId="billableExpenses">
    <Form.Label
      style={{ float: "left" }}
      className="text-success mt-3">
      Billable Expenses
    </Form.Label>
    <div className="input-group">
      <span className="input-group-text">£</span>
      <Form.Control
        name="shift_bill_expenses"
        value={formData.shift_bill_expenses}
        onChange={handleInputChange}
        isInvalid={!!errors.shift_bill_expenses}
        placeholder="0.00"
        type="number"
        step="0.01"
        min="0"
      />
      <span className="input-group-text">.00</span>
    </div>
  </Form.Group>
          </Row>
          <Row className="mb-3 ">
          <Form.Group as={Col} md={6} controlId="shiftType">
              <Form.Label
                style={{ float: "left" }}
                className="mt-3">
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                Shift Type
              </Form.Label>
              <Form.Control
                name="shift_type"
                value={formData.shift_type}
                onChange={handleInputChange}
                isInvalid={!!errors.shift_type}
                placeholder="Shift Type"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
            <Col md={6} className="mt-3">
              <Form.Group controlId="shiftPenalty">
                <Form.Label className="text-danger" style={{ float: "left" }}>
                  Penalty / Deduction
                </Form.Label>
                <div className="input-group">
                <span className="input-group-text">£</span>
                <Form.Control
                  type="number"
                  name="shift_penalty"
                  value={formData.shift_penalty}
                  onChange={handleInputChange}
                  isInvalid={!!errors.shift_penalty}
                  placeholder="Penalty/Deduction"
                  min="0"
                  step="0.01"
                />
                   <span className="input-group-text">.00</span>
                   </div>
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6}>            </Col>

            <Col md={6} className="mt-3">
              <Form.Group controlId="shiftPenalty">
                <Form.Label  style={{ float: "left" }}>
                  Penalty / Deduction Details
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="text"
                  name="shift_penalty"
                  value={formData.shift_penalty}
                  onChange={handleInputChange}
                  isInvalid={!!errors.shift_penalty}
                  placeholder="Enter Penalty/Deduction Details"
                 
                />
              </Form.Group>
            </Col>
            </Row>
            <hr style={{borderTop: '2px solid #e0e0e0', margin: '20px 0',marginTop:'50px'}} />
            <div className="">
            <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="staff">
            <Form.Label style={{ marginBottom: '10px', display: 'block', float: 'left' }}>
            <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Staff</Form.Label>
            <Form.Select
              name="staff"
              value={formData.staff}
              onChange={handleInputChange}
              isInvalid={!!errors.staff}
            >
              <option>Select Staff...</option>
              <option>Employee</option>
              <option>Subcontractor</option>
            </Form.Select>
          </Form.Group>
        </Col>

        
        
      </Row>

      {formData.staff === 'Employee' && (
        <>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="guardGroup">
              <Form.Label style={{ float: "left" }}>Guard Group</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isMulti
                name="shift_guard_group"
  options={guardGroup} // This should now be an array of objects with value and label
  value={selectedGuardGroup}
  onChange={handleGuardGroupChange}
                placeholder="Select..."
              />
            </Form.Group>
          </Col>

         
          </Row>

<Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="employees">
              <Form.Label style={{ float: "left" }}>Employees</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isMulti
                name="shift_assign_staff"
          options={employees}
          value={selectedEmployees}
          onChange={handleSelectChange}
                placeholder="Select..."
              />
            </Form.Group>
          </Col>
        
            </Row>
          </>
      )}

      {formData.staff === 'Subcontractor' && (
        <>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="subcontractor">
              <Form.Label style={{ float: "left" }}>Subcontractor</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isMulti
                name="shift_assign_sub"
                options={subcontractors}
                value={selectedSubcontractors}
                onChange={handleSubChange}                placeholder="Select..."
              />
            </Form.Group>
          </Col>
     
          </Row>
          <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="subEmployees">
              <Form.Label style={{ float: "left" }}>Sub Employees</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isMulti
                name="shift_assign_subemp"
                options={subEmployees}
                value={selectedSubEmployees}
                onChange={handleSubEmpChange}                placeholder="Select..."
              />
            </Form.Group>
          </Col>

          
         
        </Row>

          
          </>
      )}

            </div>
            <hr style={{borderTop: '2px solid #e0e0e0', margin: '20px 0',marginTop:'50px'}} />
            <Form className="p-4 bg-white rounded shadow-sm">
            <Row className="mb-3 align-items-center">
        <h3 className="col-md-3">Different Multiple Shifts</h3>
        <Col md={3}>
          <Button variant="success" onClick={addShift} className="me-2">
            <Plus size={20} />
          </Button>
          <Button variant="danger" onClick={removeSelectedShifts}>
            <X size={20} />
          </Button>
        </Col>
      </Row>
   
      {shifts.map((shift, index) => (
        <Row key={index} className="mb-3 align-items-end">
          <Col md={1}>
            <Form.Check
            style={{marginLeft:'35px'}}
              type="checkbox"
              checked={shift.selected}
              onChange={() => toggleSelectShift(index)}
            />
          </Col>
          <Col md={2}>
            <Form.Label style={{ float: 'left' }}>Shift Mode</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shift Mode"
              value={shift.shift_mode || ''}
              onChange={(e) => handleShiftChange(index, 'shift_mode', e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Label style={{ float: 'left' }}>Shift Date</Form.Label>
            <Form.Control
              type="date"
              value={shift.shift_start_date}
              onChange={(e) => handleShiftChange(index, 'shift_start_date', e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Label style={{ float: 'left' }}>Start Time</Form.Label>
            <Form.Control
              type="time"
              value={shift.shift_start_time}
              onChange={(e) => handleShiftChange(index, 'shift_start_time', e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Label style={{ float: 'left' }}>End Time</Form.Label>
            <Form.Control
              type="time"
              value={shift.shift_end_time}
              onChange={(e) => handleShiftChange(index, 'shift_end_time', e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Label style={{ float: 'left' }}>Break Time</Form.Label>
            <Form.Select
              value={shift.shift_break}
              onChange={(e) => handleShiftChange(index, 'shift_break', e.target.value)}
            >
              <option value="">please select...</option>
              <option value="30">30 minutes</option>
              <option value="60">60 minutes</option>
            </Form.Select>
          </Col>
        </Row>
      ))}

<Row className="mt-10">
  
        <Col md={6}>
          <Form.Check
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>Add Break to Shift?</span>}
            id="addBreakToShift"
            name="shift_break"
          />
          <Form.Check
          className="mt-5"
            type="checkbox"
            label={<span style={{ marginLeft: '8px' }}>Add Check Calls to Shift?</span>}

            id="addCheckCallsToShift"
            name="shift_check_call"
            value={formData.shift_check_call}
          />
        </Col>      

          
        <Col md={6}>
  <Row className="mb-4">
    <Col md={6}>
      <Form.Check
        type="checkbox"
        label={<span style={{ marginLeft: '8px' }}>Unpaid Shift</span>}
        id="unpaidShift"
        name="shift_unpaid"
        value={formData.shift_unpaid}
      />
    </Col>
    <Col md={6}>
      <Form.Check
        type="checkbox"
        label={<span style={{ marginLeft: '8px' }}>Training Shift</span>}
        id="trainingShift"
        name="shift_training"
        value={formData.shift_training}
      />
    </Col>
  </Row>
  <Row className="mb-4">
    <Col md={6}>
      <Form.Check
        type="radio"
        label={<span style={{ marginLeft: '8px' }}>Confirm Shift</span>}

        id="confirmShift"
        name="shift_confirm"
        value={formData.shift_confirm}
      />
    </Col>
    <Col md={6}>
      <Form.Check
        type="radio"
        label={<span style={{ marginLeft: '8px' }}>Unconfirm Shift</span>}

        id="unconfirmShift"
        name="shift_confirm"
        value={formData.shift_confirm}
        defaultChecked
      />
    </Col>
  </Row>
  <Row>
    <Col>
      <Form.Check
        type="checkbox"
        label={<span style={{ marginLeft: '8px' }}>PO Number Received?</span>}

        id="poNumberReceived"
      />
    </Col>
  </Row>
</Col>
            {/* <Col md={6}></Col> */}
        <Col md={6}>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" name="shift_comments" value={formData.shift_comments} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        <Col md={6}></Col>
          <Col md={6}>
          <Form.Group>
            <Form.Label>Shift Instructions</Form.Label>
            <Form.Control as="textarea"   name="shift_instructions" value={formData.shift_instructions} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        </Row>

      {/* <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Col>
      </Row> */}

      {/* <Row className="mb-3">
       
      </Row> */}

      <Row>
        <Col className="text-end">
          <Button variant="success" style={{ backgroundColor: '#4ad991', border: 'none' }} onClick={handleSubmit}>SAVE</Button>
        </Col>
      </Row>
    </Form>
        </Form>
      </div>
    </>
  );
};

export default AddShift;
