import React from "react";

const Documents = () => {
  return (
    <div className="employee-info" style={{ marginTop: "30px" }}>
      <h3>Employee Information</h3>
      <table className="table table-striped" style={{ fontSize: "15px" }}>
        <thead className="thead">
          <tr>
            <th>S.NO</th>
            <th>Document</th>
            <th>File Name</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="9" className="text-center">
              No data available in table....
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Documents;
