import React,{useState} from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Nav, Form, Button } from "react-bootstrap";
import PreviousEmployment from "./PreviousEmployment";
import PreviousEducation from "./PreviousEducation";
import SelfEmployment from "./SelfEmployment";

const Previous = () => {

  const [selectedForm, setSelectedForm] = useState("Previous Employment");

  const handleRadioChange = (event) => {
    setSelectedForm(event.target.value);
  };

  const renderForm = () => {
    switch (selectedForm) {
      case "Previous Employment":
        return <PreviousEmployment/>;
      case "Previous Education":
        return <PreviousEducation />;
      case "Self Employment":
        return <SelfEmployment />;
      default:
        return null;
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };
  return (
    <Container className="px-0">
      <Row className="mx-0">
        <Col md={10} className="main-content">
          <Row>
            <Col md={9} className="form-content">
              <h4 style={{ float: "right", marginLeft: "100px" }}>
              Date of registration: <span style={{ color: "red" }}>{getCurrentDate()}</span>
                            </h4>
              <Form>
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col md={3}>
                    <Form.Check
                      type="radio"
                      label="Previous Employment"
                      name="formType"
                      value="Previous Employment"
                      checked={selectedForm === "Previous Employment"}
                      onChange={handleRadioChange}
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Check
                      type="radio"
                      label="Previous Education"
                      name="formType"
                      value="Previous Education"
                      checked={selectedForm === "Previous Education"}
                      onChange={handleRadioChange}
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Check
                      type="radio"
                      label="Self Employment/Trade"
                      name="formType"
                      value="Self Employment"
                      checked={selectedForm === "Self Employment"}
                      onChange={handleRadioChange}
                    />
                  </Col>
                </Row>
              </Form>
              {renderForm()}
             
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
};

export default Previous;
