import React, { useState } from 'react'
import { FormControl, InputGroup, ListGroup, Table } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";


const Deduct = () => {

  const [deduct,setDeduct] = useState(false);

  const handleShowDeduct =()=>{
    setDeduct(true);
  }
  const handleCloseDeduct =()=>{
    setDeduct(false);
  }
  return (
    <div>
    <Table striped bordered hover style={{ marginTop: "20px" }}>
      <thead>
        <tr style={{ fontSize: "16px" }}>
          <th>ID</th>
          <th>Branch</th>
          <th>Deduct Break Time In Invoice</th>
          <th>Deduct Break Time In Payroll</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ fontSize: "15px" }}>
          <td>1</td>
          <td>Head Office</td>
<td>No</td>
<td>Yes</td>
          <td>
            <button className="btn-success" onClick={handleShowDeduct}>
              <i
                className="fas fa-pen"
                style={{
                  color: "white",
                  padding: "5px",
                  fontSize: "16px",
                }}></i>
            </button>
          </td>
        </tr>
      </tbody>
    </Table>
    
    <Modal show={deduct} onHide={handleCloseDeduct}>
        <Modal.Header closeButton>
          <Modal.Title>Deduct Break Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Invoice Information</Form.Label>
              <Form.Control
              as="textarea"
                style={{ width: "100%" }}
                placeholder="Invoice Information"
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
            Deduct Break Time Invoice
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
            Deduct Break Time Payroll
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
         
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseDeduct}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
  </div>
  )
}

export default Deduct;
