import React, { useState } from 'react'
import { FormControl, InputGroup, ListGroup, Table } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";

const ClientPayment = () => {

  const [clientPayments,setClientPayments] = useState(false);

  const handleClientPayments =()=>{
    setClientPayments(true);
  }
  const handleCloseClientPayments =()=>{
    setClientPayments(false);
  }
  return (
    <div>
    <Table striped bordered hover style={{ marginTop: "20px" }}>
      <thead>
        <tr style={{ fontSize: "16px" }}>
          <th>ID</th>
          <th>Print Payment Terms in Client Invoices</th>
          <th>Branch</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ fontSize: "15px" }}>
          <td>1</td>
          <td></td>
          <td>Head Office</td>
          <td>
            <button className="btn-success" onClick={handleClientPayments}>
              <i
                className="fas fa-pen"
                style={{
                  color: "white",
                  padding: "5px",
                  fontSize: "16px",
                }}></i>
            </button>
          </td>
        </tr>
      </tbody>
    </Table>
    
    <Modal show={clientPayments} onHide={handleCloseClientPayments}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Payments Terms Printing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
              Print Payment Terms in Client Invoice
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
          
          
         
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseClientPayments}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
  </div>
  )
}

export default ClientPayment
