import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Nav, Form, Button, } from "react-bootstrap";
import {  Modal, Alert,Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from "axios";
import Dashboard from "../../Dashboard/Dashboard";
import PreviewImg from "../Employee/patterns/previewimg.jpg";
import Select from 'react-select';
import Swal from 'sweetalert2';

const BasicInfo = () => {
  const [formData,setFormData]=useState({
    employee_id:"1",
      staff_id:"",
      pin :"",
      date_of_registration : "",
      job_title : "",
      holiday_entitlement : "",
      contract_hours:"",
      reference : "",
      fore_name : "",
      middle_name : "",
      sur_name : "",
      ni_number : "",
      gender : "",
      d_o_b : "",
      nationality : "",
      edbs_number : "",
      contact_number : "",
      alt_contact_no: "",
      email : "",
      next_kin : "",
      next_kin_contact : "",
      sia_required : "",
      sia_license: "",
      license_type : "",
      license_expiry : "",
      pmva_training : "",
      joining_date : "",
      comments : "",
      employee_profile : null,
      visa_to_work: "1",
      p45_p46 : "0",
      other_employment : "1",
      leisure : "0",
         criminal_record : "0",
      ccj : "1",
      any_days : "1",
      holiday_commit : "1",
      own_transport : "1",
      drive_license_type : "test license",
      employee_start_date : "2024-07-30",
       company_branch : 1
})

const [errors, setErrors] = useState({});
const [showModal, setShowModal] = useState(false);
const [apiResponse, setApiResponse] = useState(null);
const [showSiaModal, setShowSiaModal] = useState(false);
  const [apiError, setApiError] = useState(null);
const [isLoading, setIsLoading] = useState(false);

const [licenseType,setLicenseType] = useState([]);

useEffect(() => {
  fetchSiaTypes();
}, []);

const fetchSiaTypes = async () => {
  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/sia_types_show",
      {employee_id:"1"},
      {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
        },
      }
    );
    console.log("Basic Response:", response.data);
    if (response.data && response.data.success) {
      const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
      const options = successData.map(type => ({
        value: type.id.toString(),
        label: type.sia_license_name,
      }));
      setLicenseType(options);
      console.log("License Options:", options);    
    
    } else {
      console.error('Failed to fetch License types');
    }
  } catch (error) {
    console.error('Error fetching License types:', error);
  }
};
const handleSiaTypeChange = (selectedOption) => {
  setFormData({
    ...formData,
    license_type: selectedOption ? selectedOption.value : ''
  });
};

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};


const handleFileChange = (e) => {
  const file = e.target.files[0];
  setFormData((prevState) => ({
    ...prevState,
    employee_profile: file,
  }));
};

  const populateFormData = (data) => {
    setFormData(prevData => {
      const newData = { ...prevData };
  
      Object.entries(data).forEach(([key, index]) => {
        const formattedValue = Object.values(index)[0] || "";
        switch (key) {
          case 'row_1':
            newData.fore_name = formattedValue;
            break;
          case 'row_2':
            newData.middle_name = formattedValue;
            break;
          
            break;
          case 'row_5':
            newData.license_type = formattedValue;
            break;
          case 'row_6':
            newData.license_expiry = formatDate(formattedValue);
            break;
        }
      });
  
      return newData;
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

const verifySIA = async () => {
  setIsLoading(true);
  setApiError(null);

  const siaNumber = formData.sia_license;

  if (!/^\d{16}$/.test(siaNumber)) {
    setApiError("Invalid SIA number. Please enter a valid 16-digit number.");
    setIsLoading(false);
    setShowSiaModal(true);
    return;
  }

  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/check_sia_type",
      { LicenseNo: siaNumber },
      {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('SIA Verification Response:', response);
    if (!response.data || response.data.length === 0 || Object.keys(response.data[0]).length === 0) {
      setApiError("SIA Number Not Found");
    } else {
      setApiResponse(response.data);
      populateFormData(response.data[0]);
    }
  } catch (error) {
    console.error("Error verifying SIA:", error);
    setApiError(error.message || "An error occurred while verifying SIA");
  } finally {
    setIsLoading(false);
    setShowSiaModal(true);
  }
};


const handleSubmit = async (e) => {
  e.preventDefault();

  const formDataToSend = new FormData();

  Object.keys(formData).forEach(key => {
    if (key === 'employee_profile' && formData[key] instanceof File) {
      formDataToSend.append(key, formData[key], formData[key].name);
    } else {
      formDataToSend.append(key, formData[key]);
    }
  });

  try {
    const response = await axios.post(
      "https://srltd.megworld.in/api/add_basic_information",
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
           Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258", 
        },
      }
    );
    console.log('profile added successfully:', response);
        if(response.data && response.data.success){
      Swal.fire({
        icon: 'success',
        title: 'Basic Information  Updated',
        text: 'Basic Information  has been updated successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    }
  } catch (error) {
    console.error("Error:", error); 
  }
};
  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };
  return (
    <Container className="px-0">
      <Row className="mx-0">
        <Col md={10} className="main-content">
          <Row>
            <Col md={9} className="form-content">
              <p className="office-use" style={{ fontSize: "20px" }}>
                Office use only
              </p>
              <Form  onSubmit={handleSubmit}>
                <Row>
                  <Col md={4}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>STAFF ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="staff_id"
                        value={formData.staff_id}
                        onChange={handleInputChange}
                        placeholder="Staff ID"
                        style={inputStyle}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>PIN</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue="07"
                        style={inputStyle}
                        name="pin"
                        value={formData.pin}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>
                        Date of registration:
                      </Form.Label>
                      <Form.Control
                        type="date"
                        defaultValue="18-01-2024"
                        style={inputStyle}
                        name="date_of_registration"
                        value={formData.date_of_registration}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={{ marginTop: "20px", float: "left" }}>
                        Job Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Job Title"
                        style={inputStyle}
                        name="job_title"
                        value={formData.job_title}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={{ marginRight: "50px" }}>
                        Holidays Entitlement
                      </Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue="28"
                        style={inputStyle}
                        name="holiday_entitlement"
                        value={formData.holiday_entitlement}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label>Contracted Hours (Per Week)</Form.Label>
                      <Form.Control
                        type="number"
                        defaultValue="48"
                        style={inputStyle}
                        name="contract_hours"
                        value={formData.contract_hours}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={{ marginTop: "15px", float: "left" }}>
                        Reference
                      </Form.Label>
                      <Form.Control type="text" style={inputStyle}  name="reference"
                        value={formData.reference}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Forename</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue="Mark"
                        style={inputStyle}
                        name="fore_name"
                        value={formData.fore_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue="Denial"
                        style={inputStyle}
                        name="middle_name"
                        value={formData.middle_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Surname</Form.Label>
                      <Form.Control type="text" style={inputStyle}  name="sur_name"
                        value={formData.sur_name}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>N.I. Number</Form.Label>
                      <Form.Control type="text" style={inputStyle}  name="ni_number"
                        value={formData.ni_number}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Gender</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue="Male"
                        style={inputStyle}
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                      >
                        <option>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>D.O.B</Form.Label>
                      <Form.Control
                        type="date"
                        defaultValue="28-02-1994"
                        style={inputStyle}
                        name="d_o_b"
                        value={formData.d_o_b}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Nationality</Form.Label>
                      <Form.Control as="select" style={inputStyle}  name="nationality"
                        value={formData.nationality}
                        onChange={handleInputChange}>
                        <option>Please select</option>
                        <option>Indian</option>
                        {/* Add nationality options */}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={{ float: "left" }}>
                        EDBS Number
                      </Form.Label>
                      <Form.Control
                        style={inputStyle}
                        type="text"
                        placeholder="EDBS Number"
                        name="edbs_number"
                        value={formData.edbs_number}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ float: "left" }}>
                        Contact Number
                      </Form.Label>
                      <Form.Control type="text"  name="contact_number"
                        value={formData.contact_number}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ float: "left" }}>
                        Alternative Contact No
                      </Form.Label>
                      <Form.Control type="text"  name="alt_contact_no"
                        value={formData.alt_contact_no}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ marginRight: "200px" }}>
                        Email
                      </Form.Label>
                      <Form.Control type="text"  name="email"
                        value={formData.email}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ float: "left" }}>
                        Next of Kin
                      </Form.Label>
                      <Form.Control type="text"  name="next_kin"
                        value={formData.next_kin}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ float: "left" }}>
                        Relationship
                      </Form.Label>
                      <Form.Control type="text"  name="relationship"
                        value={formData.relationship}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ float: "left" }}>
                        Next of Kin Contact No.
                      </Form.Label>
                      <Form.Control type="text"  name="next_kin_contact"
                        value={formData.next_kin_contact}
                        onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        id="sia-required"
                        label={<span style={{ marginLeft: '8px' }}>SIA not required</span>}

                        className="text-danger"
                        name="sia_required"
                        checked={formData.sia_required}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-5">
                <Col md={4}>
 
<Form.Group>
  <Form.Label style={{ marginRight: "110px" }}>
    S.I.A License
  </Form.Label>
  <div className="d-flex align-items-center">
    <Form.Control
      type="text"
      name="sia_license"
      placeholder="SIA Number"
      value={formData.sia_license}
      onChange={handleInputChange}
      style={{ 
        marginRight: "10px", 
        flex: "1", 
        maxWidth: "400px"
      }}
    />
    <Form.Group as={Row} className="mb-3">
      <Col sm={{ span: 10, offset: 2 }}>
        <Form.Check
          type="checkbox"
          id="verifySIA"
          onChange={(e) => e.target.checked && verifySIA()}
          disabled={!formData.sia_license || isLoading}
        />
        {isLoading && ' Verifying...'}
      </Col>
    </Form.Group>
  </div>
</Form.Group>
</Col>

                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{marginRight:'110px'}}>
                        License Type
                      </Form.Label>
                      <Select
                      name="license_type"
                      value={licenseType.find(option => option.value === formData.license_type)}
                      onChange={handleSiaTypeChange}
                      options={licenseType}
                      isSearchable
                      placeholder="Select License Type..."
                                    >                        
                      </Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label style={{ float: "left" }}>
                        License Expiry
                      </Form.Label>
                      <Form.Control
                        style={{ marginTop: "20px" }}
                        type="date"
                        defaultValue="26-01-2024"
                        name="license_expiry"
                        value={formData.license_expiry}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  {/* <Col md={4}>
                    <Form.Check
                      type="checkbox"
                      id="additional-license"
                      label="Additional License"
                    />
                  </Col> */}

                  <Row className="mb-3 mt-10">
                    <Col md={12}>
                      <Form.Label
                        style={{ float: "left" }}
                        className="text-danger"
                      >
                        PMVA Trained officer?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                          label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="pmvaYes"
                          name="pmva_training"
                          value={formData.pmva_training}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                          label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="pmvaNo"
                          name="pmva_training"
                          value={formData.pmva_training}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={10} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Joining Date
                      </Form.Label>
                      <Form.Control type="date"  name="joining_date"
                        value={formData.joining_date}
                        onChange={handleInputChange} />
                    </Col>
                    <Col md={10} className="mt-10">
                      {/* <h3> Mark Denial Belongs to guard group</h3> */}
                      <Form.Label style={{ float: "left", marginTop: "20px" }}>
                        Comments
                      </Form.Label>
                      <Form.Control
                        style={{ marginTop: "30px" }}
                        as="textarea"
                        name="comments"
                        value={formData.comments}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-20">
                    <h3>Other Information</h3>
                    <Col md={12} className="mt-10">
                    <Form.Label htmlFor="employee_profile" style={{ float: "left" }}>
            Employee Picture
          </Form.Label>
          <Form.Control 
            type="file" 
            id="employee_profile"
            name="employee_profile"
            onChange={handleFileChange}
            accept="image/*"
          />
           {/* <div className="mt-2">
        <img
          src={previewImg || PreviewImg}
          alt="Preview"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        />
      </div> */}
      </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Do you need Visa to work or remain in the UK ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="visa_to_work"
                          value={formData.visa_to_work}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="visa_to_work"
                          value={formData.visa_to_work}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Provide Previous Employment details.Do you have p45 or
                        p46
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="p45_p46"
                          value={formData.p45_p46}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="p45_p46"
                          value={formData.p45_p46}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Do you have other Employment ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="other_employment"
                          value={formData.other_employment}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="other_employment"
                          value={formData.other_employment}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Leisure ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="leisure"
                          value={formData.leisure}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="leisure"
                          value={formData.leisure}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Any Criminal Record ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="criminal_record"
                          value={formData.criminal_record}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="criminal_record"
                          value={formData.criminal_record}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Any Bankruptcy(or)CCJ in last 6 years ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="ccj"
                          value={formData.ccj}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="ccj"
                          value={formData.ccj}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Any days/hours/shifts you can't work ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="any_days"
                          value={formData.any_days}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="any_days"
                          value={formData.any_days}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>{" "}
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Do you have any holidays commitment in the future ?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="holiday_commit"
                          value={formData.holiday_commit}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="holiday_commit"
                          value={formData.holiday_commit}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Do you have access to your own transport?
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                          id="visaCheck"
                          name="own_transport"
                          value={formData.own_transport}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                             label={<span style={{ marginLeft: '8px' }}>No</span>}
                          id="visaCheck"
                          name="own_transport"
                          value={formData.own_transport}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Driving License Type
                      </Form.Label>
                      <Form.Select  name="drive_license_type"
                        value={formData.drive_license_type}
                        onChange={handleInputChange}>
                        <option>Select Type...</option>
                        <option>Test Type</option>
                      </Form.Select>
                    </Col>
                    <Col md={12} className="mt-10">
                      <Form.Label style={{ float: "left" }}>
                        Employee Start Date (When will the employee start work
                        ?)
                      </Form.Label>
                      <Form.Control type="date"  name="employee_start_date"
                        value={formData.employee_start_date}
                        onChange={handleInputChange}/>
                    </Col>
                  </Row>
                </Row>
                <button
                onClick={handleSubmit}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "dodgerblue",
                    color: "white",
                    border: "0",
                    borderRadius: "5px",
                  }}
                >
                  SAVE
                </button>
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "Green",
                    color: "white",
                    border: "0",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  NEXT
                </button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={showSiaModal} onHide={() => setShowSiaModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>SIA Verification Result</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {apiError ? (
      <Alert variant="danger">
        {apiError}
      </Alert>
    ) : apiResponse && apiResponse.length > 0 ? (
      <table className="table table-bordered">
      <tbody>
        {apiResponse[0] && Object.entries(apiResponse[0]).map(([key, value], index) => {
          const label = {
            row_1: 'First name',
            row_2: 'Surname',
            row_3: 'Licence number',
            row_4: 'Role',
            row_5: 'Licence sector',
            row_6: 'Expiry date',
            row_7: 'Status',
          }[`row_${index + 1}`];

          const formattedValue = Object.values(value)[0] || "-";

          return (
            <tr key={key} style={{fontSize:'15px'}}>
              <td style={{width: '40%',fontWeight:'bold'}}>{label}</td>
              <td >{formattedValue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
      <p>No data available</p>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowSiaModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
    </Container>


    
  );
};

export default BasicInfo;
