import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table } from "react-bootstrap";


const ShowPreviousEducation = () => {

  const [employmentData, setEmploymentData] = useState([]);

  useEffect(() => {
    fetchEmploymentData();
  }, []);

  const fetchEmploymentData = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/show_pervious_education',
         {employee_id:"1"}, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log('API response data:', response.data); // Add this line
      setEmploymentData(response.data.data);
    } catch (error) {
      console.error('Error fetching employment data:', error);
    }
  };
  
  return (
    <Container>
    <h2>Previous Education</h2>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Education Name</th>
          <th>Education Description</th>
          <th>Institution Name</th>
          <th>Address</th>
          <th>Contact Name</th>
          <th>Contact Number</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(employmentData) && employmentData.length > 0 ? (
          employmentData.map((job, index) => (
            <tr key={index}>
              <td>{job.education_name}</td>
              <td>{job.education_description}</td>
              <td>{job.institution_name}</td>
              <td>{job.address}</td>
              <td>{job.contact_name}</td>
              <td>{job.contact_number}</td>
              <td>{job.start_date}</td>
              <td>{job.end_date}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">No employment data available</td>
          </tr>
        )}
      </tbody>
    </Table>
  </Container>
  )
}

export default ShowPreviousEducation
