import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "../../Dashboard/Dashboard";
import { useLocation } from "react-router-dom";
import { Button, Form, Table } from 'react-bootstrap';

const MonthlyCalendar = () => {
  const location = useLocation();
  const [currentDate, setCurrentDate] = useState(new Date());

  const getNavigationPath = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    return [
      { name: "Employee", bold: false },
      { name: "Payroll", bold: false },
      { name: "Holiday", bold: false },
      { name: "Holidays Monthly Calendar", bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const firstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const renderCalendar = () => {
    const days = daysInMonth(currentDate);
    const firstDay = firstDayOfMonth(currentDate);
    const weeks = Math.ceil((days + firstDay) / 7);

    let calendarDays = [];
    let day = 1;

    for (let i = 0; i < weeks; i++) {
      let week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDay) || day > days) {
          week.push(<td key={`empty-${i}-${j}`}></td>);
        } else {
          week.push(
            <td key={`day-${day}`}>
              <div className="day-number">{day}</div>
            </td>
          );
          day++;
        }
      }
      calendarDays.push(<tr key={`week-${i}`}>{week}</tr>);
    }

    return calendarDays;
  };

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}
      >
        <h3 className="text-white" style={{ fontSize: "15px", color: "white" }}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span
                style={{
                  fontWeight: part.bold ? "bold" : "normal",
                  color: "white",
                  fontSize: part.bold ? "18px" : "15px",
                }}
              >
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      <div className="calendar-container m-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <Button style={{padding:'8px'}} variant="info" className="me-2"><i className="fas fa-clock"></i></Button>
            <Button style={{padding:'8px'}} variant="success" className="me-2"><i className="fas fa-calendar"></i></Button>
            <Button style={{padding:'8px'}} variant="info"><i className="fas fa-redo"></i></Button>
          </div>
          <h2>{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</h2>
          <div>
            <Form.Control type="text" placeholder="Select Date" className="d-inline-block me-2" style={{width: 'auto'}} />
            <Button style={{padding:'8px'}} variant="primary"><i className="fas fa-search"></i></Button>
          </div>
        </div>
        <Table bordered>
          <thead style={{textAlign:'center'}}>
            <tr>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
            </tr>
          </thead>
          <tbody  style={{textAlign:'center'}}>
            {renderCalendar()}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MonthlyCalendar;