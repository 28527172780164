import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import "./SiaType.css";

const SiaType = () => {
  const [siaType, setSiaType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://srltd.megworld.in/api/sia_types_show",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        throw new Error(response.data.message || "Failed to fetch SIA types");
      }
    } catch (error) {
      console.error("Error fetching SIA types:", error);
      setError("Error fetching SIA types: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSiaType = async () => {
    if (siaType.trim() !== "") {
      try {
        setIsLoading(true);
        const payload = {
          sia_license_name: siaType,
          company_branch: "1",
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          "https://srltd.megworld.in/api/sia_types_store",
          payload,
          config
        );

        if (response.data.success) {
          setSiaType("");
          Swal.fire({
            icon: 'success',
            title: 'SIA Type Added',
            text: 'New SIA type has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchSiaTypes();
        } else {
          throw new Error(response.data.message || "Failed to add SIA type");
        }
      } catch (error) {
        console.error("Add SIA Type Error:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An error occurred while adding SIA type.'
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a SIA type.'
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/sia_types_store",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The SIA type has been deleted.',
            'success'
          );
          fetchSiaTypes();
        } else {
          throw new Error(response.data.message || "Failed to delete SIA type");
        }
      }
    } catch (error) {
      console.error("Delete SIA Type Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting SIA type.'
      });
    }
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setShowEditModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: editingItem.id,
        sia_license_name: editingItem.sia_license_name,
        company_branch: editingItem.company_branch,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        "https://srltd.megworld.in/api/sia_types_store",
        payload,
        config
      );

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'SIA Type Updated',
          text: 'SIA type has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        setShowEditModal(false);
        fetchSiaTypes();
      } else {
        throw new Error(response.data.message || "Failed to update SIA type");
      }
    } catch (error) {
      console.error("Update SIA Type Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while updating SIA type.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <Container fluid className="sia-type-container">
      <Row>
        <Col md={4} className="sia-types-section">
          <h3 className="mb-4">SIA License Type</h3>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Enter SIA License Type"
              value={siaType}
              onChange={(e) => setSiaType(e.target.value)}
            />
            <Button variant="primary" onClick={handleAddSiaType} disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Add'}
            </Button>
          </InputGroup>

          {error && <p className="text-danger">{error}</p>}
        </Col>
        <Col md={8} className="table-section">
          <h3 className="mb-4">SIA License Types</h3>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>License Name</th>
                  <th>Branch</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((data, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.sia_license_name}</td>
                    <td>{data.company_branch}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary me-1"
                        onClick={() => handleEdit(data)}
                      >
                        <i className="fas fa-edit" style={{ color: "white" }}></i>
                      </button>
                      <button 
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDelete(data.id)}
                      >
                        <i className="fas fa-trash" style={{ color: "white" }}></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit SIA License Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>License Name</Form.Label>
              <Form.Control
                type="text"
                value={editingItem?.sia_license_name || ''}
                onChange={(e) => setEditingItem({...editingItem, sia_license_name: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Branch</Form.Label>
              <Form.Control
                type="text"
                value={editingItem?.company_branch || ''}
                onChange={(e) => setEditingItem({...editingItem, company_branch: e.target.value})}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SiaType;