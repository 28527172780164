import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Table,
  Modal,
  Spinner,
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import "./SiaType.css";

const EventTypes = () => {
  const [eventType, setEventType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [newEventType, setNewEventType] = useState("");

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchEventTypes();
  }, []);

  const fetchEventTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.post("https://srltd.megworld.in/api/event_type_show", {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        setError("Failed to fetch event types");
      }
    } catch (err) {
      setError("Error fetching event types: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEventType = async () => {
    if (eventType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/event_type_store",
          {
            event_types: eventType,
            company_branch: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.success) {
          fetchEventTypes();
          setEventType("");
          Swal.fire({
            icon: 'success',
            title: 'Event Type Added',
            text: 'New Event type has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        } else {
          setError("Failed to add Event type");
        }
      } catch (err) {
        setError("Error adding Event type: " + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const response = await axios.post("https://srltd.megworld.in/api/event_type_store", {
            delete_id: id,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          if (response.data && response.data.success) {
            Swal.fire(
              'Deleted!',
              'The event type has been deleted.',
              'success'
            );
            fetchEventTypes();
          } else {
            setError("Failed to delete event type");
          }
        } catch (err) {
          setError("Error deleting event type: " + err.message);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setNewEventType(data.event_types);
    setEditModalShow(true);
  };

  const handleSaveEdit = async () => {
    if (newEventType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post("https://srltd.megworld.in/api/event_type_store", {
          id: editData.id,
          event_types: newEventType,
          company_branch: editData.company_branch,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.success) {
          setEditModalShow(false);
          Swal.fire({
            icon: 'success',
            title: 'Event Type Updated',
            text: 'The event type has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchEventTypes();
        } else {
          setError("Failed to update event type");
        }
      } catch (err) {
        setError("Error updating event type: " + err.message);
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a new event type name.'
      });
    }
  };

  return (
    <div className="page-container">
      <div className="common-container">
        <h3>Event Types</h3>
        <InputGroup className="Group mb-3">
          <FormControl
            placeholder="Event Type"
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddEventType} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "ADD"}
          </Button>
        </InputGroup>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Event Type</th>
              <th>Branch</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{data.event_types}</td>
                <td>{data.company_branch}</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    className="me-1"
                    onClick={() => handleEdit(data)}
                  >
                    <i className="fas fa-edit" style={{ color: "white" }}></i>
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(data.id)}
                  >
                    <i className="fas fa-trash" style={{ color: "white" }}></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Event Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formEventType">
            <Form.Label>Event Type Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new event type name"
              value={newEventType}
              onChange={(e) => setNewEventType(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EventTypes;