import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  FormControl,
  InputGroup,
  Table,
  Alert,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import "./SiaType.css";

const TrainingTypes = () => {
  const [trainingType, setTrainingType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [newTrainingType, setNewTrainingType] = useState("");

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
  // const apiUrl = "https://srltd.megworld.in/api";

  useEffect(() => {
    fetchTrainingTypes();
  }, []);

  const fetchTrainingTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.post("https://srltd.megworld.in/api/training_type_show", {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && Array.isArray(response.data.data)) {
        setTableData(response.data.data);
      } else {
        setTableData([]);
        setError("Failed to fetch training types or received invalid data");
      }
    } catch (err) {
      setTableData([]);
      setError("Error fetching training types: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTrainingType = async () => {
    if (trainingType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(
         "https://srltd.megworld.in/api/training_type_store",
          {
            training_type: trainingType,
            company_branch: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data && response.data.success) {
          fetchTrainingTypes();
          setTrainingType("");
          Swal.fire({
            icon: 'success',
            title: 'Training Type Added',
            text: 'New Training type has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        } else {
          setError("Failed to add training type or received invalid data");
        }
      } catch (err) {
        setError("Error adding training type: " + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const response = await axios.post("https://srltd.megworld.in/api/training_type_store", {
            delete_id: id,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data && response.data.success) {
            Swal.fire(
              'Deleted!',
              'The training type has been deleted.',
              'success'
            );
            fetchTrainingTypes();
          } else {
            setError("Failed to delete training type");
          }
        } catch (err) {
          setError("Error deleting training type: " + err.message);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setNewTrainingType(data.training_type || '');
    setEditModalShow(true);
  };
  
  const handleSaveEdit = async () => {
    if (newTrainingType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post("https://srltd.megworld.in/api/training_type_store", {
          id: editData.id,
          training_type: newTrainingType,
          company_branch: editData.company_branch,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data && response.data.success) {
          setEditModalShow(false);
          Swal.fire({
            icon: 'success',
            title: 'Training Type Updated',
            text: 'The training type has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchTrainingTypes();
        } else {
          setError("Failed to update training type");
        }
      } catch (err) {
        setError("Error updating training type: " + err.message);
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a new training type name.'
      });
    }
  };

  return (
    <div className="page-container">
      <div className="common-container">
        <h3>Training Type</h3>
        <InputGroup className="Group mb-3">
          <FormControl
            placeholder="Training Type"
            value={trainingType}
            onChange={(e) => setTrainingType(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddTrainingType} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "ADD"}
          </Button>
        </InputGroup>
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Training Type</th>
              <th>Branch</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData && tableData.length > 0 ? (
              tableData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data?.training_type || 'N/A'}</td>
                  <td>{data?.company_branch || 'N/A'}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      className="me-1"
                      onClick={() => handleEdit(data)}
                    >
                      <i className="fas fa-edit" style={{ color: "white" }}></i>
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(data.id)}
                    >
                      <i className="fas fa-trash" style={{ color: "white" }}></i>
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Training Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formTrainingType">
            <Form.Label>Training Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new training type"
              value={newTrainingType}
              onChange={(e) => setNewTrainingType(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrainingTypes;