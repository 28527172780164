import React, { useState } from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';

const PersionalReference = () => {
  const [formData, setFormData] = useState({
    employee_id: "1",
    reference_name: "",
    reference_name_1:"",
    contact_number: "",
    contact_email: "",
    profession: "",
    relationship: "",
    referee_address: "",
    last_2_years: ""
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'radio') {
      setFormData({ ...formData, [name]: value });
    } else if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked ? value : '' });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['reference_name', 'contact_number', 'contact_email', 'profession', 'relationship', 'referee_address', 'last_2_years'];
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0) {
      alert(`Please fill in all required fields`);
      return;
    }
    try {
      const response = await axios.post('https://srltd.megworld.in/api/add_personal_reference', formData, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258',
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response);
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Personal Reference Updated',
          text: 'Personal Reference has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      } else {
        alert("Failed to add Personal Reference: " + (response.data.message || "Unknown error"));
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        alert(`Error: ${error.response.status} - ${error.response.data.message || "Unknown error"}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert("No response received from the server. Please try again.");
      } else {
        console.error('Error message:', error.message);
        alert("An error occurred: " + error.message);
      }
    }
  };

  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  return (
    <Container className="px-0">
      <Row className="mx-0">
        <Col md={10} className="main-content">
          <Row>
            <Col md={9} className="form-content">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={10} className="mt-10">
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Reference Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Reference Name"
                        style={inputStyle}
                        name="reference_name"
                        value={formData.reference_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10} className="">
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Reference Name 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Reference Name 2"
                        style={inputStyle}
                        name="reference_name_1"
                        value={formData.reference_name_1 }
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Contact Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Contact Number"
                        style={inputStyle}
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Contact Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Contact Email"
                        style={inputStyle}
                        name="contact_email"
                        value={formData.contact_email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Profession</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Profession"
                        style={inputStyle}
                        name="profession"
                        value={formData.profession}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Relationship</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Relationship"
                        style={inputStyle}
                        name="relationship"
                        value={formData.relationship}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Referee Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Referee Address"
                        style={inputStyle}
                        name="referee_address"
                        value={formData.referee_address}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mt-10">
  <Form.Label style={{ float: "left" }}>
    Do above person know you for last 2 years?
  </Form.Label>
  <div>
    <Form.Check
      inline
      type="radio"
      label="Yes"
      id="last_2_years_yes"
      name="last_2_years"
      value="1"
      checked={formData.last_2_years === '1'}    
        onChange={handleChange}
    />
    <Form.Check
      inline
      type="radio"
      label="No"
      id="last_2_years_no"
      name="last_2_years"
      value="0"
      checked={formData.last_2_years === '0'}    
      onChange={handleChange}
    />
  </div>
</Col>
                </Row>

                <Button
                  type="submit"
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "dodgerblue",
                    color: "white",
                    border: "0",
                    borderRadius: "5px",
                  }}
                >
                  SAVE
                </Button>
                <Button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "Green",
                    color: "white",
                    border: "0",
                    borderRadius: "5px",
                    marginLeft: "5px",
                  }}
                >
                  NEXT
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PersionalReference;
