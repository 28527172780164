import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Table,
  Alert,
  Spinner,
  Modal,
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from "axios";
import "./SiaType.css";

const VisaType = () => {
  const [visaType, setVisaType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [newVisaType, setNewVisaType] = useState("");

  const apiUrlShow = "https://srltd.megworld.in/api/visa_type_show";
  const apiUrlStore = "https://srltd.megworld.in/api/visa_type_store";
  const apiUrlUpdate = "https://srltd.megworld.in/api/visa_type_store";
  const apiUrlDelete = "https://srltd.megworld.in/api/visa_type_store";
  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchVisaTypes();
  }, []);

  const fetchVisaTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.post(apiUrlShow, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        setError("Failed to fetch visa types");
      }
    } catch (err) {
      setError("Error fetching visa types");
    } finally {
      setLoading(false);
    }
  };

  const handleAddVisaType = async () => {
    if (visaType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(apiUrlStore, {
          visa_type: visaType,
          company_branch: 1, // Assuming a static value for now
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.data && response.data.success) {
          setVisaType("");
          fetchVisaTypes();
          Swal.fire({
            icon: 'success',
            title: 'Visa Type Added',
            text: 'New Visa type has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        } else {
          setError("Failed to add visa type");
        }
      } catch (err) {
        setError("Error adding visa type");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const response = await axios.post(apiUrlDelete, {
            delete_id: id,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data && response.data.success) {
            Swal.fire(
              'Deleted!',
              'The visa type has been deleted.',
              'success'
            );
            setTableData(prevData => prevData.filter(item => item.id !== id));
          } else {
            setError("Failed to delete visa type");
          }
        } catch (err) {
          setError("Error deleting visa type");
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setNewVisaType(data.visa_type);
    setEditModalShow(true);
  };

  const handleSaveEdit = async () => {
    if (newVisaType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(apiUrlUpdate, {
          id: editData.id,
          visa_type: newVisaType,
          company_branch: editData.company_branch,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.success) {
          setEditModalShow(false);
          Swal.fire({
            icon: 'success',
            title: 'Visa Type Updated',
            text: 'The visa type has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          setTableData(prevData => 
            prevData.map(item => 
              item.id === editData.id ? {...item, visa_type: newVisaType} : item
            )
          );
        } else {
          setError("Failed to update visa type");
        }
      } catch (err) {
        setError("Error updating visa type");
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a new visa type name.'
      });
    }
  };

  return (
    <div className="page-container">
      <div className="common-container">
        <h3>Visa Type</h3>
        <InputGroup className="Group">
          <FormControl
            placeholder="Visa Type"
            value={visaType}
            onChange={(e) => setVisaType(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddVisaType} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "ADD"}
          </Button>
        </InputGroup>
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      </div>
      <div className="table-container mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Visa Type</th>
              <th>Branch</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={data.id}>
                <td>{index + 1}</td>
                <td>{data.visa_type}</td>
                <td>{data.company_branch}</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleEdit(data)}
                  >
                    <i className="fas fa-edit" style={{ color: "white" }}></i>
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => handleDelete(data.id)}
                  >
                    <i className="fas fa-trash" style={{ color: "white" }}></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Visa Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formVisaType">
            <Form.Label>Visa Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new visa type"
              value={newVisaType}
              onChange={(e) => setNewVisaType(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VisaType;