import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import Dashboard from "../../Dashboard/Dashboard";
import { Link ,useLocation} from "react-router-dom";
import Swal from 'sweetalert2';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import NewSGSLogo from "../Payroll/patterns/logos/NewSGSLogo.jpeg"
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Visibility, Edit, Delete, Email, PictureAsPdf } from '@mui/icons-material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const AdvancePayment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filteredAdvance,setFilteredAdvance] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [formData, setFormData] = useState({
    adv_emp_name: "",
    adv_amount: "",
    adv_date: "",
    adv_comments: "",
    adv_return_amount: "",
    adv_due_date: "",
    adv_rerturn_comments: "",
    company_branch: 1
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchAdvanceList();
  }, []);

  const fetchAdvanceList = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/show_advance_list',
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setTableData(response.data.data || []);
      setFilteredAdvance(response.data.data || []);
    } catch (error) {
      console.error("Error fetching advance list:", error);
    }
  };

  useEffect(() => {
    const filtered = tableData.filter((data) =>
      Object.values(data).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredAdvance(filtered);
    setCurrentPage(0);
  }, [searchTerm, tableData]);

  const handleShowFilter = () => setShowFilter(true);
  const handleCloseFilter = () => setShowFilter(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      fetchAdvanceList(); // Reset to original list if search term is empty
    } else {
      const filtered = tableData.filter((data) =>
        Object.values(data).some((value) =>
          value
            ? value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            : false
        )
      );
      setTableData(filtered);
    }
    setCurrentPage(1); // Reset to first page after search
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.adv_emp_name === "" ||
      formData.adv_amount === "" ||
      formData.adv_date === ""
    ) {
      alert("Please fill out all required fields.");
      return;
    }
    try {
      await axios.post(
        'https://srltd.megworld.in/api/add_advance_list',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Advance payment  Updated',
        text: 'Advance payment  has been updated successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      fetchAdvanceList(); // Refresh the list
      setFormData({ // Reset form
        adv_emp_name: "",
        adv_amount: "",
        adv_date: "",
        adv_comments: "",
        adv_return_amount: "",
        adv_due_date: "",
        adv_rerturn_comments: "",
        company_branch: 1
      });
    } catch (error) {
      console.error("Error adding advance payment:", error);
      alert("Failed to add advance payment");
    }
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setFormData({
      id: item.id,
      adv_emp_name: item.adv_emp_name,
      adv_amount: item.adv_amount,
      adv_date: item.adv_date,
      adv_comments: item.adv_comments,
      adv_return_amount: item.adv_return_amount,
      adv_due_date: item.adv_due_date,
      adv_rerturn_comments: item.adv_rerturn_comments,
      company_branch: item.company_branch
    });
    setShowEditModal(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.post(
        "https://srltd.megworld.in/api/add_advance_list",
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Advance Payments Updated',
        text: 'Advance Payments has been updated successfully.',
      });      fetchAdvanceList();
      resetForm();
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating advance payment:", error);
      alert("Failed to update advance payment");
    }
  };

  const resetForm = () => {
    setFormData({
      adv_emp_name: "",
      adv_amount: "",
      adv_date: "",
      adv_comments: "",
      adv_return_amount: "",
      adv_due_date: "",
      adv_rerturn_comments: "",
      company_branch: 1
    });
    setEditingItem(null);
  };

  

  const generatePDF = (data) => {
    if (!data) {
      console.error('Invalid Advance Payment data');
      return;
    }
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
    const logoWidth = 15;
    const logoHeight = 15;
    doc.addImage(NewSGSLogo, 'PNG', margin, 15, logoWidth, logoHeight);
  
    // Add company name and address
    doc.setFontSize(12);
    doc.text('UK ARCHERS LTD', margin, 40);
    doc.text('Company number 12833471',margin,45)

    doc.setFontSize(10);
    doc.text('149 Victoria Road', margin, 55);
    doc.text('Barking', margin, 60);
    doc.text('IG11 8PZ', margin, 65);
    doc.text('England', margin, 70);
  
    // Add INVOICE text
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text('PAYROLL', pageWidth - margin, 25, { align: 'right' });
  
  
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Employee Name: ${data.adv_emp_name || 'N/A'}`, pageWidth - margin, 40, { align: 'right' });
    doc.text(`Date: ${data.adv_date || 'N/A'}`, pageWidth - margin, 45, { align: 'right' });
    doc.text(`Due Date: ${data.adv_due_date || 'N/A'}`, pageWidth - margin, 50, { align: 'right' });
  
    doc.autoTable({
      startY: 90,
      head: [['Item','Description']],
      body: [
        ['Employee Name', `${data.adv_emp_name || 'N/A'}`],
        ['Comments', `${data.adv_comments || 'N/A'}`],
        ['Return Comments', `${data.adv_rerturn_comments || 'N/A'}`],
      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 50 },
      },
    });
  

    doc.autoTable({
      startY: 140,
      head: [['Description', 'Amount']],
    body: [
      ['Due Amount', `${data.adv_amount || '0.00'}`],
      ['Return Amount',`${data.adv_return_amount || '0.00'}`],
    ],
      theme: 'grid',
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: 255,
        fontStyle: 'bold'
      },
      bodyStyles: {
        textColor: 0
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      margin: { top: 100 }
    });
  
    return doc.output('blob');};


    const previewPDF = (data) => {
      const pdfBlob = generatePDF(data);
      if (pdfBlob) {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      }
    };
    const location = useLocation();

    const getNavigationPath = () => {
      const pathParts = location.pathname.split('/').filter(Boolean);
      return [
        { name: 'Accounts', bold: false },
        { name: 'Payroll', bold: false },
        { name: 'Advance Payments', bold: true },
      ];
    };
    
    const navigationPath = getNavigationPath();
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll");
      XLSX.writeFile(workbook, "AdvancePayments_data.xlsx");
    }


    const tooltipStyles = {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        color: '#fff',
        fontSize: '14px',
        padding: '8px 12px',
        borderRadius: '4px',
      },
      arrow: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    };
    return (
      <>
        <Dashboard />
        <div
          className="toolbar py-2"
          id="kt_toolbar"
          style={{backgroundColor: '#BF0404' }}>
          <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
              <React.Fragment key={index}>
                <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                  {part.name}
                </span>
                {index < navigationPath.length - 1 && " / "}  
              </React.Fragment>
            ))}</h3>           <div className="col-auto d-flex flex-wrap gap-2">
           
            <Button
  variant="contained"
  color="primary"
  onClick={handleShowFilter}
  sx={{ padding: '8px', marginLeft: '130px' }}
>
Filter Advance Payment
</Button>
          </div>
      </div>
    
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Advance Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4 mt-5">
                <Form.Label htmlFor="employee" style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                  Employee
                </Form.Label>
                <Form.Control
                  as="select"
                  style={{ width: "100%" }}
                  id="employee"
                  name="adv_emp_name"
                  value={formData.adv_emp_name}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                >
                  <option>Select Employee...</option>
                  <option>Lione</option>
                  <option>Mark H</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="amount" style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                  Amount
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="text"
                  id="amount"
                  name="adv_amount"
                  value={formData.adv_amount}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="date" style={{ float: "left" }}>
                  Date
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="date"
                  id="date"
                  name="adv_date"
                  value={formData.adv_date}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="comments" style={{ float: "left" }}>
                  Comments
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  as="textarea"
                  id="comments"
                  name="adv_comments"
                  value={formData.adv_comments}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label  style={{ float: "left" }} htmlFor="adv_return_amount">
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Return Amount</Form.Label>
                <Form.Control
                  type="number"
                  id="adv_return_amount"
                  name="adv_return_amount"
                  value={formData.adv_return_amount}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="adv_due_date"  style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Due Date</Form.Label>
                <Form.Control
                  type="date"
                  id="adv_due_date"
                  name="adv_due_date"
                  value={formData.adv_due_date}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="adv_rerturn_comments"  style={{ float: "left" }}>Return Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  id="adv_rerturn_comments"
                  name="adv_rerturn_comments"
                  value={formData.adv_rerturn_comments}
                  onChange={handleInputChange}
                />
              </Form.Group>          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showFilter} onHide={handleCloseFilter} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Filter Advance Payment System</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Filter By</Form.Label>
                  <Form.Select>
                    <option>All staff</option>
                    <option>Staff with zero amount</option>
                    <option>Staff with amount greater than zero</option>
                    <option>Specific staff</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary">FILTER</Button>
              <Button variant="secondary" onClick={handleCloseFilter}>
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 mt-5">
                <Form.Label htmlFor="employee" style={{ float: "left" }}>
                  
                  Employee
                </Form.Label>
                <Form.Control
                  as="select"
                  style={{ width: "100%" }}
                  id="employee"
                  name="adv_emp_name"
                  value={formData.adv_emp_name}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                >
                  <option>Select Employee...</option>
                  <option>Lione</option>
                  <option>Mark H</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="amount" style={{ float: "left" }}>
                  Amount
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="text"
                  id="amount"
                  name="adv_amount"
                  value={formData.adv_amount}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="date" style={{ float: "left" }}>
                  Date
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  type="date"
                  id="date"
                  name="adv_date"
                  value={formData.adv_date}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="comments" style={{ float: "left" }}>
                  Comments
                </Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  as="textarea"
                  id="comments"
                  name="adv_comments"
                  value={formData.adv_comments}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label  style={{ float: "left" }} htmlFor="adv_return_amount">Return Amount</Form.Label>
                <Form.Control
                  type="number"
                  id="adv_return_amount"
                  name="adv_return_amount"
                  value={formData.adv_return_amount}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="adv_due_date"  style={{ float: "left" }}>Due Date</Form.Label>
                <Form.Control
                  type="date"
                  id="adv_due_date"
                  name="adv_due_date"
                  value={formData.adv_due_date}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="adv_rerturn_comments"  style={{ float: "left" }}>Return Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  id="adv_rerturn_comments"
                  name="adv_rerturn_comments"
                  value={formData.adv_rerturn_comments}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <div className="text-center">
                {/* <Button variant="primary" type="submit">
                  Add Advance Payment
                </Button> */}

                <Button  variant="contained" color="primary"
 >
         Add Advance Payment
        </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select 
                  className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={(e) => setEntriesPerPage(Number(e.target.value))}
                >
                  {[10,50,100,500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
              <Button
                  variant="primary"
                  className="m-0"
                  onClick={handleSearch}>
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  // style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Employee Name</th>
                    <th>Due Amount</th>
                    <th>Date</th>
                    <th>Comments</th>
                    <th>Return Amount</th>
                    <th>Due Date</th>
                    <th>Return Comments</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {tableData
                    .filter(data => {
                      const searchLower = searchTerm.toLowerCase();
                      return (
                        (data.adv_emp_name?.toLowerCase() || '').includes(searchLower) ||
                        (data.adv_amount?.toString() || '').includes(searchTerm) ||
                        (data.adv_date || '').includes(searchTerm)
                      );
                    })
                    .slice(0, entriesPerPage)
                    .map((data, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{data.adv_emp_name}</td>
                        <td>{data.adv_amount}</td>
                        <td>{data.adv_date}</td>
                        <td>{data. adv_comments}</td>
                        <td>{data.adv_return_amount}</td>
                        <td>{data.adv_due_date}</td>
                        <td>{data.adv_rerturn_comments}</td>
                        <td>
                          <Link to="/viewpayment">
                          <button className="btn-danger me-2">
                              <i
                                className="fas fa-file"
                                style={{
                                  color: "white",
                                  padding: "5px",
                                  fontSize: "16px",
                                }}></i>
                            </button>
                          </Link>
                          <button className="btn-primary me-2"
                          onClick={()=>handleEdit(data)}
                          >
                              <i
                                className="fas fa-pen"
                                style={{
                                  color: "white",
                                  padding: "5px",
                                  fontSize: "16px",
                                }}></i>
                            </button>
                            <button className="btn-primary me-2" onClick={() => previewPDF(data)}>
    <i
      className="fas fa-file-pdf"
      style={{
        color: "white",
        padding:'7px'
      }}></i>
  </button> 
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>
                Showing 1 to {tableData.length} of {tableData.length} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}


      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Employee Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Due Amount </TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Comments</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Return Amount</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Due Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Return Comments</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredAdvance
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((data, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell >{data.adv_emp_name}</TableCell>
                    <TableCell >{data.adv_amount}</TableCell>
                    <TableCell >{data.adv_date}</TableCell>
                    <TableCell >{data.adv_comments}</TableCell>
                    <TableCell >{data.adv_return_amount}</TableCell>
                    <TableCell >{data.adv_due_date}</TableCell>
                    <TableCell >{data.adv_rerturn_comments}</TableCell>

                    <TableCell >
  <Link to="/viewpayment">
  <Tooltip 
    title="View Advance Payment" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
    <IconButton 
      color="success"
      size="small"
      sx={{ marginRight: 2,  color: 'white', 
        backgroundColor: 'green',  '&:hover': { backgroundColor: 'darkgreen' }  }}

    >
      <Visibility sx={{ fontSize: 16 }} />
    </IconButton>
    </Tooltip>
  </Link>
  <Tooltip 
    title="Edit Advance Payment" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{ marginRight: 2,  color: 'white', 
      backgroundColor: 'blue',  '&:hover': { backgroundColor: 'darkblue' }  }}
      onClick={()=>handleEdit(data)}  >
    <Edit sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>
  <Tooltip 
    title="View PDF" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{color: 'white', 
      backgroundColor: 'darkviolet',  '&:hover': { backgroundColor: 'darkviolet' }  }}

      onClick={() => previewPDF(data)}  >
    <PictureAsPdf sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredAdvance.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default AdvancePayment;
