import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Form } from "react-bootstrap";
import "./Invoice.css";
import axios from "axios";
import Dashboard from "../../Dashboard/Dashboard";
import Swal from 'sweetalert2';
import NewSGSLogo from "../Invoice/patterns/logos/NewSGSLogo.jpeg"
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { Link,useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Visibility, Edit, Delete, Email, PictureAsPdf } from '@mui/icons-material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';


const CreditNotes = () => {
  const [credit, setCredit] = useState([]);
  const [filteredCredit,setFilteredCredit] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchInvoice, setShowSearchInvoice] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showAddEventDetail, setShowAddEventDetail] = useState(false);
  const [newCredit, setNewCredit] = useState({
    credit_inv_name: "",
    credit_inv_site_group: "",
    credit_invoice_no: "",
    credit_date: "",
    credit_number: "",
    credit_amount: "",
    credit_description: "",
    company_branch: "1"
  });
  const [editingCredit, setEditingCredit] = useState(null);
 const [showCreditEdit,setShowCreditEdit] = useState(false)

 const handleEditCredit = (credit) => {
  setEditingCredit({...credit});
  setShowCreditEdit(true);
};

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525";

  useEffect(() => {
    fetchCredit();
  }, []);

  const fetchCredit = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_credit_note",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data && response.data.success) {
        setCredit(response.data.data);
        setFilteredCredit(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching credit:", error);
      setError("Error fetching credit: " + error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filtered = credit.filter((credits) =>
      Object.values(credits).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredCredit(filtered);
    setCurrentPage(0);
  }, [searchTerm, credit]);

  const handleAddCredit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_credit_note",
        newCredit,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Credit Note Updated',
          text: 'Credit Note has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
        setNewCredit(response.data.data);
        // setCredit([...credit, response.data.data]); 
        fetchCredit();
        handleCloseAddEventDetail();
      }
    } catch (error) {
      console.error("Error adding Credit:", error);
      alert("An error occurred while adding Credit. Please try again.");
    }
  };


  const handleShowEmail = () => setShowEmail(true);
  const handleCloseEmail = () => setShowEmail(false);

  const handleShowSearchInvoice = () => setShowSearchInvoice(true);
  const handleCloseSearchInvoice = () => setShowSearchInvoice(false);

  const handleShowAddEventDetail = () => setShowAddEventDetail(true);
  const handleCloseAddEventDetail = () => setShowAddEventDetail(false);


  const [inputs, setInputs] = useState([{ id: Date.now() }]);

  const handleAddInput = () => {
    setInputs([...inputs, { id: Date.now() }]);
  };


  // const handleSearch = () => {
  //   if (searchTerm.trim() === "") {
  //     fetchCredit(); // Reset to original list if search term is empty
  //   } else {
  //     const filtered =credit.filter((credits) =>
  //       Object.values(credits).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //     );
  //     setCredit(filtered);
  //   }
  //   setCurrentPage(1); // Reset to first page after search
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCredit({ ...newCredit, [name]: value });
    setEditingCredit(prev => ({ ...prev, [name]: value }));
  };


  const handleRemoveInput = (id) => {
    setInputs(inputs.filter((input) => input.id !== id));
  };




  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentCredit = credit.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleUpdateCredit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_credit_note",
        editingCredit,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Credit Notes Updated',
          text: 'Credit Notes has been updated successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });      
        setCredit(credit.map((credits) =>
          credits.id === editingCredit.id ? response.data.data : credits
        ));        setShowCreditEdit(false);
      }
    } catch (error) {
      console.error("Error updating Credit:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while updating the Credit.'
      });    }
  };

  const handleDeleteCredit = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_credit_note",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Credit Notes has been deleted.',
            'success'
          );
          fetchCredit();
        } else {
          throw new Error(response.data.message || "Failed to delete Credit Notes");
        }
      }
    } catch (error) {
      console.error("Delete Credit Notes Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Credit Notes.'
      });
    }
  };
 
  
  const generatePDF = (credits) => {
    if (!credits) {
      console.error('Invalid credits data');
      return;
    }
  
    const doc = new jsPDF();
     const pageWidth = doc.internal.pageSize.width;
    const margin = 14;
    
    const logoWidth = 15;
    const logoHeight = 15;
    doc.addImage(NewSGSLogo, 'PNG', margin, 15, logoWidth, logoHeight);
  
    // Add company name and address
    doc.setFontSize(12);
    doc.text('UK ARCHERS LTD', margin, 40);
    doc.text('Company number 12833471',margin,45)

    doc.setFontSize(10);
    doc.text('149 Victoria Road', margin, 55);
    doc.text('Barking', margin, 60);
    doc.text('IG11 8PZ', margin, 65);
    doc.text('England', margin, 70);
  
    // Add INVOICE text
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text('INVOICE', pageWidth - margin, 25, { align: 'right' });
  
   
    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(`Invoice Name: ${credits.credit_inv_name || 'N/A'}`, pageWidth - margin, 40, { align: 'right' });
    doc.text(`Site Group: ${credits.credit_inv_site_group || 'N/A'}`,pageWidth - margin, 50, { align: 'right' });
    doc.text(`Date: ${new Date().toLocaleDateString()}`, pageWidth - margin, 45, { align: 'right' });

    // Add payment details
    doc.autoTable({
      startY: 90,
      head: [['Item','Description']],
      body: [
        ['Invoice Name',` ${credits.credit_inv_name  || 'N/A'}`],
        ['Site Group', `${credits.credit_inv_site_group || 'N/A'}`],
        ['Credit Number', `${credits.credit_number  || 'N/A'}`],
        ['Credit Description', `${credits.credit_description|| 'N/A'}`],

      ],
      theme: 'striped',
      headStyles: {
        fillColor: [0, 0, 255],
        textColor: 255,
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 50 },
      },
    });
   


    // Add amount table
    doc.autoTable({
      startY: 150,
      head: [['Description', 'Amount']],
      body: [
        ['Credit Amount', `£${credits.credit_number || '0.00'}`],
      ],
      theme: 'grid',
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: 255,
        fontStyle: 'bold'
      },
      bodyStyles: {
        textColor: 0,
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      margin: { top: 110 }
    });
  
    
  
    return doc.output('blob');};


  const previewPDF = (credits) => {
    const pdfBlob = generatePDF(credits);
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Accounts', bold: false },
      { name: 'Invoice', bold: false },
      { name: 'Credit Notes', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(credit);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
    XLSX.writeFile(workbook, "CreditNotes_data.xlsx");
  }

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>           
          <div className="col-auto d-flex flex-wrap gap-2">
          
            <Button
  variant="contained"
  color="primary"
  onClick={handleShowAddEventDetail}
  sx={{ padding: '8px', marginLeft: '130px' }}
>
Add Credit
</Button>
          </div>
</div>


<Modal show={showCreditEdit} onHide={() => setShowCreditEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Credit Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
                  <Form.Select
                    style={{ width: "100%" }}
                    name="credit_inv_name"
                    value={editingCredit?.credit_inv_name || ""}
                    onChange={(e) => setEditingCredit({...editingCredit, credit_inv_name: e.target.value})}>
                    <option>Select Client...</option>
                    <option>SGS</option>
                    <option>sample</option>

                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
                  <Form.Select
                    style={{ width: "100%" }}
                    name="credit_inv_site_group"
                    value={editingCredit?.credit_inv_site_group}
                    onChange={(e) => setEditingCredit({...editingCredit, credit_inv_site_group: e.target.value})}>
                    <option>Select Site Group...</option>
                    <option>Site1</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Invoices</Form.Label>
                  <Form.Select
                    style={{ width: "100%" }}
                    name="credit_invoice_no"
                    value={editingCredit?.credit_invoice_no}
                    onChange={(e) => setEditingCredit({...editingCredit, credit_invoice_no: e.target.value})}>
                  
                    <option>Select Invoice Number...</option>
                    <option>Number</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Credit Note Date
                  </Form.Label>
                  <Form.Control
                         name="credit_date"
                         value={editingCredit?.credit_date}
                         onChange={(e) => setEditingCredit({...editingCredit, credit_date: e.target.value})}
                    type="Date"
                    style={{ width: "100%" }}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Credit Number
                  </Form.Label>
                  <Form.Control
                 name="credit_number"
                 value={editingCredit?.credit_number}
                 onChange={(e) => setEditingCredit({...editingCredit, credit_number: e.target.value})}
                 type="number"
                    style={{ width: "100%" }}
                    placeholder="Credit Note Number"
                  />
                </Form.Group>
                {inputs.map((input, index) => (
                  <div
                    key={input.id}
                    className="d-flex align-items-center mb-3">
                    <Form.Group className="mr-3" style={{ flex: 1 }}>
                      <Form.Label style={{ float: "left" }}>
                       Amount
                      </Form.Label>
                      <Form.Control
                          name="credit_amount"
                          value={editingCredit?.credit_amount}
                          onChange={(e) => setEditingCredit({...editingCredit, credit_amount: e.target.value})}
                          type="number"
                        style={{ width: "100%" }}
                        placeholder="Amount"
                      />
                    </Form.Group>
                    <Form.Group className="mr-3" style={{ flex: 2 }}>
                      <Form.Label style={{ float: "left", marginLeft: "30px" }}>
                        Credit Note Description
                      </Form.Label>
                      <Form.Control
                                name="credit_description"
                                value={editingCredit?.credit_description}
                                onChange={(e) => setEditingCredit({...editingCredit, credit_description: e.target.value})}
                                type="text"
                        placeholder="Description"
                        style={{ width: "90%", marginLeft: "25px" }}
                      />
                    </Form.Group>
                    <button
                      variant="danger"
                      onClick={() => handleRemoveInput(input.id)}
                      style={{
                        marginRight: "5px",
                        color: "white",
                        backgroundColor: "red",
                        border: "0",
                        padding: "8px 10px",
                        marginTop: "25px",
                        borderRadius: "5px",
                      }}>
                      <i className="fas fa-times"></i>
                    </button>
                    {index === inputs.length - 1 && (
                      <button
                        variant="primary"
                        onClick={handleAddInput}
                        style={{
                          color: "white",
                          backgroundColor: "Green",
                          border: "0",
                          padding: "8px 10px",
                          marginTop: "25px",
                          borderRadius: "5px",
                        }}>
                        <i className="fas fa-plus"></i>
                      </button>
                    )}
                  </div>
                ))}
              </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreditEdit(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateCredit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
            show={showAddEventDetail}
            onHide={handleCloseAddEventDetail}
            size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add Credit Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Client Name</Form.Label>
                  <Form.Select
                    style={{ width: "100%" }}
                    name="credit_inv_name"
                    value={newCredit?.credit_inv_name || ""}
                                    onChange={handleInputChange}>
                    <option value="">Select Client...</option>
                    <option value="SGS">SGS</option>
                    <option value="SGS">sample</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Site Group</Form.Label>
                  <Form.Select
                    style={{ width: "100%" }}
                    name="credit_inv_site_group"
                    value={newCredit?.credit_inv_site_group}
                    onChange={handleInputChange}>
                    <option value="">Select Site Group...</option>
                    <option value="Site1">Site1</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Invoices</Form.Label>
                  <Form.Select
                    style={{ width: "100%" }}
                    name="credit_invoice_no"
                    value={newCredit?.credit_invoice_no}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Invoice Number...</option>
                    <option value="Number">Number</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                    Credit Note Date
                  </Form.Label>
                  <Form.Control
                         name="credit_date"
                         value={newCredit?.credit_date}
                         onChange={handleInputChange}
                    type="Date"
                    style={{ width: "100%" }}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                    Credit Number
                  </Form.Label>
                  <Form.Control
                 name="credit_number"
                 value={newCredit?.credit_number}
                 onChange={handleInputChange}
                    type="number"
                    style={{ width: "100%" }}
                    placeholder="Credit Note Number"
                  />
                </Form.Group>
                {inputs.map((input, index) => (
                  <div
                    key={input.id}
                    className="d-flex align-items-center mb-3">
                    <Form.Group className="mr-3" style={{ flex: 1 }}>
                      <Form.Label style={{ float: "left" }}>
                      <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                        Credit Note Amount
                      </Form.Label>
                      <Form.Control
                          name="credit_amount"
                          value={newCredit?.credit_amount}
                          onChange={handleInputChange}
                        type="number"
                        style={{ width: "100%" }}
                        placeholder="Amount"
                      />
                    </Form.Group>
                    <Form.Group className="mr-3" style={{ flex: 2 }}>
                      <Form.Label style={{ float: "left", marginLeft: "30px" }}>
                        Credit Note Description
                      </Form.Label>
                      <Form.Control
                                name="credit_description"
                                value={newCredit?.credit_description}
                                onChange={handleInputChange}
                        type="text"
                        placeholder="Description"
                        style={{ width: "90%", marginLeft: "25px" }}
                      />
                    </Form.Group>
                    <button
                      variant="danger"
                      onClick={() => handleRemoveInput(input.id)}
                      style={{
                        marginRight: "5px",
                        color: "white",
                        backgroundColor: "red",
                        border: "0",
                        padding: "8px 10px",
                        marginTop: "25px",
                        borderRadius: "5px",
                      }}>
                      <i className="fas fa-times"></i>
                    </button>
                    {index === inputs.length - 1 && (
                      <button
                        variant="primary"
                        onClick={handleAddInput}
                        style={{
                          color: "white",
                          backgroundColor: "Green",
                          border: "0",
                          padding: "8px 10px",
                          marginTop: "25px",
                          borderRadius: "5px",
                        }}>
                        <i className="fas fa-plus"></i>
                      </button>
                    )}
                  </div>
                ))}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleAddCredit}>
                ADD CREDIT NOTE
              </Button>
              <Button variant="secondary" onClick={handleCloseAddEventDetail}>
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>
 


              <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Client Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Site Group</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Credit Note Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Credit Note Amount</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Credit Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Description</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {filteredCredit
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((credits, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell >{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell >{credits.credit_inv_name}</TableCell>
                    <TableCell >{credits.credit_inv_site_group}</TableCell>
                    <TableCell >{credits.credit_date}</TableCell>
                    <TableCell >{credits.credit_amount}</TableCell>
                    <TableCell >{credits.credit_number}</TableCell>
                    <TableCell >{credits.credit_description}</TableCell>
                    <TableCell >


                    <Tooltip 
    title="Edit Credit Notes" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{ marginRight: 2,  color: 'white', 
      backgroundColor: 'blue',  '&:hover': { backgroundColor: 'darkblue' }  }}
      onClick={() => handleEditCredit(credits)}  >
    <Edit sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>

  <Tooltip 
    title="Delete Credit Notes" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="error"
    size="small"
    sx={{ marginRight: 2,  color: 'white', 
      backgroundColor: 'red',  '&:hover': { backgroundColor: 'darkred' }  }}
      onClick={() => handleDeleteCredit(credits.id)}  >
    <Delete sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>

  <Tooltip 
    title="View PDF" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton
    color="primary"
    size="small"
    sx={{color: 'white', 
      backgroundColor: 'darkviolet',  '&:hover': { backgroundColor: 'darkviolet' }  }}

      onClick={() => previewPDF(credits)}  >
    <PictureAsPdf sx={{ fontSize: 16 }} />
  </IconButton>
  </Tooltip>

</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredCredit.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
              <Modal show={showEmail} onHide={handleCloseEmail}>
                <Modal.Header closeButton>
                  <Modal.Title>Send Invoice Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h3>
                    Email Recipient :
                    <span style={{ color: "red" }}>sewrfd@gmail.com</span>
                  </h3>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary">SEND</Button>
                  <Button variant="secondary" onClick={handleCloseEmail}>
                    CLOSE
                  </Button>
                </Modal.Footer>
              </Modal>
        
    </>
  );
};

export default CreditNotes;
