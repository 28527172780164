import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import Dashboard from "../../Dashboard/Dashboard";
import Swal from 'sweetalert2';
import { useLocation,useNavigate } from "react-router-dom";


const EmployeeChecklist = () => {
  const initialCharacteristics = [
    "Appearance And Pose",
    "Friendliness And Personality",
    "Conversational Ability And Alertness",
    "Education And Qualifications",
    "Skills And Experience",
    "Previous Experience In Security Industry",
    "Drive And Initiative",
    "Special Circumstances",
  ];

  const [checklistData, setChecklistData] = useState(
    initialCharacteristics.map(characteristic => ({
      characteristics: characteristic,
      assignment: "",
      comments: ""
    }))
  );
  const [suitable, setSuitable] = useState("");
  const [additionalComments, setAdditionalComments] = useState("");
  const [additionalFields, setAdditionalFields] = useState({
    interviewDate: "",
    interviewer: "",
    dateNotified: "",
    startDate: ""
  });

  const handleAdditionalFieldChange = (field, value) => {
    setAdditionalFields(prev => ({
      ...prev,
      [field]: value
    }));
  };
  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
  const employeeId = "1";

  const handleInputChange = (index, field, value) => {
    const updatedData = [...checklistData];
    updatedData[index][field] = value;
    setChecklistData(updatedData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

   
    const payload = {
      employee_id: employeeId,
      data: checklistData,
      suitable: suitable,
      additional_comments: additionalComments,
      ...additionalFields
    };

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/interview_checklist",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.success) {
        console.log("Interview checklist saved successfully:",response);
        Swal.fire({
          icon: 'success',
          title: 'Interview checklist saved',
          text: 'Interview checklist saved successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      } else {
        console.error("Failed to save interview checklist");
      }
    } catch (error) {
      console.error("Error saving interview checklist:", error);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    const breadcrumbPaths = [
      { name: 'Master', bold: false },
      { name: 'Employee', bold: false },
      { name: 'View', path:'/view', bold: false },
      { name: 'Employee Interview Checklist', path: location.pathname, bold: true }
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
      
        <div className="toolbar-content">
        <h3 className="text-white" style={{ fontSize: '15px' }}>
      {navigationPath.map((part, index) => (
        <React.Fragment key={index}>
          <Link
            to={part.path}
            onClick={(e) => handleClick(part.path, e)}
            style={{
              fontWeight: part.bold ? 'bold' : 'normal',
              color: 'white',
              fontSize: part.bold ? '18px' : '15px',
              textDecoration: 'none'
            }}
          >
            {part.name}
          </Link>
          {index < navigationPath.length - 1 && " / "}
        </React.Fragment>
      ))}
    </h3> 
        </div>
  
      </div>

      <div className="container mt-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title mb-20" style={{ fontSize: "20px" }}>
               Interview Checklist

            </h5>
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-md-4">
                  <strong style={{ fontSize: "15px" }}>Characteristics</strong>
                </div>
                <div className="col-md-4">
                  <strong style={{ fontSize: "15px" }}>
                    Assessment 1 - 10
                    <br />1 = Low & 10 = High
                  </strong>
                </div>
                <div className="col-md-4">
                  <strong style={{ fontSize: "15px" }}>Comments</strong>
                </div>
              </div>
              {checklistData.map((item, index) => (
                <div className="row mb-3" key={index}>
                  <div className="col-md-4">
                    <strong style={{ fontSize: "15px" }}>{item.characteristics}</strong>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-select"
                      value={item.assignment}
                      onChange={(e) => handleInputChange(index, "assignment", e.target.value)}
                    >
                      <option value="">Select Assessment</option>
                      {[...Array(10)].map((_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      value={item.comments}
                      onChange={(e) => handleInputChange(index, "comments", e.target.value)}
                    />
                  </div>
                </div>
              ))}
              <div className="row mb-3">
          <div className="col-md-4">Suitable</div>
          <div className="col-md-8">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="suitable"
                id="suitableYes"
                value="yes"
                checked={suitable === "yes"}
                onChange={(e) => setSuitable(e.target.value)}
              />
              <label className="form-check-label" htmlFor="suitableYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="suitable"
                id="suitableNo"
                value="no"
                checked={suitable === "no"}
                onChange={(e) => setSuitable(e.target.value)}
              />
              <label className="form-check-label" htmlFor="suitableNo">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">Additional Comments</div>
          <div className="col-md-8">
            <textarea
              className="form-control"
              rows="3"
              name="additionalComments"
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
            ></textarea>
          </div>
        </div>
        {[
          { label: "Interview Date", key: "interviewDate" },
          { label: "Interviewer", key: "interviewer" },
          { label: "Date Candidate Notified Of Outcome", key: "dateNotified" },
          { label: "Agreed Training Session / Start date", key: "startDate" },
        ].map(({ label, key }) => (
          <div className="row mb-3" key={key}>
            <div className="col-md-4">{label}</div>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control"
                value={additionalFields[key]}
                onChange={(e) => handleAdditionalFieldChange(key, e.target.value)}
              />
            </div>
          </div>
        ))}
        <div className="row">
          <div className="col-md-12 text-end">
            <button
              type="submit"
              className="btn btn-success"
              style={{ marginRight: "200px" }}
            >
              Save
            </button>
          </div>
        </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeChecklist;
