import React,{useState} from "react";
import Dashboard from "../../../Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";
import "./Subcontro.css";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

const SubAdd = () => {

  const [formData, setFormData] = useState({
    subcon_company_name: "",
    subcon_company_address: "",
    subcon_contact_person: "",
    subcon_contact_number: "",
    subcon_contact_depart: "",
    subcon_email: "",
    subcon_invoice_terms: "",
    subcon_payment_terms: "",
    subcon_vat_reg: false,
    subcon_vat_number:"0",
    subcon_payrate: "",

  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.subcon_company_name.trim()) {
      newErrors.subcon_company_name = "Company Name is required";
    }
    if (!formData.subcon_company_address.trim()) {
      newErrors.subcon_company_address = "Company Address is required";
    }
    if (!formData.subcon_contact_person.trim()) {
      newErrors.subcon_contact_person = "Contact Person is required";
    }
    if (!formData.subcon_contact_number.trim()) {
      newErrors.subcon_contact_number = "Contact Number is required";
    } else if (!/^\d{10}$/.test(formData.subcon_contact_number)) {
      newErrors.subcon_contact_number = "Contact Number must be 10 digits";
    }
    if (!formData.subcon_contact_depart.trim()) {
      newErrors.subcon_contact_depart = "Contact Department is required";
    }
    if (!formData.subcon_email.trim()) {
      newErrors.subcon_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.subcon_email)) {
      newErrors.subcon_email = "Email is invalid";
    }
    if (!formData.subcon_invoice_terms) {
      newErrors.subcon_invoice_terms = "Invoice Terms is required";
    }
    if (!formData.subcon_payment_terms.trim()) {
      newErrors.subcon_payment_terms = "Payment Terms is required";
    }
    if (!formData.subcon_payrate.trim()) {
      newErrors.subcon_payrate = "Pay Rate is required";
    } else if (isNaN(formData.subcon_payrate)) {
      newErrors.subcon_payrate = "Pay Rate must be a number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const _token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/sub_contract_store",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${_token}`,
            },
          }
        );
        if (response.data && response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Subcontractor Updated',
            text: 'Subcontractor has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          navigate("/subview");
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error:", error);
        setErrors({ api: "An error occurred. Please try again." });
      }
    }
  };
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Employee', bold: false },
      { name: 'Subcontractors', bold: false },
      { name: 'Add New Subcontractors', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>
      </div>
      <div className="container mt-5">
        <Form onSubmit={handleSubmit}>
          <div className="row mb-3 ">
            <Form.Label
            style={{fontSize:'15px'}}
              className="col-sm-2 col-form-label mt-5"
            >
              <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Company Name
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="text" 
                className={`form-control mt-5 ${
                  errors.subcon_company_name ? "is-invalid" : ""
                }`}
                name="subcon_company_name"
                placeholder="Company Name"
                value={formData.subcon_company_name}
                onChange={handleChange}
              />
              {errors.subcon_company_name && (
                <div className="invalid-feedback">
                  {errors.subcon_company_name}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="companyAddress" className="col-sm-2 col-form-label">
              Company Address
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="text"
                className={`form-control ${
                  errors.subcon_company_address ? "is-invalid" : ""
                }`}
                id="companyAddress"
                name="subcon_company_address"
                placeholder="Company Address"
                value={formData.subcon_company_address}
                onChange={handleChange}
              />
              {errors.subcon_company_address && (
                <div className="invalid-feedback">
                  {errors.subcon_company_address}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="contactPerson" className="col-sm-2 col-form-label">
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Contact Person
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="text"
                className={`form-control ${
                  errors.subcon_contact_person ? "is-invalid" : ""
                }`}
                id="contactPerson"
                name="subcon_contact_person"
                placeholder="Contact Person"
                value={formData.subcon_contact_person}
                onChange={handleChange}
              />
              {errors.subcon_contact_person && (
                <div className="invalid-feedback">
                  {errors.subcon_contact_person}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="contactNumber" className="col-sm-2 col-form-label">
              Contact Number
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="number"
                className={`form-control ${
                  errors.subcon_contact_number ? "is-invalid" : ""
                }`}
                id="contactNumber"
                name="subcon_contact_number"
                placeholder="Contact Number"
                value={formData.subcon_contact_number}
                onChange={handleChange}
              />
              {errors.subcon_contact_number && (
                <div className="invalid-feedback">
                  {errors.subcon_contact_number}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label
                        style={{fontSize:'15px'}}

              htmlFor="contactDepartment"
              className="col-sm-2 col-form-label"
            >

              Contact Department
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="text"
                className={`form-control ${
                  errors.subcon_contact_depart ? "is-invalid" : ""
                }`}
                id="contactDepartment"
                name="subcon_contact_depart"
                placeholder="Contact Department"
                value={formData.subcon_contact_depart}
                onChange={handleChange}
              />
              {errors.subcon_contact_depart && (
                <div className="invalid-feedback">
                  {errors.subcon_contact_depart}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="emailAddress" className="col-sm-2 col-form-label">
              Email Address
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="email"
                className={`form-control ${
                  errors.subcon_email ? "is-invalid" : ""
                }`}
                id="emailAddress"
                name="subcon_email"
                placeholder="Email Address"
                value={formData.subcon_email}
                onChange={handleChange}
              />
              {errors.subcon_email && (
                <div className="invalid-feedback">{errors.subcon_email}</div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="invoiceTerms" className="col-sm-2 col-form-label">
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Invoice Terms
            </Form.Label>
            <div className="col-sm-10">
              <select
                className={`form-select form-control ${
                  errors.subcon_invoice_terms ? "is-invalid" : ""
                }`}
                id="invoiceTerms"
                name="subcon_invoice_terms"
                value={formData.subcon_invoice_terms}
                onChange={handleChange}
                >
                <option value="">Select invoice term...</option>
                <option value="fortnightly">Fortnightly Invoice</option>
                <option value="monthly">Monthly Invoice</option>
                <option value="weekly">Weekly Invoice</option>
              </select>
              {errors.subcon_invoice_terms && (
                <div className="invalid-feedback">
                  {errors.subcon_invoice_terms}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="paymentTerms" className="col-sm-2 col-form-label">
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Payment Terms
            </Form.Label>
            <div className="col-sm-10">
              <textarea
                className={`form-control ${
                  errors.subcon_payment_terms ? "is-invalid" : ""
                }`}
                id="paymentTerms"
                name="subcon_payment_terms"
                placeholder="Payment Terms"
                value={formData.subcon_payment_terms}
                onChange={handleChange}
                rows="3"
              ></textarea>
              {errors.subcon_payment_terms && (
                <div className="invalid-feedback">
                  {errors.subcon_payment_terms}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
        <Form.Label style={{fontSize:'15px'}} className="col-sm-2 col-form-label">
          VAT Registered?
        </Form.Label>
        <div className="col-sm-10 d-flex align-items-center">
          <input
            className={`form-check-input ${
              errors.subcon_vat_reg ? "is-invalid" : ""
            }`}
            type="checkbox"
            id="vatRegistered"
            name="subcon_vat_reg"
            checked={formData.subcon_vat_reg}
            onChange={handleChange}
          />
          {errors.subcon_vat_reg && (
            <div className="invalid-feedback">{errors.subcon_vat_reg}</div>
          )}
        </div>
      </div>
      
      {formData.subcon_vat_reg && (
        <div className="row mb-3">
          <Form.Label style={{fontSize:'15px'}} className="col-sm-2 col-form-label">
          <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
            VAT Number
          </Form.Label>
          <div className="col-sm-10">
            <input
              className={`form-control ${
                errors.subcon_vat_number ? "is-invalid" : ""
              }`}
              type="text"
              id="vatNumber"
              name="subcon_vat_number"
              value={formData.subcon_vat_number || ''}
              onChange={handleChange}
              placeholder="Enter VAT Number"
            />
            {errors.subcon_vat_number && (
              <div className="invalid-feedback">{errors.subcon_vat_number}</div>
            )}
          </div>
        </div>
      )}
          <div className="row mb-3">
            <Form.Label             style={{fontSize:'15px'}}
 htmlFor="payRate" className="col-sm-2 col-form-label">
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
              Pay Rate
            </Form.Label>
            <div className="col-sm-10">
              <input
                type="text"
                className={`form-control ${
                  errors.subcon_payrate ? "is-invalid" : ""
                }`}
                id="payRate"
                name="subcon_payrate"
                placeholder="Pay Rate"
                value={formData.subcon_payrate}
                onChange={handleChange}
              />
              {errors.subcon_payrate && (
                <div className="invalid-feedback">{errors.subcon_payrate}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-2">
              <button
                type="submit"
               className="SubAddSave"
               
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SubAdd;
