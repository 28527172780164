import React, { useState } from "react";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';


const TrainingInfo = () => {
  const [trainingData, setTrainingData] = useState({
    training_title: "",
    training_title_other:"",
    awarding_bodies: "",
    awarding_bodies_other:"",
    award_date: "",
    expiry_date: "",
    no_training_far: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTrainingData({
      ...trainingData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_trainings",
        {
          ...trainingData,
          employee_id: 1,
          company_branch: 1,
          no_training_far: trainingData.no_training_far ? "1" : "0",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Training added successfully:", response);
      if(response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'Training Added',
          text: 'Training has been Added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
      setLoading(false);
      setTrainingData({
        training_title: "",
        awarding_bodies: "",
        award_date: "",
        expiry_date: "",
        no_training_far: false,
      });
    } catch (err) {
      setError("Error adding training");
      setLoading(false);
    }
  };

  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  return (
    <Container className="px-0">
    <Row className="mx-0">
      <Col md={10} className="main-content">
        <Row>
          <Col md={9} className="form-content">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={10} className="mt-10">
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Training Title</Form.Label>
                    <Form.Select
                      name="training_title"
                      value={trainingData.training_title}
                      onChange={handleInputChange}
                      style={inputStyle}
                    >
                      <option value="">Select Training</option>
                      <option value="CSCS">CSCS</option>
                      <option value="First Aid">First Aid</option>
                      <option value="Health & Safety">Health & Safety</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  {trainingData.training_title === 'Other' && (
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Custom Training Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="training_title_other"
                        value={trainingData.training_title_other}
                        onChange={handleInputChange}
                        style={inputStyle}
                        placeholder="Enter custom training title"
                      />
                    </Form.Group>
                  )}
                </Col>
                <Col md={10}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Awarding Body</Form.Label>
                    <Form.Select
                      name="awarding_bodies"
                      value={trainingData.awarding_bodies}
                      onChange={handleInputChange}
                      style={inputStyle}
                    >
                      <option value="">Select Awarding Body</option>
                      <option value="Edexcel">Edexcel</option>
                      <option value="QA">Highfield</option>
                      <option value="QA">Pearsons QNUK </option>
                      <option value="QA">QA</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  {trainingData.awarding_bodies === 'Other' && (
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={labelStyle}>Custom Awarding Body</Form.Label>
                      <Form.Control
                        type="text"
                        name="awarding_bodies_other"
                        value={trainingData.awarding_bodies_other}
                        onChange={handleInputChange}
                        style={inputStyle}
                        placeholder="Enter custom awarding body"
                      />
                    </Form.Group>
                  )}
                </Col>
                <Col md={10}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Award Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="award_date"
                      value={trainingData.award_date}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col md={10}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Label style={labelStyle}>Expiry Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="expiry_date"
                      value={trainingData.expiry_date}
                      onChange={handleInputChange}
                      style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group style={formGroupStyle}>
                    <Form.Check
                      type="checkbox"
                      name="no_training_far"
                      checked={trainingData.no_training_far}
                      onChange={handleInputChange}
                      label="No training so far"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button
                type="submit"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "dodgerblue",
                  color: "white",
                  border: "0",
                  borderRadius: "5px",
                }}
                disabled={loading}
              >
                {loading ? "Saving..." : "SAVE"}
              </Button>
            </Form>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
  );
};

export default TrainingInfo;