import React,{useState} from 'react'
import { FormControl, InputGroup, ListGroup, Table } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";

const ManageTravel = () => {
  const [manage,setManage] = useState(false);

  const handleShowManage = ()=>{
    setManage(true);
  }
  const handleCloseManage = ()=>{
    setManage(false);
  }
  return (
    <div>
    <Table striped bordered hover style={{ marginTop: "20px" }}>
      <thead>
        <tr style={{ fontSize: "16px" }}>
          <th>ID</th>
          <th>Branch</th>
          <th>Add Travel Expense From Employee Payroll Information <span style={{color:'red'}}>In Penalty/Deduction Box</span></th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ fontSize: "15px" }}>
          <td>1</td>
          <td>Head Office</td>
          <td></td>
          <td>
            <button className="btn-success" onClick={handleShowManage}>
              <i
                className="fas fa-pen"
                style={{
                  color: "white",
                  padding: "5px",
                  fontSize: "16px",
                }}></i>
            </button>
          </td>
        </tr>
      </tbody>
    </Table>
    
    <Modal show={manage} onHide={handleCloseManage}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Travel Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Label style={{ marginRight: "10px", marginBottom: "0" }}>
           Enable Travel Expense
              </Form.Label>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="yesCheckbox" label="Yes" />
              </div>
              <div className="form-check form-check-inline">
                <Form.Check type="checkbox" id="noCheckbox" label="No" />
              </div>
            </Form.Group>
          
          
         
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="Event-button"
            variant="primary"
            style={{ color: "white" }}>
            UPDATE
          </Button>
          <Button variant="secondary" onClick={handleCloseManage}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
  </div>
  )
}

export default ManageTravel
