import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboard from "../../../Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import {
  Card,
  Button as BootstrapButton,
  Table as BootstrapTable,
  Modal,
  Form,
  Col,
  Row,
  Alert,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button as MuiButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";

const EditSubForm = ({ employee, onClose, onUpdate }) => {
  const [formData, setFormData] = useState(employee);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>EDIT SUBVIEW DETAILS</h2>
          <BootstrapButton className="close-btn" onClick={onClose}>
            &times;
          </BootstrapButton>
        </div>
        <form onSubmit={handleEditSubmit}>
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              name="subcon_company_name"
              value={formData.subcon_company_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Company Address</label>
            <input
              type="text"
              name="subcon_company_address"
              value={formData.subcon_company_address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Contact Person</label>
            <input
              type="text"
              name="subcon_contact_person"
              value={formData.subcon_contact_person}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="text"
              name="subcon_contact_number"
              value={formData.subcon_contact_number}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="subcon_email"
              value={formData.subcon_email}
              onChange={handleChange}
            />
          </div>
          <div className="form-actions">
            <BootstrapButton type="submit" className="btn btn-primary">
              UPDATE
            </BootstrapButton>
            <BootstrapButton
              type="BootstrapButton"
              className="btn btn-secondary"
              onClick={onClose}>
              CLOSE
            </BootstrapButton>
          </div>
        </form>
      </div>
    </div>
  );
};
const SubViewDetails = ({ id }) => {
  const [tableData, setTableData] = useState([]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [subEmployeePerPage, setSubEmployeePerPage] = useState(10);
  const [currentEmployeePage, setCurrentEmployeePage] = useState(1);
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState("");
  const [totalSubEmployees, setTotalSubEmployees] = useState(0);
  const [totalContact, setTotalContact] = useState(0);

  const [formData, setFormData] = useState({
    sub_con_id: "1",
    subcon_company_name: "",
    subemp_staff_pin: "",
    subemp_contact_number: "",
    subemp_edbs_number: "",
    subemp_email: "",
    subemp_nationality: "",
    subemp_sia_required: "Yes",
    subemp_sia_number: "",
    subemp_sia_type: "",
    subemp_sia_expiry_date: "",
    subemp_dob: "",
    subemp_ni_number: "",
    subemp_training_name: "",
    subemp_certificate_number: "",
    subemp_address: "",
    subemp_pmva_training: "",
    subemp_need_uk_visa: "",
    subemp_visa_type: "",
    subemp_visa_expiry: "",
    subemp_status: "Active",
  });

  const [contactData, setContactData] = useState({
    sub_con_id: 1,
    company_name: "",
    department_name: "",
    contact_name: "",
    contact_number: "",
    fax: "",
    email: "",
    del: null,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [showEmployee, setShowEmployee] = useState(false);
  const [showSubEmployee, setShowSubEmployee] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_show",
        {},
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("API response:", response.data.data);
      setTableData(response.data.data);
      setFilteredEmployeeData(response.data.data);
      setTotalSubEmployees(response.data.data.length);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrors("An error occurred while fetching data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = tableData.filter((data) =>
      Object.values(data).some(
        (value) =>
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(employeeSearchTerm.toLowerCase())
      )
    );
    setFilteredEmployeeData(filtered);
    setCurrentEmployeePage(0);
  }, [employeeSearchTerm, tableData]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subemployee");
    XLSX.writeFile(workbook, "Subemployee_viewdata.xlsx");
  };

  const handleEditClick = () => {
    setShowEditForm(true);
  };

  const handleCloseForm = () => {
    setShowEditForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const _token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_store",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${_token}`,
          },
        }
      );
      console.log("Full response:", response);
      if (response.data && response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Sub Employee Added",
          text: "Sub Employee has been Added successfully.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        fetchTableData();
        handleCloseSubEmployee();
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors({ api: "An error occurred. Please try again." });
    }
  };

  const handleShowSubEmployee = () => {
    setShowSubEmployee(true);
  };

  const handleCloseSubEmployee = () => {
    setShowSubEmployee(false);
  };

  const handleShowEmployee = () => {
    setShowEmployee(true);
  };
  const handleCloseEmployee = () => {
    setShowEmployee(false);
  };

  useEffect(() => {
    fetchSubData();
  }, []);

  const fetchSubData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_contract_show",
        {},
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Sub Response:", response);
      setViewDetails(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const [showEditForm, setShowEditForm] = useState(false);

  const handleUpdateEmployee = async (updatedData) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_contract_store",
        updatedData,
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("SubEmployee response:", response);
      fetchSubData();
      setShowEditForm(false);
    } catch (error) {
      console.error("Error updating client data:", error);
    }
  };
  const location = useLocation();
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const breadcrumbPaths = [
      { name: "Employee", bold: false },
      { name: "Subcontractor", bold: false },
      { name: "View", path: "/subview", bold: false },
      { name: "Sub View Details", path: location.pathname, bold: true },
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1,
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };
  const [verifyResponse, setVerifyResponse] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const verifySIA = async () => {
    setIsLoading(true);
    setApiError(null);

    const siaNumber = formData.subemp_sia_number;

    if (!/^\d{16}$/.test(siaNumber)) {
      setApiError("Invalid SIA number. Please enter a valid 16-digit number.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/check_sia_type",
        { LicenseNo: siaNumber },
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("SIA Verification Response:", response.data);

      if (
        !response.data ||
        response.data.length === 0 ||
        Object.keys(response.data[0]).length === 0
      ) {
        setVerifyResponse({ message: "SIA Number Not Found" });
      } else {
        setVerifyResponse(response.data);
        populateFormData(response.data[0]);
      }
      setShowModal(true);
    } catch (error) {
      console.error("Error verifying SIA:", error);
      setApiError(error.message || "An error occurred while verifying SIA");
    } finally {
      setIsLoading(false);
    }
  };

  const populateFormData = (data) => {
    setFormData((prevData) => {
      const newData = { ...prevData };

      Object.entries(data).forEach(([key, value]) => {
        const formattedValue = Object.values(value)[0] || "";
        switch (key) {
          case "row_1":
            newData.subcon_company_name = formattedValue;
            break;
          case "row_2":
            newData.subcon_company_name = formattedValue;
            break;
          case "row_3":
            newData.subemp_sia_number = formattedValue;
            break;
          case "row_5":
            newData.subemp_sia_type = formattedValue;
            break;
          case "row_6":
            newData.subemp_sia_expiry_date = formatDate(formattedValue);
            break;
        }
      });

      return newData;
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const [formSubData, setFormSubData] = useState({
    sub_con_id: "",
    company_name: "",
    department_name: "",
    contact_name: "",
    contact_number: "",
    fax: "",
    email: "",
  });
  const [subContractors, setSubContractors] = useState([]);
  const [filteredContact, setFilteredContact] = useState([]);
  const [subContactPerPage, setSubContactPerPage] = useState(10);
  const [currentContactPage, setCurrentContactPage] = useState(1);
  const [contactSearchTerm, setContactSearchTerm] = useState("");

  useEffect(() => {
    const filtered = subContractors.filter((contact) =>
      Object.values(contact).some(
        (value) =>
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(contactSearchTerm.toLowerCase())
      )
    );
    setFilteredContact(filtered);
    setCurrentContactPage(0);
  }, [contactSearchTerm, subContractors]);

  const handleSubInputChange = (e) => {
    setFormSubData({ ...formSubData, [e.target.name]: e.target.value });
  };

  const handleSubSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/store_sub_contact",
        formSubData
      );
      console.log("SubContact Response:", response);
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Subcontact Updated",
          text: "Subcontact has been updated successfully.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
      handleCloseEmployee();
      fetchSubContractors();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const fetchSubContractors = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_sub_contact"
      );
      setSubContractors(response.data.data);
      setFilteredContact(response.data.data);
      setTotalContact(response.data.data.length);
    } catch (error) {
      console.error("Error fetching subcontractors:", error);
    }
  };

  useEffect(() => {
    fetchSubContractors();
  }, []);

  const exportToContactExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(subContractors);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sub Contact");
    XLSX.writeFile(workbook, "SubContactdata.xlsx");
  };

  const [deleteData, setDeleteData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchDeletedSubEmployees();
  }, []);

  const fetchDeletedSubEmployees = async (id) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_trash",
        { employee_id: id },
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Deleted SubEmployee :", response);
      setDeleteData(response.data.data);
      setFilteredData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching deleted sub-employees:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = deleteData.filter((employee) =>
      Object.values(employee).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setPage(0);
  }, [searchTerm, deleteData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToDeleteExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(deleteData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sub Employee Delete");
    XLSX.writeFile(workbook, "Sub Employee Delete Data.xlsx");
  };


  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/store_sub_contact",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        console.log("Response:",response.data)
        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Employee has been deleted.',
            'success'
          );
          fetchSubContractors();
        } else {
          throw new Error(response.data.message || "Failed to delete Employee");
        }
      }
    } catch (error) {
      console.error("Delete Employee Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Employee.'
      });
    }
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{ backgroundColor: "#BF0404" }}>
        <h3 className="text-white" style={{ fontSize: "15px" }}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <Link
                to={part.path}
                onClick={(e) => handleClick(part.path, e)}
                style={{
                  fontWeight: part.bold ? "bold" : "normal",
                  color: "white",
                  fontSize: part.bold ? "18px" : "15px",
                  textDecoration: "none",
                }}>
                {part.name}
              </Link>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>

      {showEditForm && (
        <EditSubForm
          employee={viewDetails}
          onClose={handleCloseForm}
          onUpdate={handleUpdateEmployee}
        />
      )}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    {/* <h5 className="card-title">Mark LTD</h5> */}
                    <BootstrapButton
                      variant="info"
                      size="sm"
                      className="col-auto"
                      onClick={handleEditClick}>
                      <FontAwesomeIcon icon={faEdit} /> EDIT
                    </BootstrapButton>
                  </div>
                  <BootstrapTable borderless>
                    <tbody>
                      <tr>
                        <td>
                          <strong style={{fontSize:'15px'}}>Company Address</strong>
                        </td>
                        <td>{viewDetails.subcon_company_address}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong style={{fontSize:'15px'}}>Company Name</strong>
                        </td>
                        <td>{viewDetails.subcon_company_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong style={{fontSize:'15px'}}>Contact Number</strong>
                        </td>
                        <td>{viewDetails.subcon_contact_number}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong style={{fontSize:'15px'}}>Contact Name</strong>
                        </td>
                        <td>{viewDetails.subcon_contact_person}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong style={{fontSize:'15px'}}>Email</strong>
                        </td>
                        <td>{viewDetails.subcon_email}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong style={{fontSize:'15px'}}>Invoice Terms</strong>
                        </td>
                        <td>{viewDetails.subcon_invoice_terms}</td>
                      </tr>
                    </tbody>
                  </BootstrapTable>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="text-center mb-4">
                <Card.Body>
                  <h1 className="display-4 text-danger">{totalSubEmployees}</h1>{" "}
                  <p className="text-muted">TOTAL SUB-EMPLOYEES</p>
                  <BootstrapButton
                    variant="success"
                    onClick={handleShowSubEmployee}>
                    ADD NEW SUB-EMPLOYEE
                  </BootstrapButton>
                </Card.Body>
              </Card>
              <Card className="text-center">
                <Card.Body>
                  <h1 className="display-4 text-danger">{totalContact}</h1>
                  <p className="text-muted">SUBCONTRACTOR CONTACTS</p>
                  <BootstrapButton
                    variant="success"
                    onClick={handleShowEmployee}>
                    ADD NEW CONTACT
                  </BootstrapButton>
                </Card.Body>
              </Card>
            </div>
          </div>

          <Modal show={showEmployee} onHide={handleCloseEmployee}>
            <Modal.Header closeButton>
              <Modal.Title>ADD SubContractor Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Company Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="company_name"
                    value={formSubData.company_name}
                    onChange={handleSubInputChange}
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Department Name
                  </Form.Label>
                  <Form.Select
                    type="number"
                    name="department_name"
                    value={formSubData.department_name}
                    onChange={handleSubInputChange}
                    style={{ width: "100%" }}>
                    <option>Select Department...</option>
                    <option>depart 1</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Contact Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    value={formSubData.contact_name}
                    onChange={handleSubInputChange}
                    placeholder="Contact Name"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>
                    Contact Number
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Contact Number"
                    name="contact_number"
                    value={formSubData.contact_number}
                    onChange={handleSubInputChange}
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>FAX</Form.Label>
                  <Form.Control
                    type="text"
                    name="fax"
                    value={formSubData.fax}
                    onChange={handleSubInputChange}
                    placeholder="FAX"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: "left" }}>Email</Form.Label>
                  <Form.Control
                    type="mail"
                    placeholder="Email"
                    name="email"
                    value={formSubData.email}
                    onChange={handleSubInputChange}
                    style={{ width: "100%" }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton
                variant="secondary"
                onClick={handleCloseEmployee}>
                CLOSE
              </BootstrapButton>
              <BootstrapButton variant="primary" onClick={handleSubSubmit}>
                SAVE RECORD
              </BootstrapButton>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showSubEmployee}
            onHide={handleCloseSubEmployee}
            size="lg">
            <Modal.Header closeButton>
              <Modal.Title>ADD SUB-EMPLOYEE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    SUBCONTRACTOR NAME
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="SUBCONTRACTOR NAME"
                      name="subcon_company_name"
                      value={formData.subcon_company_name}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Sub Staff PIN
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="number"
                      placeholder="Assign PIN to the staff"
                      name="subemp_staff_pin"
                      value={formData.subemp_staff_pin}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                {/* <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>STAFF NAME</Form.Label>
        <Col sm={9}>
          <Form.Control type="text" placeholder="Staff Name of Subcontractor"          name="subemp_staff_pin"
        value={formData.subemp_staff_pin}
        onChange={handleInputChange}/>
        </Col>
      </Form.Group> */}

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    CONTACT NUMBER
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Staff Contact Number"
                      name="subemp_contact_number"
                      value={formData.subemp_contact_number}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    EDBS NUMBER
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="EDBS Number"
                      name="subemp_edbs_number"
                      value={formData.subemp_edbs_number}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    EMAIL
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="email"
                      placeholder="Staff Email Address"
                      name="subemp_email"
                      value={formData.subemp_email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Nationality
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      name="subemp_nationality"
                      value={formData.subemp_nationality}
                      onChange={handleInputChange}>
                      <option>Please select ...</option>
                      <option>India</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}></Form.Label>
                  <Col sm={9}>
                    <Form.Check
                      type="checkbox"
                      label="SIA not required"
                      name="subemp_sia_required"
                      value={formData.subemp_sia_required}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    SIA Number
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="Number"
                      placeholder="SIA Number"
                      name="subemp_sia_number"
                      value={formData.subemp_sia_number}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: 10, offset: 2 }}>
                    <Form.Check
                      type="checkbox"
                      id="verifySIA"
                      label="Verify SIA Number"
                      onChange={(e) => e.target.checked && verifySIA()}
                      disabled={!formData.subemp_sia_number || isLoading}
                    />
                    {isLoading && " Verifying..."}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    SIA Type
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="subemp_sia_type"
                      value={formData.subemp_sia_type}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Expity Date
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="date"
                      placeholder="Expity Date"
                      name="subemp_sia_expiry_date"
                      value={formData.subemp_sia_expiry_date}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    DATE OF BIRTH
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="date"
                      placeholder="Date of birth"
                      name="subemp_dob"
                      value={formData.subemp_dob}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    N.I. NUMBER
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="NI Number"
                      name="subemp_ni_number"
                      value={formData.subemp_ni_number}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    TRAINING NAME
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Training Name"
                      name="subemp_training_name"
                      value={formData.subemp_training_name}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    CERTIFICATE NUMBER
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Certificate Number"
                      name="subemp_certificate_number"
                      value={formData.subemp_certificate_number}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    ADDRESS
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Staff Contact Address"
                      name="subemp_address"
                      value={formData.subemp_address}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    PMVA Trained officer?
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      name="subemp_pmva_training"
                      value={formData.subemp_pmva_training}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      name="subemp_pmva_training"
                      value={formData.subemp_pmva_training}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Do you need visa to work or remain in the UK?
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      name="subemp_need_uk_visa"
                      value={formData.subemp_need_uk_visa}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      name="subemp_need_uk_visa"
                      value={formData.subemp_need_uk_visa}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton
                variant="secondary"
                onClick={handleCloseSubEmployee}>
                CLOSE
              </BootstrapButton>
              <BootstrapButton variant="primary" onClick={handleSubmit}>
                ADD SUB EMPLOYEE
              </BootstrapButton>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
              if (verifyResponse && verifyResponse.length > 0) {
                populateFormData(verifyResponse[0]);
              }
            }}>
            <Modal.Header closeButton>
              <Modal.Title>SIA Verification Result</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {apiError ? (
                <Alert variant="danger">Error: {apiError}</Alert>
              ) : verifyResponse && verifyResponse.length > 0 ? (
                <table className="table table-bordered">
                  <tbody>
                    {verifyResponse[0] &&
                      Object.entries(verifyResponse[0]).map(
                        ([key, value], index) => {
                          const label = {
                            row_1: "First name",
                            row_2: "Surname",
                            row_3: "Licence number",
                            row_4: "Role",
                            row_5: "Licence sector",
                            row_6: "Expiry date",
                            row_7: "Status",
                          }[`row_${index + 1}`];

                          const formattedValue = Object.values(value)[0] || "-";

                          return (
                            <tr key={key} style={{ fontSize: "15px" }}>
                              <td style={{ width: "40%", fontWeight: "bold" }}>
                                {label}
                              </td>
                              <td>{formattedValue}</td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              ) : (
                <p>SIA Number Not Found</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton
                variant="secondary"
                onClick={() => {
                  setShowModal(false);
                  if (verifyResponse && verifyResponse.length > 0) {
                    populateFormData(verifyResponse[0]);
                  }
                }}>
                Close
              </BootstrapButton>
            </Modal.Footer>
          </Modal>

          <Box
            sx={{
              width: "100%",
              mt: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Paper sx={{ width: "90%", mb: 2 }}>
              <h3
                className=""
                style={{ marginBottom: "20px", marginTop: "10px" }}>
                Current Sub-Employee
              </h3>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  value={employeeSearchTerm}
                  onChange={(e) => setEmployeeSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                  }}
                />
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<FileDownloadIcon />}
                  onClick={exportToExcel}>
                  Export to Excel
                </MuiButton>
              </Box>
              <TableContainer>
                <MuiTable
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Pin</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Name</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Contact Number
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Sia Number
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Sia Type</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Sia Expiry
                      </TableCell>
                      {/* <TableCell sx={{ fontSize: "15px" }}>
                        Training Name
                      </TableCell> */}
                      <TableCell sx={{ fontSize: "15px" }}>
                        Certificate Number
                      </TableCell>
                      <TableCell sx={{ fontSize: '13px'}}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredEmployeeData
                      .slice(
                        currentEmployeePage * subEmployeePerPage,
                        currentEmployeePage * subEmployeePerPage +
                          subEmployeePerPage
                      )
                      .map((event, index) => (
                        <TableRow
                          key={index}
                          sx={{ textAlign: "center" }}
                          hover>
                          <TableCell>
                            {currentEmployeePage * subEmployeePerPage +
                              index +
                              1}
                          </TableCell>
                          <TableCell>{event.subemp_staff_pin}</TableCell>
                          <TableCell>{event.subcon_company_name}</TableCell>
                          <TableCell>{event.subemp_contact_number}</TableCell>
                          <TableCell>{event.subemp_sia_number}</TableCell>
                          <TableCell>{event.subemp_sia_type}</TableCell>
                          <TableCell>{event.subemp_sia_expiry_date}</TableCell>
                          {/* <TableCell>{event.subemp_training_name}</TableCell> */}
                          <TableCell>
                            {event.subemp_certificate_number}
                          </TableCell>

                        <TableCell >

                        <IconButton 
onClick={() => handleDelete(event.id)}    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton>
</TableCell> 
                        </TableRow>
                      ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={filteredEmployeeData.length}
                rowsPerPage={subEmployeePerPage}
                page={currentEmployeePage}
                onPageChange={(event, newPage) =>
                  setCurrentEmployeePage(newPage)
                }
                onRowsPerPageChange={(event) => {
                  setSubEmployeePerPage(parseInt(event.target.value, 10));
                  setCurrentEmployeePage(0);
                }}
              />
            </Paper>
          </Box>
          {/* <div className='container'>
            <h3 className="mb-5">Deleted Sub-Employee</h3>
            <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select className="form-select d-inline-block w-auto mx-2">
                  {[10, 50, 100, 500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
                <BootstrapButton variant="primary" className="m-0">
                  Search
                </BootstrapButton>
                <input
                  type="text"
                  className="form-control ms-2"
                  // style={{ width: "200px" }}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>PIN</th>
                    <th>Name</th>
                    <th>Contact Number</th>
                    <th>SIA Number</th>
                    <th>SIA Type</th>
                    <th>SIA Expiry</th>
                    <th>Training Name</th>
                    <th>Certificate No</th>
                  <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="12" className="text-center">
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>Showing 0 to 0 of 0 entries</div>
          </div>
          </div> */}

          <Box
            sx={{
              width: "100%",
              mt: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Paper sx={{ width: "90%", mb: 2 }}>
              <h3 className="mb-5 mt-5">Deleted Sub-Employee</h3>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                  }}
                />
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<FileDownloadIcon />}
                  onClick={exportToDeleteExcel}>
                  Export to Excel
                </MuiButton>
              </Box>
              <TableContainer>
                <MuiTable
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Pin</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Name</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Contact Number
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Sia Number
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Sia Type</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Sia Expiry
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Training Name
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Certificate Number
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((employee, index) => (
                        <TableRow
                          key={index}
                          sx={{ textAlign: "center" }}
                          hover>
                          <TableCell>
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell>{employee.subemp_staff_pin}</TableCell>
                          <TableCell>{employee.subcon_company_name}</TableCell>
                          <TableCell>
                            {employee.subemp_contact_number}
                          </TableCell>
                          <TableCell>{employee.subemp_sia_number}</TableCell>
                          <TableCell>{employee.subemp_sia_type}</TableCell>
                          <TableCell>
                            {employee.subemp_sia_expiry_date}
                          </TableCell>
                          <TableCell>{employee.subemp_training_name}</TableCell>
                          <TableCell>
                            {employee.subemp_certificate_number}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>

          <Box
            sx={{
              width: "100%",
              mt: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Paper sx={{ width: "90%", mb: 2 }}>
              <h3 className="mb-10">SubContractor Contacts</h3>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  value={employeeSearchTerm}
                  onChange={(e) => setEmployeeSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                  }}
                />
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<FileDownloadIcon />}
                  onClick={exportToContactExcel}>
                  Export to Excel
                </MuiButton>
              </Box>
              <TableContainer>
                <MuiTable
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: "15px" }}>S.NO</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Name</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Department Number
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Contact Name
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Contact Number
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Fax</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subContractors
                      .slice(
                        currentEmployeePage * subEmployeePerPage,
                        currentEmployeePage * subEmployeePerPage +
                          subEmployeePerPage
                      )
                      .map((data, index) => (
                        <TableRow
                          key={index}
                          sx={{ textAlign: "center" }}
                          hover>
                          <TableCell>
                            {currentEmployeePage * subEmployeePerPage +
                              index +
                              1}
                          </TableCell>
                          <TableCell>{data.company_name}</TableCell>
                          <TableCell>{data.department_name}</TableCell>
                          <TableCell>{data.contact_name}</TableCell>
                          <TableCell>{data.contact_number}</TableCell>
                          <TableCell>{data.fax}</TableCell>
                          <TableCell>{data.email}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                count={filteredContact.length}
                rowsPerPage={subContactPerPage}
                page={currentContactPage}
                onPageChange={(event, newPage) =>
                  setCurrentContactPage(newPage)
                }
                onRowsPerPageChange={(event) => {
                  setSubContactPerPage(parseInt(event.target.value, 10));
                  setCurrentContactPage(0);
                }}
              />
            </Paper>
          </Box>
        </div>
      </section>
    </>
  );
};

export default SubViewDetails;
