import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table } from "react-bootstrap";

const ShowSelfEmployment = () => {

  
  const [employmentData, setEmploymentData] = useState([]);

  useEffect(() => {
    fetchEmploymentData();
  }, []);

  const fetchEmploymentData = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/show_self_employment',
         {employee_id:"1"}, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log('API response data:', response.data); // Add this line
      setEmploymentData(response.data.data);
    } catch (error) {
      console.error('Error fetching employment data:', error);
    }
  };
  return (
    <Container>
    <h2>Self Employment</h2>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Job Title</th>
          <th>Job Description</th>
          <th>Company Name</th>
          <th>Address</th>
          <th>Start Date</th>
          <th>End Date</th>

        </tr>
      </thead>
      <tbody>
        {Array.isArray(employmentData) && employmentData.length > 0 ? (
          employmentData.map((job, index) => (
            <tr key={index}>
              <td>{job.job_title}</td>
              <td>{job.job_description}</td>
              <td>{job.company_name}</td> 
              <td>{job.address}</td>
              <td>{job.start_date}</td>
              <td>{job.end_date }</td>

            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">No employment data available</td>
          </tr>
        )}
      </tbody>
    </Table>
  </Container>
  )
}

export default ShowSelfEmployment
