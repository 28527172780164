import React, { useState, useEffect } from "react";
import axios from "axios";

const Training = () => {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchTrainings();
  }, []);

  const fetchTrainings = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_trainings",
        { employee_id: "1" },

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API Response :",response)
      setTrainings(response.data.data);
      setLoading(false);
    } catch (err) {
      setError("Error fetching trainings");
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container">
      <h3>Training Information</h3>
      {trainings && trainings.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Training Title</th>
              <th>Custom Training Title</th>
              <th>Awarding Body</th>
              <th>Custom Awarding Body</th>
              <th>Award Date</th>
              <th>Expiry Date</th>
              <th>No Training So Far</th>
            </tr>
          </thead>
          <tbody>
            {trainings.map((training, index) => (
              <tr key={index}>
                <td>{training.training_title}</td>
                <td>{training.training_title_other}</td>
                <td>{training.awarding_bodies}</td>
                <td>{training.awarding_bodies_other}</td>
                <td>{training.award_date}</td>
                <td>{training.expiry_date}</td>
                <td>{training.no_training_far === "1" ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No training information available.</p>
      )}
    </div>
  );
};

export default Training;