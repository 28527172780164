import React from "react";
import Dashboard from "../../../Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import "./Subcontro.css";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import * as XLSX from 'xlsx';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const DormantSub = () => {

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Master', bold: false },
      { name: 'Subcontractors', bold: false },
      { name: 'Dormant Sub Employees', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>          
          </div>

      {/* <div className=" mt-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-flex align-items-center" id="flex1">
              <label htmlFor="show" className="me-3">
                Show
              </label>
              <select id="show" className="form-select-custom me-5">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              entries
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end" id="flex1">
              <label htmlFor="search" className="me-3" id="search">
                Search:
              </label>
              <input type="text" id="search" className="form-control-custom" />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4" id="container-box">
        <table className="table table-striped">
          <thead className="thead">
            <tr>
              <th>ID</th>
              <th>Company Name</th>
              <th>Sub-Employee Name</th>
              <th>SIA Number</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="9" className="text-center">
                No data available in table....
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="Delete-btn ">Delete</button> */}

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
              // InputProps={{
              //   startAdornment: <SearchIcon fontSize="small" />,
              // }}
            />
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button> */}
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '13px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Company Name</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Sub Employee Name</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>SIA Number</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Email</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Address</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Data Created</TableCell>
                  <TableCell sx={{ fontSize: '13px'}}>Actions</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
          
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredEmployees.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          /> */}
        </Paper>
      </Box>
    </>
  );
};

export default DormantSub;
