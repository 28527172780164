import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignIn.css";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";
import SGS_Logo from "../SignUp Page/media/logos/SGS-Logo-1-1024x387.webp";
import { Link } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState({});

  const validate = () => {
    const errors = {};
    if (!fullName) errors.fullName = "Full Name is Required";
    if (!email) {
      errors.email = "Email is Required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid Email";
    }
    if (!password) {
      errors.password = "Password is Required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!agreeTerms) {
      errors.agreeTerms = "You must accept the terms and conditions";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const payload = {
        name: fullName,
        email: email,
        password: password,
        c_password: confirmPassword,
        role_name: "Admin",
      };
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/register",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data.data);
      
        if (response.data.success) {
          const authToken = response.data.token;
          localStorage.setItem("token", authToken);
          Swal.fire({
            icon: 'success',
            title: 'Registration Successful',
            text: 'You will be redirected to the sign-in page.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/signin");
            }
          });
      
        } else {
          setErrors({ submit: response.data.message });
        }
      } catch (err) {
        console.error("Registration error:", err.response?.data || err.message);
        setErrors({
          submit:
            err.response?.data?.message ||
            "An error occurred during registration",
        });
      }
    }
  }
          
  return (
    <div className="body">
    <div className="auth-container">
    <div className="auth-welcome">
      <h2 style={{color:'white'}}>Welcome Back!</h2>
      {/* <p>To keep connected with us please login with your personal info</p> */}
      <button className="auth-switch-button" onClick={() => navigate("/signin")}>SIGN IN</button>
    </div>
    <div className="auth-form-container">
      <div className="auth-form">
        <img src={SGS_Logo} alt="SGS Logo" className="auth-logo" />
        <h2>Create Account</h2>
        {/* <div className="social-auth">
     <a href="https://www.instagram.com/azhagar.krish/"> <button className="social-btn"><i className="fab fa-instagram"></i></button></a>
          <button className="social-btn"><i className="fab fa-google"></i></button>
          <button className="social-btn"><i className="fab fa-facebook"></i></button>
        </div>
        <p className="auth-divider">or use your email for registration</p> */}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            className="auth-input"
            onChange={(e) => setFullName(e.target.value)}
          />
          {errors.fullName && <div className="error">{errors.fullName}</div>}
          <input
            type="email"
            placeholder="Email"
            className="auth-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <div className="error">{errors.email}</div>}
          <input
            type="password"
            placeholder="Password"
            className="auth-input"
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <div className="error">{errors.password}</div>}
          <input
            type="password"
            placeholder="Confirm Password"
            className="auth-input"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
          <div className="terms-checkbox">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
            />
            <label htmlFor="agreeTerms" style={{fontSize:'12px'}}>I agree to the Terms and Conditions</label>
          </div>
          {errors.agreeTerms && <div className="error">{errors.agreeTerms}</div>}
          {errors.submit && <div className="error">{errors.submit}</div>}
          <button type="submit" className="auth-button">SIGN UP</button>
        </form>
      </div>
    </div>
  </div>
  </div>

  );
};

export default SignUp;
