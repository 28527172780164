import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
const ProtectedRoute = () => {
  const isAuthenticated = !!localStorage.getItem('token');

  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;