import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Table,
  Modal,
  Spinner,
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import "./SiaType.css";

const DocumentTypes = () => {
  const [documentType, setDocumentType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [newDocumentType, setNewDocumentType] = useState("");

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  const fetchDocumentTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.post("https://srltd.megworld.in/api/document_show", {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data && response.data.success) {
        setTableData(response.data.data);
      } else {
        setError("Failed to fetch document types");
      }
    } catch (err) {
      setError("Error fetching document types: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddDocumentType = async () => {
    if (documentType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/document_store",
          {
            document_types: documentType,
            company_branch: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.success) {
          fetchDocumentTypes();
          setDocumentType("");
          Swal.fire({
            icon: 'success',
            title: 'Document Type Added',
            text: 'New Document type has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        } else {
          setError("Failed to add Document type");
        }
      } catch (err) {
        setError("Error adding Document type: " + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const response = await axios.post("https://srltd.megworld.in/api/document_store", {
            delete_id: id,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          if (response.data && response.data.success) {
            Swal.fire(
              'Deleted!',
              'The document type has been deleted.',
              'success'
            );
            fetchDocumentTypes();
          } else {
            setError("Failed to delete document type");
          }
        } catch (err) {
          setError("Error deleting document type: " + err.message);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setNewDocumentType(data.document_types);
    setEditModalShow(true);
  };

  const handleSaveEdit = async () => {
    if (newDocumentType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post("https://srltd.megworld.in/api/document_store", {
          id: editData.id,
          document_types: newDocumentType,
          company_branch: editData.company_branch,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.success) {
          setEditModalShow(false);
          Swal.fire({
            icon: 'success',
            title: 'Document Type Updated',
            text: 'The document type has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          fetchDocumentTypes();
        } else {
          setError("Failed to update document type");
        }
      } catch (err) {
        setError("Error updating document type: " + err.message);
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a new document type name.'
      });
    }
  };

  return (
    <div className="page-container">
      <div className="common-container">
        <h3>Document Types</h3>
        <InputGroup className="Group mb-3">
          <FormControl
            placeholder="Document Type"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddDocumentType} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "ADD"}
          </Button>
        </InputGroup>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Document Type</th>
              <th>Branch</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{data.document_types}</td>
                <td>{data.company_branch}</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    className="me-1"
                    onClick={() => handleEdit(data)}
                  >
                    <i className="fas fa-edit" style={{ color: "white" }}></i>
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(data.id)}
                  >
                    <i className="fas fa-trash" style={{ color: "white" }}></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Document Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formDocumentType">
            <Form.Label>Document Type Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new document type name"
              value={newDocumentType}
              onChange={(e) => setNewDocumentType(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentTypes;