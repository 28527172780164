import React, { useState, useEffect } from 'react';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link,useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import SGS_Logo from "../SignUp Page/media/logos/SGS-Logo-1-1024x387.webp";


const UserDetails = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [roleName, setRoleName] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_user_view",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          },
        }
      );
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await axios.post(
        "https://srltd.megworld.in/api/show_user_view",{id:id},
        {
          headers: {
            "Authorization": "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'User Deleted',
        text: 'User has been successfully deleted.',
      });
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error("Error deleting user:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the user.',
      });
    }
  };




  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const validate = () => {
    const errors = {};
    if (!fullName) errors.fullName = "Full Name is Required";
    if (!email) {
      errors.email = "Email is Required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid Email";
    }
    if (!password) {
      errors.password = "Password is Required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!roleName) {
      errors.roleName = "RoleName is Required";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const payload = {
        name: fullName,
        email: email,
        password: password,
        c_password: confirmPassword,
        role_name: roleName,
      };
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/register",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data.data);
      
        if (response.data.success) {
          const authToken = response.data.token;
          localStorage.setItem("token", authToken);
          Swal.fire({
            icon: 'success',
            title: 'Registration Successful',
            text: 'Registration Completed Successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          console.log("Role is :" ,response.data.role_name)
          localStorage.setItem("role", response.data.role_name);
        } else {
          setErrors({ submit: response.data.message });
        }
      } catch (err) {
        console.error("Registration error:", err.response?.data || err.message);
        setErrors({
          submit:
            err.response?.data?.message ||
            "An error occurred during registration",
        });
      }
    }
  }
    const [showUser,setShowUser] = useState(false);

    const handleShowUser = () => setShowUser(true);
    const handleCloseUser = () => setShowUser(false);
  
    const location = useLocation();

const getNavigationPath = () => {
  const pathParts = location.pathname.split('/').filter(Boolean);
  return [
    { name: 'Profile', bold: false },
    { name: 'User', bold: true },
  ];
};

const navigationPath = getNavigationPath();

const exportToExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet();
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "");
  XLSX.writeFile(workbook, "Events_data.xlsx");
}
  return (
    <>
    <Dashboard />
    <div
      className="toolbar py-2"
      id="kt_toolbar"
      style={{backgroundColor: '#BF0404' }}>
      <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
          <React.Fragment key={index}>
            <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
              {part.name}
            </span>
            {index < navigationPath.length - 1 && " / "}  
          </React.Fragment>
        ))}</h3>               
        

          <Box display="flex" justifyContent="flex-end" className="col-auto">
  <Button 
  onClick={handleShowUser}
  variant="contained" 
    sx={{ 
      backgroundColor: '#3f51b5', 
      color: 'white', 
      padding: '8px 10px',
      marginLeft:'400px',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#303f9f',
      }
    }}
  >
    ADD USER
  </Button>
</Box>
      </div>

      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>User Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Email</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Role Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(currentPage * usersPerPage, currentPage * usersPerPage + usersPerPage)
                  .map((user, index) => (
                  <TableRow key={user.id}  hover>
                    <TableCell>{currentPage * usersPerPage + index + 1}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role_name}</TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={() => handleDeleteUser(user.id)}
                        size="small" 
                        sx={{ 
                          color: 'white', 
                          backgroundColor: 'red', 
                          '&:hover': { backgroundColor: 'darkred' } 
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={usersPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setUsersPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
      
      <Modal show={showUser} onHide={handleCloseUser}>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>User Name</Form.Label>
            <Form.Control
              type="text"
              placeholder='user name'
              style={{ width: "100%" }}
              onChange={(e) => setFullName(e.target.value)}
            />
                      {errors.fullName && <div className="error" style={{fontSize:'13px'}}>{errors.fullName}</div>}

          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder='email'
              style={{ width: "100%" }}
              onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <div className="error" style={{fontSize:'13px'}}>{errors.email}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='password'
              style={{ width: "100%" }}
              onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <div className="error" style={{fontSize:'13px'}}>{errors.password}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='confirm password'
              style={{ width: "100%" }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {errors.confirmPassword && <div className="error" style={{fontSize:'13px'}}>{errors.confirmPassword}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ float: "left" }}>Role Name</Form.Label>
            <Form.Select
              as="select"
              style={{ width: "100%" }}
              onChange={(e) => setRoleName(e.target.value)}
              >
              {errors.roleName && <div className="error" style={{fontSize:'13px'}}>{errors.roleName}</div>}
              <option>Select Role Name...</option>
              <option>Client</option>
              <option>Officer</option>
              <option>Staff</option>
              <option>Accountant</option>
              <option>Admin</option>

            </Form.Select>
          </Form.Group>
    
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button   variant="contained"
  color="primary"
  onClick={handleSubmit}
>
          Add User
        </Button>
        <Button variant="contained"
  color="danger" onClick={handleCloseUser}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
      </>
  )
}

export default UserDetails;
