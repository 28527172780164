import React, { useState ,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useLocation } from "react-router-dom";
import { InputGroup } from 'react-bootstrap';


const AddSite = () => {
  const [formData, setFormData] = useState({
    site_client_name: "",
    site_group_name: "",
    site_name: "",
    site_contact_person: "",
    site_contact_number: "",
    site_trained_guard: false,
    site_address: "",
    site_post_code: "",
    site_bill_guarding: "",
    site_bill_supervisor: "",
    site_pay_guarding: "",
    site_pay_supervisor:"",
    site_note: "",
    site_status: "Active",
    company_branch: "1",
  });

  const navigate = useNavigate();


  const [site, setSite] = useState([]);
  
  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Client Name Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(client => ({
          value: client.client_name,
          label: client.client_name,
          charge_guarding: client.client_charge_guarding,
          charge_supervisor: client.client_charge_supervisor,
        }));
        setSite(options);
      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };

  const handleClientChange = (selectedOption) => {
    setFormData(prevState => ({
      ...prevState,
      site_client_name: selectedOption ? selectedOption.value : '',
      site_bill_guarding: selectedOption ? selectedOption.charge_guarding : '',
      site_bill_supervisor: selectedOption ? selectedOption.charge_supervisor : '',
    }));
  };

   
  const [siteGroups, setSiteGroups] = useState([]);
  
  useEffect(() => {
    fetchSiteGroupTypes();
  }, []);

  const fetchSiteGroupTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_site_group",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Site Group Response:", response);
      if (response.data && response.data.success) {
        const successSiteData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data[0]);
        const siteOptions = successSiteData.map(client => ({
          value: client.site_group_name,
          label: client.site_group_name,
        }))
        setSiteGroups(siteOptions);
        console.log("Site Group Options:", siteOptions);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };

  const handleSiteGroupChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      site_group_name: selectedOption ? selectedOption.value : '',
    }));
  };


  const handleSiaTypeChange = (selectedOption, { name }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_store",
        formData,
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Form submitted successfully:", response.data.data);
      if (response.data && response.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'Site Details Added',
        text: 'New Site has been added successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      navigate("/viewsite");
    }    
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  
  };

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Sites', bold: false },
      { name: 'Add Sites', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  const fetchClientRates = async (clientName) => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_rate_auto",
        { client_name: clientName },
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Bill Response:",response)
      if (response.data) {
        setFormData(prevState => ({
          ...prevState,
          site_bill_guarding: response.data.client_charge_guarding,
          site_bill_supervisor: response.data.client_charge_supervisor,
        }));
      }
    } catch (error) {
      console.error('Error fetching client rates:', error);
    }
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>                  
      </div>

      <div className="container mt-6">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
            <Form.Group className="mb-3" style={{ marginTop: '20px' }}>
  <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>
  <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
    Client Name
  </Form.Label>
  <Select  
    name="site_client_name"
    value={site.find(option => option.value === formData.site_client_name)}
    onChange={handleClientChange}
    options={site}
    isSearchable
    placeholder="Client Name..."
    styles={{
      control: (provided) => ({
        ...provided,
        width: '100%',
      }),
     
    }}
  />
</Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Site Group</Form.Label>
                <Select
                  name="site_group_name"
                  value={siteGroups.find(option => option.value === formData.site_group_name)}
                  onChange={handleSiteGroupChange}
                  options={siteGroups}
                  isSearchable
                  placeholder="Select Site Group..."
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                    }),
                   
                  }}                
            
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>Site Name</Form.Label>
                <Form.Control
                  type="text"
                  name="site_name"
                  value={formData.site_name}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  placeholder="Site Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
                  Contact Person
                </Form.Label>
                <Form.Control
                  type="text"
                  name="site_contact_person"
                  value={formData.site_contact_person}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  placeholder="Contact Person"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                  Contact Number
                </Form.Label>
                <Form.Control
                  type="text"
                  name="site_contact_number"
                  value={formData.site_contact_number}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  placeholder="Contact Number"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="site_address"
                  value={formData.site_address}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  placeholder="Site Address"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>
                  Trained Guards Required
                </Form.Label>
                <Form.Check
                style={{marginLeft:'10px'}}
                  type="checkbox"
                  label={<span style={{ marginLeft: '8px' }}>Yes</span>}
                                    name="site_trained_guard"
                  checked={formData.site_trained_guard}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left", marginTop: "20px" }}>
                  Post Code
                </Form.Label>
                <Form.Control
                  type="text"
                  name="site_post_code"
                  value={formData.site_post_code}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  placeholder="Post Code"
                />
              </Form.Group>
              <Form.Group className="mb-3">
  <Form.Label style={{ float: "left" }}>
    <span className="text-danger" style={{ fontSize: '18px', verticalAlign: 'middle' }}>*</span>
    Site Billable Rate (Guarding)
  </Form.Label>
  <div className="input-group">
    <span className="input-group-text">£</span>
    <Form.Control
      type="number"
      name="site_bill_guarding"
      value={formData.site_bill_guarding}
      onChange={handleChange}
      placeholder="0.00"
      step="0.01"
      min="0"
      required
    />
    <span className="input-group-text">.00</span>
  </div>
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label style={{ float: "left" }}>
    Site Payable Rate (Guarding)
  </Form.Label>
  <div className="input-group">
    <span className="input-group-text">£</span>
    <Form.Control
      type="number"
      name="site_pay_guarding"
      value={formData.site_pay_guarding}
      onChange={handleChange}
      placeholder="0.00"
      step="0.01"
      min="0"
    />
    <span className="input-group-text">.00</span>
  </div>
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label style={{ float: "left" }}>
    <span className="text-danger" style={{fontSize: '18px', verticalAlign: 'middle'}}>*</span>
    Site Billable Rate (Supervisor)
  </Form.Label>
  <div className="input-group">
    <span className="input-group-text">£</span>
    <Form.Control
      type="number"
      name="site_bill_supervisor"
      value={formData.site_bill_supervisor}
      onChange={handleChange}
      placeholder="0.00"
      step="0.01"
      min="0"
      required
    />
    <span className="input-group-text">.00</span>
  </div>
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label style={{ float: "left" }}>
    Site Payable Rate (Supervisor)
  </Form.Label>
  <div className="input-group">
    <span className="input-group-text">£</span>
    <Form.Control
      type="number"
      name="site_pay_supervisor"
      value={formData.site_pay_supervisor}
      onChange={handleChange}
      placeholder="0.00"
      step="0.01"
      min="0"
    />
    <span className="input-group-text">.00</span>
  </div>
</Form.Group>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Site Note</Form.Label>
                <Form.Control
                  as="textarea"
                  name="site_note"
                  value={formData.site_note}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  placeholder="Site Note"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
      </div>
    </>
  );
};

export default AddSite;
