import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import Dashboard from "../Dashboard/Dashboard";
import "./Roster.css";
import headerBackground from "../patterns/header-bg.png";
import { Link } from "react-router-dom";
import { useLocation ,useNavigate} from "react-router-dom";
import axios from "axios";
import Select from 'react-select';
import { Plus, X } from 'lucide-react';
import { format, parseISO  } from 'date-fns';
import Swal from 'sweetalert2';


const AddGuard = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    staff:"",
    shift_title:"",
    shift_client_name: "",
    shift_site_name: "",
    shift_assign_staff: "",
    shift_guard_group:"",
    shift_assign_sub:"",
    // shift_pay_rate:"",
    shift_assign_subemp:"",
    shift_pay_amount: "",
    shift_pay_expenses: "",
    shift_bill_rate: "",
    shift_bill_amount: "",
    shift_bill_expenses: "",
    shift_type: "",
    shift_penalty: "",
    shift_start_date: [],
    shift_end_date: [],
    shift_start_time: [],
    shift_end_time: [],
    shift_break: [],
    shift_check_call: false,
    shift_unpaid: false,
    shift_training: false,
    shift_confirm: false,
    // shift_completed:false,
    // shift_cancelled:false,  
    shift_unconfirm: false,
    shift_comments: "",
    shift_instructions: "",
    check_break:"false",
    company_branch: "1",
  });


  const [errors, setErrors] = useState({});

  const [shiftType, setShiftType] = useState('sameMultipleShifts');
  const [shifts, setShifts] = useState([{ shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '' }]);

  const handleShiftTypeChange = (e) => {
    setShiftType(e.target.value);
    if (e.target.value === 'differentMultipleShifts') {
      setShifts([{ shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '' }]);
    }
  };

  const handleShiftChange = (index, field, value) => {
    const newShifts = [...shifts];
    newShifts[index][field] = value;
    setShifts(newShifts);
  };


  
  const addShift = () => {
    setShifts([...shifts, { shift_start_date: '', shift_start_time: '', shift_end_time: '', shift_break: '' }]);
  };

  const removeShift = (index) => {
    const newShifts = shifts.filter((_, i) => i !== index);
    setShifts(newShifts);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    const requiredFields = [
      "shift_client_name",
      // "shift_title",
      "shift_site_name",
      // "shift_type",
      // "staff",
      // "shift_comments",
      // "shift_instructions",
    ];
  
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });
  
    if (shiftType === 'sameMultipleShifts') {
      if (!formData.shift_start_date || !formData.shift_end_date || !formData.shift_start_time || !formData.shift_end_time) {
        newErrors.shift_dates = "All shift dates and times are required";
      }
    } else if (shiftType === 'differentMultipleShifts') {
      if (shifts.some(shift => !shift.shift_start_date || !shift.shift_start_time || !shift.shift_end_time)) {
        newErrors.shifts = "All shift dates and times are required for different multiple shifts";
      }
    }
  
    // if (formData.staff === 'Employee' && (!formData.shift_assign_staff || formData.shift_assign_staff.length === 0)) {
    //   newErrors.shift_assign_staff = "Please select at least one employee";
    // }
  
    // if (formData.staff === 'Subcontractor' && (!formData.shift_assign_sub || formData.shift_assign_sub.length === 0)) {
    //   newErrors.shift_assign_sub = "Please select at least one subcontractor";
    // }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        let shiftData = { ...formData };

        if (shiftType === 'sameMultipleShifts') {
          // Create arrays for the date range
          const startDate = new Date(formData.shift_start_date);
          const endDate = new Date(formData.shift_end_date);
          const dateArray = [];
          for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            dateArray.push(new Date(date).toISOString().split('T')[0]);
          }

          shiftData.shift_start_date = dateArray;
          shiftData.shift_end_date = dateArray;
          shiftData.shift_start_time = new Array(dateArray.length).fill(formData.shift_start_time);
          shiftData.shift_end_time = new Array(dateArray.length).fill(formData.shift_end_time);
          shiftData.shift_break = new Array(dateArray.length).fill(formData.shift_break);
        } else if (shiftType === 'differentMultipleShifts') {
          shiftData.shift_start_date = shifts.map(shift => shift.shift_start_date);
          shiftData.shift_start_time = shifts.map(shift => shift.shift_start_time);
          shiftData.shift_end_time = shifts.map(shift => shift.shift_end_time);
          shiftData.shift_break = shifts.map(shift => shift.shift_break);
          shiftData.shift_end_date = shiftData.shift_start_date; 
        }

        const response = await axios.post(
          "https://srltd.megworld.in/api/add_shift_new",
          shiftData,
          {
            headers: {
              Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
              "Content-Type": "application/json",
            },
          }
        );
        
        console.log("Add Shift Response:", response);
        if (response.data && response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Shift Added',
            text: 'Shift has been added successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          navigate('/clientroster');
        }
      } catch (error) {
        console.error("Error adding shift:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while adding the shift. Please try again.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Form Validation',
        text: 'Please correct the errors in the form before submitting.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    }
  };

 
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Roster', bold: false },
      { name: 'Add Single Guard Shift', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  const [clientName,setClientName] = useState([])
const [siteName,setSiteName] = useState([])


  useEffect(() => {
    fetchSiaTypes();
  }, []);

  const fetchSiaTypes = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/client_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.client_name,
          label: type.client_name,
        }
      ));
 
    setClientName(options);
        console.log("Client Options:", options);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };

  useEffect(() => {
    fetchSite();
  }, []);

  const fetchSite = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/site_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b6525",
          },
        }
      );
      console.log("Response:", response.data);
      if (response.data && response.data.success) {
        const successData = Array.isArray(response.data.success) ? response.data.success : Object.values(response.data.data);
        const options = successData.map(type => ({
          value: type.site_name,
            label: type.site_name,
        }
      ));
 
      setSiteName(options);
        console.log("Site Options:", options);    

      } else {
        console.error('Failed to fetch Site types');
      }
    } catch (error) {
      console.error('Error fetching Site types:', error);
    }
  };
  const handleSiaTypeChange = (selectedOption, { name }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  
  const [employees, setEmployees] = useState([]);
  // const [selectedEmployees, setSelectedEmployees] = useState([]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_emp_auto',
        {},
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      setEmployees(response.data);
      
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };


  // const handleSiaTypeChange = (selectedOption, { name }) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: selectedOption ? selectedOption.value : '',
  //   }));
  // };

  const [subcontractors, setSubcontractors] = useState([]);
  // const [selectedSubcontractors, setSelectedSubcontractors] = useState([]);

  useEffect(() => {
    fetchSubcontractors();
  }, []);

  const fetchSubcontractors = async () => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_sub_auto',
        {},
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      setSubcontractors(response.data);
    } catch (error) {
      console.error('Error fetching subcontractors:', error);
    }
  };

  const handleSubChange = (selectedOption, { name }) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };
 
  // const handleSelectChange = (selectedOption, { name }) => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: selectedOption ? selectedOption.value : '',
  //   }));
  // };

  const [subEmployees, setSubEmployees] = useState([]);
  // const [selectedSubEmployees, setSelectedSubEmployees] = useState([]);

  useEffect(() => {
    fetchSubEmployees();
  }, []);

  const fetchSubEmployees = async (id) => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/shift_subemp_auto',
        {id:1},  
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      console.log("Sub employee auto :",response)
      setSubEmployees(response.data);
    } catch (error) {
      console.error('Error fetching sub employees:', error);
    }
  };

  const handleSubEmpChange = (selectedOption, { name }) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };
  // const handleSubChange = (selectedOption, { name }) => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: selectedOption ? selectedOption.value : '',
  //   }));
  // };

  const [guardGroup, setGuardGroup] = useState([]);
  // const [selectedGuardGroup, setSelectedGuardGroup] = useState([]);

  useEffect(() => {
    fetchGuardGroup();
  }, []);

  const fetchGuardGroup = async (id) => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/guard_group_show',
        {id:id},  
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      console.log("Guard Group auto :",response)
      const formattedGuardGroups = response.data.data.map(group => ({
        value: group.guard_group,
        label: group.guard_group
      }));
      
      setGuardGroup(formattedGuardGroups);     
    } catch (error) {
      console.error('Error fetching Guard Group:', error);
    }
  };

  const handleGuardGroupChange = (selectedOption, { name }) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };
  // const handleSubEmpChange = (selectedOption, { name }) => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: selectedOption ? selectedOption.value : '',
  //   }));
  // };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <div className="toolbar-content">
          <h3 className="text-white fw-bolder mb-2" style={{fontSize:'15px'}}>
            Add Single Guard Shift(s)
          </h3>
        </div>
       
      </div>

      <div className="container mt-4">
        <Form>
          <Row className="mb-3">
          <Form.Group as={Col} md={6} controlId="clientName">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>Client Name</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                name="shift_client_name"
                value={clientName.find(option => option.value === formData.shift_client_name)}
                onChange={handleSiaTypeChange}
                options={clientName}
                isSearchable
                placeholder="Select Client Name..."
                            isInvalid={!!errors.shift_client_name}
          
              />
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="shiftTitle">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>Shift Title</Form.Label>
              <Form.Control
              name="shift_title"
                value={formData.shift_title}
                onChange={handleInputChange}
                placeholder="Enter Shift Title..."
                            isInvalid={!!errors.shift_title}
          
              />
            </Form.Group>
            {/* <Form.Group as={Col} md={3} controlId="payableRates"></Form.Group>
            <Form.Group as={Col} md={3} controlId="staffRate">
              <Form.Label style={{ float: "left" }} className="text-warning">
                Payable Rates
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Staff Rate</option>
                <option>Site Rate</option>
              </Form.Select>
            </Form.Group> */}
            {/* <Form.Group as={Col} md={3} controlId="staffRateSelected">
              <Form.Label style={{ float: "left" }}>
                Staff Rate Selected
              </Form.Label>
              <Form.Control
                placeholder="Staff Rate Selected"
                style={{ width: "100%" }}
                type="text"
                readOnly
              />
            </Form.Group> */}
          </Row>

          <Row className="mb-3">
          <Form.Group as={Col} md={6} controlId="siteName">
              <Form.Label style={{ marginBottom: '10px', display: 'block',float:'left' }}>Site Name</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}                 name="shift_site_name"
                value={siteName.find(option => option.value === formData.shift_site_name)}
                onChange={handleSiaTypeChange}
                options={siteName}
                isSearchable
                placeholder="Select Site Name..."
                isInvalid={!!errors.shift_site_name}
                
              />
            </Form.Group>
            {/* <Form.Group as={Col} md={3} controlId="amount"></Form.Group> */}

            <Form.Group as={Col} md={6} controlId="payableRate">
              <Form.Label style={{ float: "left" }} className="text-warning">
                Amount
              </Form.Label>
              <Form.Control
              name="shift_pay_amount"
              value={formData.shift_pay_amount}
              isInvalid={!!errors.shift_pay_amount}
              onChange={handleInputChange}
                placeholder="Payable Rate"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
        
          </Row>

          {/* <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="staff">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select style={{ width: "100%" }} name="staff"
                value={formData.staff}
                onChange={handleInputChange}>
                <option>Select Staff</option>
                <option>Employee</option>
                <option>Trained Employees</option>
                <option>Subcontractors</option>
                <option>Trained Subcontractors</option>
                <option>Unassigned</option>
              </Form.Select>
            </Form.Group> */}
            <Row>
            {/* <Form.Group as={Col} md={6} controlId="billableAmount"></Form.Group> */}
            <Form.Group as={Col} md={6} controlId="payableRates"></Form.Group>
<Form.Group as={Col} md={6} controlId="payableExpensesInput">
              <Form.Label style={{ float: "left" }} className="text-warning">
                Payable Expenses
              </Form.Label>
              <Form.Control
               name="shift_pay_expenses"
               value={formData.shift_pay_expenses}
               onChange={handleInputChange}
               isInvalid={!!errors.shift_pay_expenses}
                placeholder="Payable Expenses"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
            </Row>

        <Row>
            <Form.Group as={Col} md={6} controlId="payableRates"></Form.Group>
            <Form.Group as={Col} md={6} controlId="billableRates">
              <Form.Label
                style={{ float: "left" }}
                className="text-success mt-3"
              >
                Billable Rates
              </Form.Label>
              <Form.Select style={{ width: "100%" }}  name="shift_bill_rate"
              value={formData.shift_bill_rate}
              onChange={handleInputChange}
              isInvalid={!!errors.shift_bill_rate}>
                <option>Site Guard</option>
                <option>Site Supervisor</option>
              </Form.Select>
            </Form.Group>
            </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="billableAmount"></Form.Group>
            <Form.Group as={Col} md={6} controlId="billableExpenses">
              <Form.Label style={{ float: "left" }} className="text-success">
                Amount
              </Form.Label>
              <Form.Control
               name="shift_bill_amount"
               value={formData.shift_bill_amount}
               onChange={handleInputChange}
               isInvalid={!!errors.shift_bill_amount}
                placeholder="Site Billlable Rate"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="billableAmount"></Form.Group>
            <Form.Group as={Col} md={6} controlId="billableExpenses">
              <Form.Label
                style={{ float: "left" }}
                className="text-success mt-3"
              >
                Billable Expenses
              </Form.Label>
              <Form.Control
               name="shift_bill_expenses"
               value={formData.shift_bill_expenses}
               onChange={handleInputChange}
               isInvalid={!!errors.shift_bill_expenses}
                placeholder="Billable Expenses"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 mt-8">
        

            <Form.Group as={Col} md={6} controlId="shiftType">
              <Form.Label
                style={{ float: "left" }}
                className="mt-3">
                Shift Type
              </Form.Label>
              <Form.Control
                   name="shift_type"
                   value={formData.shift_type}
                   onChange={handleInputChange}
                   isInvalid={!!errors.shift_type}
                placeholder="Shift Type"
                style={{ width: "100%" }}
                type="text"
              />
            </Form.Group>
            <Col md={6}>
              <Form.Label className="text-danger" style={{ float: "left" }}>
                Penalty / Deduction
              </Form.Label>
              <Form.Control
                name="shift_penalty"
                value={formData.shift_penalty}
                onChange={handleInputChange}
                isInvalid={!!errors.shift_penalty}
                style={{ width: "100%" }}
                type="text"
                placeholder="Penalty / Deduction"
              />
            </Col>
          </Row>
          <hr style={{borderTop: '2px solid #e0e0e0', margin: '20px 0',marginTop:'50px'}} />

          <div className="">
            <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="staff">
            <Form.Label style={{ marginBottom: '10px', display: 'block', float: 'left' }}>Staff</Form.Label>
            <Form.Select
              name="staff"
              value={formData.staff}
              onChange={handleInputChange}
              isInvalid={!!errors.staff}
            >
              <option>Select Staff...</option>
              <option>Employee</option>
              <option>Subcontractor</option>
            </Form.Select>
          </Form.Group>
        </Col>

        
        
      </Row>

      {formData.staff === 'Employee' && (
        <>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="guardGroup">
              <Form.Label style={{ float: "left" }}>Guard Group</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isSearchable
                name="shift_guard_group"
  options={guardGroup} // This should now be an array of objects with value and label
  // value={selectedGuardGroup}
  value={guardGroup.find(option => option.value === formData.shift_guard_group)}
  onChange={handleGuardGroupChange}
                placeholder="Select..."
              />
            </Form.Group>
          </Col>

         
          </Row>

<Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="employees">
              <Form.Label style={{ float: "left" }}>Employees</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isSearchable
                name="shift_assign_staff"
          options={employees}
          // value={selectedEmployees}
          value={employees.find(option => option.value === formData.shift_assign_staff)}

          onChange={handleSelectChange}
                placeholder="Select..."
              />
            </Form.Group>
          </Col>
        
            </Row>
          </>
      )}

      {formData.staff === 'Subcontractor' && (
        <>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="subcontractor">
              <Form.Label style={{ float: "left" }}>Subcontractor</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isSearchable
                name="shift_assign_sub"
                options={subcontractors}
                // value={selectedSubcontractors}
                value={subcontractors.find(option => option.value === formData.shift_assign_sub)}

                onChange={handleSubChange}                placeholder="Select..."
              />
            </Form.Group>
          </Col>
     
          </Row>
          <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="subEmployees">
              <Form.Label style={{ float: "left" }}>Sub Employees</Form.Label>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                isSearchable
                name="shift_assign_subemp"
                options={subEmployees}
                // value={selectedSubEmployees}
                value={subEmployees.find(option => option.value === formData.shift_assign_subemp)}

                onChange={handleSubEmpChange}                placeholder="Select..."
              />
            </Form.Group>
          </Col>

          
         
        </Row>

          
          </>
      )}

            </div>

          <Form className="p-4 bg-white rounded shadow-sm mt-20">
      <Row className="mb-3">
      <Col>
          <Form.Check
            inline
            type="radio"
            label="Same Multiple Shifts"
            name="shift_add_type "
            id="sameMultipleShifts"
            value="sameMultipleShifts"
            checked={shiftType === 'sameMultipleShifts'}
            onChange={handleShiftTypeChange}
          />
          <Form.Check
            inline
            type="radio"
            label="Different Multiple Shifts"
            name="shift_add_type "
            id="differentMultipleShifts"
            value="differentMultipleShifts"
            checked={shiftType === 'differentMultipleShifts'}
            onChange={handleShiftTypeChange}
          />
        </Col>
      </Row>
      {shiftType === 'sameMultipleShifts' && (
          <>
      <Row className="mb-3 mt-5">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Shift Dates</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control type="date" placeholder="Start date" name="shift_start_date" value={formData.shift_start_date} onChange={handleInputChange}/>
              <span className="mx-2">to</span>
              <Form.Control type="date" placeholder="End date" name="shift_end_date" value={formData.shift_end_date} onChange={handleInputChange}/>
            </div>
          </Form.Group>
        </Col>
      
        <Col md={6}>
  <Row className="mb-4">
    <Col md={6}>
      <Form.Check
        type="checkbox"
        label="Unpaid Shift"
        id="unpaidShift"
        name="shift_unpaid"
        value={formData.shift_unpaid}
        
      />
    </Col>
    <Col md={6}>
      <Form.Check
        type="checkbox"
        label="Training Shift"
        id="trainingShift"
        name="shift_training"
        value={formData.shift_training}

      />
    </Col>
  </Row>
  <Row className="mb-4">
    <Col md={6}>
      <Form.Check
        type="radio"
        label="Confirm Shift"
        id="confirmShift"
        name="shift_confirm"
        value={formData.shift_confirm}

      />
    </Col>
    <Col md={6}>
      <Form.Check
        type="radio"
        label="Unconfirm Shift"
        id="unconfirmShift"
        name="shift_confirm"
        value={formData.shift_confirm}

        defaultChecked
      />
    </Col>
  </Row>
  <Row>
    <Col>
      <Form.Check
        type="checkbox"
        label="PO Number Received?"
        id="poNumberReceived"
        name="shift_unpaid"
        value={formData.shift_unpaid}

      />
    </Col>
  </Row>
</Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <Form.Group>
            <Form.Label>Shift Start Time</Form.Label>
            <Form.Control type="time" name="shift_start_time" value={formData.shift_start_time} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>Shift End Time</Form.Label>
            <Form.Control type="time" name="shift_end_time" value={formData.shift_end_time} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" name="shift_comments" value={formData.shift_comments} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        <Row className="mb-3">
        <Col md={3}>
          <Form.Check
            type="checkbox"
            label="Add Break to Shift?"
            id="addBreakToShift"
            name="check_break"
            checked={formData.check_break === "true"}
            onChange={(e) => handleInputChange({
              target: {
                name: 'check_break',
                value: e.target.checked ? "true" : "false"
              }
            })}
          />
        </Col>
        {formData.check_break === "true" && (
          <>
          <Col md={3}>
            <Form.Group controlId="breakTime">
              <Form.Label style={{float:'left'}}>Break Time</Form.Label>
              <Form.Select
                value={formData.shift_break}
                name="shift_break"
                onChange={handleInputChange}
              >
                <option value="">Please select...</option>
                <option value="30">30 minutes</option>
                <option value="60">60 minutes</option>
              </Form.Select>
            </Form.Group>
          </Col>
          
          </>
        )}
      </Row>
      <Col md={6}>
          <Form.Check
          className="mt-5"
            type="checkbox"
            label="Add Check Calls to Shift?"
            id="addCheckCallsToShift"
            name="shift_check_call"
            value={formData.shift_check_call}
          />
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Shift Instructions</Form.Label>
              <Form.Control as="textarea" name="shift_instructions" value={formData.shift_instructions} onChange={handleInputChange}/>
            </Form.Group>
          </Col>
      </Row>
      </>
      )}
      
      {shiftType === 'differentMultipleShifts' && (
          <>
            {shifts.map((shift, index) => (
 <Row key={index} className="mb-3 align-items-end">
 <Col md={2}>
   <Form.Label style={{float:'left'}}>Shift Date</Form.Label>
   <Form.Control
  type="date"
  value={shift.shift_start_date}
  name={`shift_start_date[${index}]`}
  onChange={(e) => handleShiftChange(index, 'shift_start_date', e.target.value)}
/>
 </Col>
 <Col md={2}>
   <Form.Label style={{float:'left'}}>Start Time</Form.Label>
   <Form.Control
     type="time"
     value={shift.shift_start_time}
     name="shift_start_time"
     onChange={(e) => handleShiftChange(index, 'shift_start_time', e.target.value)}
   />
 </Col>
 <Col md={2}>
   <Form.Label style={{float:'left'}}>End Time</Form.Label>
   <Form.Control
     type="time"
     value={shift.shift_end_time}
     name="shift_end_time"
     onChange={(e) => handleShiftChange(index, 'shift_end_time', e.target.value)}
   />
 </Col>
 <Col md={2} >
   <Form.Label style={{float:'left'}}>Break Time</Form.Label>
   <Form.Select
     value={shift.shift_break}
     name="shift_break"
     onChange={(e) => handleShiftChange(index, 'shift_break', e.target.value)}
   >
     <option value="">please select...</option>
     <option value="30">30 minutes</option>
     <option value="60">60 minutes</option>
   </Form.Select>
 </Col>                <Col md={3}>
                  {index === 0 ? (
                    <Button variant="success" onClick={addShift} className="me-2">
                      <Plus size={20} />
                    </Button>
                  ) : (
                    <Button variant="danger" onClick={() => removeShift(index)}>
                      <X size={20} />
                    </Button>
                  )}
                </Col>

                
              </Row>
              
            ))}

<Row className="mt-10">
  
        <Col md={6}>
          <Form.Check
            type="checkbox"
            label="Add Break to Shift?"
            id="addBreakToShift"
            name="shift_break"
          />
          <Form.Check
          className="mt-5"
            type="checkbox"
            label="Add Check Calls to Shift?"
            id="addCheckCallsToShift"
            name="shift_check_call"
            value={formData.shift_check_call}
          />
        </Col>      

          
        <Col md={6}>
  <Row className="mb-4">
    <Col md={6}>
      <Form.Check
        type="checkbox"
        label="Unpaid Shift"
        id="unpaidShift"
        name="shift_unpaid"
        value={formData.shift_unpaid}
      />
    </Col>
    <Col md={6}>
      <Form.Check
        type="checkbox"
        label="Training Shift"
        id="trainingShift"
        name="shift_training"
        value={formData.shift_training}
      />
    </Col>
  </Row>
  <Row className="mb-4">
    <Col md={6}>
      <Form.Check
        type="radio"
        label="Confirm Shift"
        id="confirmShift"
        name="shift_confirm"
        value={formData.shift_confirm}
      />
    </Col>
    <Col md={6}>
      <Form.Check
        type="radio"
        label="Unconfirm Shift"
        id="unconfirmShift"
        name="shift_confirm"
        value={formData.shift_confirm}
        defaultChecked
      />
    </Col>
  </Row>
  <Row>
    <Col>
      <Form.Check
        type="checkbox"
        label="PO Number Received?"
        id="poNumberReceived"
      />
    </Col>
  </Row>
</Col>
            {/* <Col md={6}></Col> */}
        <Col md={6}>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" name="shift_comments" value={formData.shift_comments} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        <Col md={6}></Col>
          <Col md={6}>
          <Form.Group>
            <Form.Label>Shift Instructions</Form.Label>
            <Form.Control as="textarea"   name="shift_instructions" value={formData.shift_instructions} onChange={handleInputChange}/>
          </Form.Group>
        </Col>
        </Row>
          </>
        )}

      {/* <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Col>
      </Row> */}

      {/* <Row className="mb-3">
       
      </Row> */}

      <Row>
        <Col className="text-end">
          <Button variant="success" style={{ backgroundColor: '#4ad991', border: 'none' }} onClick={handleSubmit}>SAVE</Button>
        </Col>
      </Row>
    </Form>

         
        </Form>
      </div>
    </>
  );
};

export default AddGuard;
