import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Nav, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dashboard from "../../Dashboard/Dashboard";
import BasicInfo from "./BasicInfo"; // Import your BasicInfo component here
import PayrollInfo from "./PayrollInfo";
import AddressInfo from "./AddressInfo";
import TrainingInfo from "./TrainingInfo";
import SIAKnowledge from "./SIAKnowledge";
import PersionalReference from "./PersionalReference";
import Occupational from "./Occupational";
import PolicyDeclare from "./PolicyDeclare";
import GDPRNotice from "./GDPRNotice";
import Previous from "./Previous";
import { useLocation,useNavigate } from "react-router-dom";

const EmployeeForm = () => {
  const [activeTab, setActiveTab] = useState("Basic Information"); 

  const tabNames = [
    "Basic Information",
    "Payroll Information",
    "Addresses",
    "Training",
    "Previous Work History",
    "SIA Knowledge Questionnaire",
    "Personal References",
    "Occupational Health Section",
    "GDPR Notice",
    "Policy and Declaration",
  ];

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "Basic Information":
        return <BasicInfo />;
      case "Payroll Information":
        return <PayrollInfo />;
      case "Addresses":
        return <AddressInfo />;
      case "Training":
        return <TrainingInfo />;
      case "Previous Work History":
        return <Previous />;
      case "SIA Knowledge Questionnaire":
        return <SIAKnowledge />;
      case "Personal References":
        return <PersionalReference />;
      case "Occupational Health Section":
        return <Occupational />;
      case "GDPR Notice":
        return <GDPRNotice />;
      case "Policy and Declaration":
        return <PolicyDeclare />;
      default:
        return null;
    }
  };
  const location = useLocation();
  const navigate = useNavigate();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    const breadcrumbPaths = [
      { name: 'Master', bold: false },
      { name: 'Employee', bold: false },
      { name: 'View', path:'/view', bold: false },
      { name: 'Employee Basic Information', path: location.pathname, bold: true }
    ];

    return breadcrumbPaths.map((part, index) => ({
      ...part,
      bold: index === breadcrumbPaths.length - 1
    }));
  };

  const navigationPath = getNavigationPath();

  const handleClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
      
        <div className="">
        <h3 className="text-white" style={{ fontSize: '15px' }}>
      {navigationPath.map((part, index) => (
        <React.Fragment key={index}>
          <Link
            to={part.path}
            onClick={(e) => handleClick(part.path, e)}
            style={{
              fontWeight: part.bold ? 'bold' : 'normal',
              color: 'white',
              fontSize: part.bold ? '18px' : '15px',
              textDecoration: 'none'
            }}
          >
            {part.name}
          </Link>
          {index < navigationPath.length - 1 && " / "}
        </React.Fragment>
      ))}
    </h3>     
        </div>
       
      </div>
      <Container fluid className="px-0">
        <Row className="mx-0">
          <Col md={10} className="main-content">
            <Row>
              <Col md={3} className="form-sections">
                <Nav className="flex-column">
                  {tabNames.map((tabName, index) => (
                    <Nav.Link
                      key={index}
                      onClick={() => setActiveTab(tabName)}
                      active={activeTab === tabName}
                    >
                      {tabName}
                    </Nav.Link>
                  ))}
                </Nav>
              </Col>
              <Col md={9} className="form-content">
                {/* <h2>Mark Denial - {activeTab}</h2> */}
                {renderActiveComponent()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeeForm;
