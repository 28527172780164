import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import Dashboard from "../../../Dashboard/Dashboard";
import { Navbar, Nav } from "react-bootstrap";
import "./GeneralSetting.css";
import SiaType from "./SiaType";
import Nationalities from "./Nationalities";
import VisaType from "./VisaTypes";
import TrainingTypes from "./TrainingTypes";
import AwardingBodies from "./AwardingBodies";
import DocumentTypes from "./DocumentTypes";
import EventTypes from "./EventTypes";
import Threatlevel from "./Threatlevel";
import {useLocation} from "react-router-dom";

const GeneralSetting = () => {
  const [activePage, setActivePage] = useState("SiaType");

  const navbarItems = [
    "SiaType",
    "Nationalities",
    "VisaType",
    "TrainingTypes",
    "AwardingBodies",
    "DocumentTypes",
    "EventTypes",
    "Threatlevel",
  ];

  const renderActivePage = () => {
    switch (activePage) {
      case "SiaType":
        return <SiaType />;
      case "Nationalities":
        return <Nationalities />;
      case "VisaType":
        return <VisaType />;
      case "TrainingTypes":
        return <TrainingTypes />;
      case "AwardingBodies":
        return <AwardingBodies />;
      case "DocumentTypes":
        return <DocumentTypes />;
      case "EventTypes":
        return <EventTypes />;
      case "Threatlevel":
        return <Threatlevel />;

      default:
        return null;
    }
  };

  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Settings', bold: false },
      { name: 'Workforce', bold: false },
      { name: 'Employees', bold: false },
      { name: 'General Settings', bold: true },

    ];
  };
  
  const navigationPath = getNavigationPath();


  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>  
      </div>

      <div className="navbar-container">
        <Navbar bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {navbarItems.map((item, index) => (
                <Nav.Link
                  key={index}
                  href="#"
                  className={`navbar-link ${
                    activePage === item ? "active" : ""
                  }`}
                  onClick={() => setActivePage(item)}
                >
                  {item}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="page-content">{renderActivePage()}</div>
    </>
  );
};

export default GeneralSetting;
