import React,{useState} from 'react'
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Nav, Form, Button } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';

const SelfEmployment = () => {

  const [formData, setFormData] = useState({
    employee_id: "1",
    job_title: "",
    job_description: "",
    company_name: "",
    address: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://srltd.megworld.in/api/add_self_employment', formData, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log('Data submitted successfully:', response);
      if(response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'Self Employment Added',
          text: 'Self Employment has been Added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      // You can add an error message here
    }
  };
  const formGroupStyle = {
    marginBottom: "20px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#4a4a4a",
    float: "left",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };
  return (
    <Form onSubmit={handleSubmit}>
              
    <Row>
      <Col md={10} className="mt-10">
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Job Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Job Title"
            style={inputStyle}
            name="job_title"
            value={formData.job_title}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>
            Job Description
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Job Description"
            style={inputStyle}
            name="job_description"
            value={formData.job_description}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>

      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Company Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Company Name"
            style={inputStyle}
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Employer Address"
            style={inputStyle}
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>Start Date</Form.Label>
          <Form.Control
            type="date"
            style={inputStyle}
            name="start_date "
            value={formData.start_date }
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group style={formGroupStyle}>
          <Form.Label style={labelStyle}>End date</Form.Label>
          <Form.Control
            type="date"
            style={inputStyle}
            name="end_date "
            value={formData.end_date }
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
    </Row>
    <Button
    onClick={handleSubmit}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "dodgerblue",
                  color: "white",
                  border: "0",
                  borderRadius: "5px",
                }}
              >
                SAVE
              </Button>
              <Button
                style={{
                  padding: "10px 20px",
                  backgroundColor: "Green",
                  color: "white",
                  border: "0",
                  borderRadius: "5px",
                  marginLeft: "5px",
                }}
              >
                NEXT
              </Button>
  </Form>
  )
}

export default SelfEmployment
