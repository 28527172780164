import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';

const Occupational = () => {
  const [agreed, setAgreed] = useState(false);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);

  const handleCheckboxChange = (e) => {
    setAgreed(e.target.checked);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (agreed && !description.trim()) {
      setError('Please provide a description.');
      return;
    }

    const payload = {
      employee_id: "1", 
      name: agreed ? "1" : "0",
      description: description
    };

    try {
      const response = await axios.post('https://srltd.megworld.in/api/add_health_section', payload, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      
      console.log('Data submitted successfully:', response);
      Swal.fire({
        icon: 'success',
        title: 'Occupational Data Updated',
        text: 'Occupational Data has been updated successfully.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      setDescription("");
      setAgreed(false);
    } catch (error) {
      console.error('Error submitting data:', error);
      setError('Error submitting data. Please try again.');
    }
  };

  return (
    <Container className="px-0">
      <Row className="mx-0">
        <Col md={10} className="main-content">
          <Row>
            <Col md={20} className="form-content">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Disclosure: The following question may be found
                    inappropriate and disturbing.
                  </Form.Label>
                  <Form.Check 
                    type="checkbox" 
                    label="Agree"
                    checked={agreed}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>

                {agreed && (
                  <Form.Group className="mb-3">
                    <Form.Label style={{float:'left'}}>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={handleDescriptionChange}
                      placeholder="Enter your description here"
                    />
                  </Form.Group>
                )}

                <div className="mt-3">
                  <Button
                    type="submit"
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "dodgerblue",
                      color: "white",
                      border: "0",
                      borderRadius: "5px",
                    }}
                  >
                    SUBMIT
                  </Button>
                </div>
                {error && <div className="mt-3 text-danger">{error}</div>}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Occupational;