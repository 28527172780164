import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import Dashboard from "../../Dashboard/Dashboard";
import { Link } from "react-router-dom";

const ViewPayment = () => {
  const [employeeData, setEmployeeData] = useState({
    branch: "Head Office",
    employee: "lione",
    totalDue: 53.8,
    returnAmount: "",
    dueDate: "",
    comments: "",
  });

  const [payments, setPayments] = useState([
    {
      type: "Payment",
      date: "03-01-2024",
      amount: 56.0,
      comments: "urgent pay",
    },
    { type: "Return", date: "04-01-2024", amount: 1.1, comments: "Paid" },
    { type: "Return", date: "04-01-2024", amount: 1.1, comments: "Paid" },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData({ ...employeeData, [name]: value });
  };

  const totalRemainingDue =
    employeeData.totalDue -
    payments.reduce(
      (sum, payment) =>
        payment.type === "Return" ? sum + payment.amount : sum,
      0
    );

  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-3"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white fw-bolder text-center"style={{fontSize:'15px'}}>Accounts / Payroll / <Link to="/advancepayment" style={{color:'white'}}> Advance Payment</Link> /
          Advance Payment Management
        </h3>
        <div className="text-center">
          <Link to="/advancepayment">
            <button
              style={{
                padding: "10px 20px",
                marginLeft: "650px",
                backgroundColor: "deepskyblue",
                border: "0",
                borderRadius: "5px",
                color: "white",
              }}
            >
              BACK
            </button>
          </Link>
        </div>
      </div>
      <div className="container mt-5">
        <Row className="justify-content-center">
          <Col md={15}>
            <Form>
              {Object.entries(employeeData).map(([key, value]) => (
                <Form.Group as={Row} className="mb-3 mt-5" key={key}>
                  <Form.Label column sm={3} className="text-end">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Form.Label>
                  <Col sm={9}>
                    {key === "comments" ? (
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <Form.Control
                        type={key === "dueDate" ? "date" : "text"}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                        readOnly={["branch", "employee", "totalDue"].includes(
                          key
                        )}
                      />
                    )}
                  </Col>
                </Form.Group>
              ))}
            </Form>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col md={7}>
            <Table striped bordered hover style={{ marginLeft: "50px" }}>
              <thead>
                <tr>
                  <th>Payment Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        style={{ padding: "10px" }}
                        className={`badge ${
                          payment.type === "Payment"
                            ? "bg-danger"
                            : "bg-success"
                        }`}
                      >
                        {payment.type}
                      </span>
                    </td>
                    <td>{payment.date}</td>
                    <td>£{payment.amount.toFixed(2)}</td>
                    <td>{payment.comments}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="d-flex justify-content-end mt-3">
              <strong>Total Remaining Due: </strong>
              <span
                className="badge bg-info ms-2"
                style={{ padding: "10px", marginTop: "-8px" }}
              >
                £{totalRemainingDue.toFixed(2)}
              </span>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md={8} className="text-center">
            <button
              style={{
                color: "white",
                backgroundColor: "green",
                border: "0",
                padding: "10px 100px",
                borderRadius: "5px",
                fontSize: "15px",
                marginRight: "200px",
              }}
            >
              Return Payment
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewPayment;
