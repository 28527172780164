import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table } from "react-bootstrap";

const PersonalRef = () => {
  const [employmentData, setEmploymentData] = useState([]);

  useEffect(() => {
    fetchEmploymentData();
  }, []);

  const fetchEmploymentData = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/show_personal_reference',
         {employee_id:"1"}, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log('API response data:', response); 
      setEmploymentData(response.data.data);
    } catch (error) {
      console.error('Error fetching employment data:', error);
    }
  };
  return (
    <Container>
    <h2>Persional Reference</h2>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Reference Name</th>
          <th>Reference Name 2</th>
          <th>Contact Number</th>
          <th>Contact Email</th>
          <th>Profession</th>
          <th>Relationship</th>
          <th>Reference Address</th>
          <th>Do above person know you for last 2 years ?</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(employmentData) && employmentData.length > 0 ? (
          employmentData.map((job, index) => (
            <tr key={index}>
              <td>{job.reference_name}</td>
              <td>{job.reference_name_1}</td>
              <td>{job.contact_number}</td>
              <td>{job.contact_email}</td>
              <td>{job.profession}</td>
              <td>{job.relationship}</td>
              <td>{job.referee_address}</td>
              <td>{job.last_2_years}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">No employment data available</td>
          </tr>
        )}
      </tbody>
    </Table>
  </Container>
  );
};

export default PersonalRef;
