import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../Dashboard/assets/media/logos/logo-light.svg";
import logoDefault from "../Dashboard/assets/media/logos/logo-default.svg";
import headerBackground from "../patterns/header-bg.png";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Button, Form } from "react-bootstrap";
import "./Dashboard.css";
import { useAuth } from "../SignIn/ProtectedRoute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faGauge,
  faCalendarDays,
  faPhone,
  faUserShield,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { Offcanvas, NavItem, NavLink, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SGS_LogoImg from "../Dashboard/assets/media/logos/SGS_LogoImg.png";
import profile_preview_img from "../Dashboard/assets/media/logos/profile preview img.jpg";
import "typeface-roboto";
import NavigationPath from "./Navigation";
import Swal from "sweetalert2";
import { RollerShadesSharp } from "@mui/icons-material";

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenus, setActiveSubMenus] = useState(null);
  const [showEmployeeDropdown, setShowEmployeeDropdown] = useState(false);
  const [showSubcontractorDropdown, setShowSubcontractorDropdown] =
    useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 858);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 858);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // if(role_name == 'admin'){
  const menuItems = [
    {
      title: "DASHBOARD",
      icon: "fa-solid fa-gauge",
      path: "/dashboardpage",
      role: ["Admin", "Accountant"],
    },
    {
      title: "EMPLOYEE",
      icon: "fa-solid fa-users",
      role: ["Admin"],
      subMenu: [
        {
          title: "Payroll",
          subMenu: [
            { title: "Add", path: "/add" },
            { title: "View", path: "/view" },
            { title: "Trash", path: "/trashemployee" },
            { title: "Dormant Staff", path: "/dormantstaff" },
            { title: "Guard Group", path: "/guard" },
            {
              title: "Holiday",
              subMenu: [
                { title: "Staff Holidays", path: "/holiday" },
                {
                  title: "Holidays Monthly Calendar",
                  path: "/monthlycalendar",
                },
                { title: "Current Holidays Request", path: "/holidayrequest" },
              ],
            },
            {
              title: "Hr",
              subMenu: [
                { title: "Online Applications", path: "/onlineapp" },
                { title: "Documents", path: "/hrdocuments" },
              ],
            },
            { title: "Import Employee", path: "/exportemployee" },
          ],
        },
        {
          title: "Subcontractors",
          subMenu: [
            { title: "Add", path: "/subadd" },
            { title: "View", path: "/subview" },
            { title: "Trash", path: "/trash" },
            { title: "Dormant Sub Staff", path: "/dormantsub" },
            // { title: "Un approved sub staff", path: "/unapproved" },
          ],
        },
      ],
    },
    {
      title: "CLIENTS",
      icon: "fa-solid fa-building",
      role: ["Admin", "Client"],
      subMenu: [
        { title: "Add Clients ", path: "/addclient" },
        { title: "View Clients", path: "/viewclient" },
        { title: "Trash Clients", path: "/trashclient" },
      ],
    },
    {
      title: "SITES",
      icon: "fa-solid fa-map-location-dot",
      role: ["Admin", "Client"],
      subMenu: [
        { title: "Add Site", path: "/addsite" },
        { title: "View Site", path: "/viewsite" },
        { title: "Trash Site", path: "/trashsite" },
        { title: "Dormant Site", path: "/dormantsite" },
      ],
    },
    {
      title: "ROSTER",
      icon: "fa-solid fa-calendar-days",
      role: ["Admin", "Accountant", "Client"],
      subMenu: [
        { title: "Add Shifts", path: "/addshift" },
        { title: "Client Roster", path: "/clientroster" },
        { title: "Staff Roster", path: "/staffroster" },
        { title: "Sub staff Roster", path: "/substaffroster" },
        // { title: "Multiple edits", path: "/multipleedit" },
        // { title: "Site card" },
        // { title: "Shift Requests" },
      ],
    },

    {
      title: "EVENTS",
      icon: "fa-solid fa-calendar-check",
      role: ["Admin"],
      subMenu: [
        { title: "Events", path: "/events" },
        { title: "Call out", path: "/callout" },
      ],
    },
    {
      title: "ACCOUNTS",
      icon: "fa-solid fa-coins",
      role: ["Admin", "Accountant"],
      subMenu: [
        {
          title: "Invoice",
          subMenu: [
            { title: "Manage Invoices", path: "/manageinvoice" },
            { title: "Manage Payments", path: "/managepayments" },
            { title: "Credit Notes", path: "/creditnotes" },
          ],
        },
        {
          title: "Payroll",
          subMenu: [
            { title: "Manage payroll", path: "/managepayroll" },
            { title: "Manage Payments", path: "/payrollpayments" },
            { title: "Advance Payments", path: "/advancepayment" },
          ],
        },
      ],
    },
    {
      title: "REPORTS",
      icon: "fa-solid fa-chart-bar",
      role: ["Admin", "Accountant", "Staff"],
      subMenu: [
        {
          title: "Work Force Reports",
          subMenu: [
            { title: "Staff List", path: "/stafflist" },
            { title: "Application From", path: "/applicationform" },
            // { title: "Disclaimer Report" ,path:"/disclaimerreport"},
            { title: "SubContracts List", path: "/subcontractslist" },
            {
              title: "Client Roster Employee List",
              path: "/clientrosteremployeelist",
            },
            // { title: "New Employee Form",path:"/newemployeeform"},
            // { title: "Site Distance  to Employees", path:"/sitedistance" },
          ],
        },
        {
          title: "Client and Site Reports",
          subMenu: [
            // { title: "Client Details",path:"/clientdetails"},
            { title: "Client List", path: "/clientlist" },
            // { title: "Site Details" ,path:"/SiteDetailsReport"},
            { title: "Site List", path: "/sitelist" },
          ],
        },
        {
          title: "Roster Reports",
          subMenu: [
            { title: "Employee/Client Roster", path: "/employeeclientroster" },
            { title: "Lateness Report", path: "/latenessreport" },
            { title: "SubContracts Report", path: "/subcontractorreport" },
            {
              title: "Master Client Roster Report",
              path: "/masterclientrosterreport",
            },
            { title: "Site Roster", path: "/siteroster" },
            { title: "Shift Status Report", path: "/shiftstatusreport" },
            // { title: "Weekly Time Sheet",path:"/weeklytimesheet" },
            { title: "Weekly Roster View", path: "/weeklyrosterview" },
            // { title: "Master Roster Report",path:"/masterrosterreport"},
            // { title: "Contracted Hours Report",path:"/contractorhoursreport"},
          ],
        },
        {
          title: "Timestamp Reports",
          subMenu: [
            // {title:"Chase Up History Report",path:"/chaseuphistory"},
            { title: "Timestamp Report", path: "/timestampreport" },
          ],
        },
        {
          title: "Finance Reports(Invoice)",
          subMenu: [
            { title: "Client Invoice", path: "/clientinvoice" },
            {
              title: "Client Invoice Payments",
              path: "/clientinvoicepayments",
            },
            { title: "Site Wise Invoice", path: "/sitewiseinvoice" },
            { title: "Subcontractor Invoice", path: "/subcontractorinvoice" },
            { title: "Missing Shifts", path: "/missingshift" },
            { title: "Site Shifts Report", path: "/siteshiftreport" },
            { title: "Profit & Loss Statment", path: "/profit" },
            {
              title: "Timestamp Out Invoice Report",
              path: "/timestampinvoicereport",
            },
            { title: "Total Number of Hours", path: "/totalnumber" },
            { title: "Client Hours Analysis", path: "/clienthoursanalysis" },
          ],
        },
        {
          title: "Finance Reports(Payroll)",
          subMenu: [
            { title: "Employee Wise Payroll", path: "/employeewisepayroll" },
            // {title:"All Employees Payroll",path:"/allemployeepayroll"},
            {
              title: "Paid / Unpaid Shift & Holidays",
              path: "/painunpaidshift",
            },
            {
              title: "Employee Site Wise Hours",
              path: "/employeesitewisehours",
            },
            { title: "Time Sheet", path: "/timesheet" },
            {
              title: "Master Payroll & Invoice Report",
              path: "/masterpayrollinvoicereport",
            },
            // {title:"Payment Claculation",path:"/paymentcalculation"},
            {
              title: "Subcontractor Hours Report",
              path: "/subcontractorhoursreport",
            },
          ],
        },
        {
          title: "HR Reports",
          subMenu: [
            { title: "Employees Sia Expiry", path: "/employeesiaexpiry" },
            // {title:"Sia License Check",path:"/sialicensecheck"},
            {
              title: "Suncontractor Sia Expiry",
              path: "/subcontractorsiaexpiry",
            },
            { title: "Employee Visa Expiry", path: "/employeevisaexpiry" },
            { title: "Hr Document Report", path: "/htdocumentreport" },
            {
              title: "Sub Employee Document Report",
              path: "/subemployeedocumentreport",
            },
            {
              title: "Screening and Vetting Report",
              path: "/screeningandvettingreport",
            },
          ],
        },
        { title: "Event Reports", path: "/eventsreport" },
        { title: "Log Reports", path: "/logreport" },
      ],
    },
    {
      title: "SETTINGS",
      icon: "fa-solid fa-gear",
      role: ["Admin"],
      subMenu: [
        { title: "Company", path: "/companydetails" },
        // {
        //   title: "Workforce",

        // },
        { title: "Client", path: "/clientsettings" },
        {
          title: "Employees",
          subMenu: [
            { title: "General Settings", path: "/generalsetting" },
            // { title: "HR" },
            { title: "Staff Web Portal" },
            { title: "Staff Mobile App" },
          ],
        },
        {
          title: "Subcontractor",
          subMenu: [{ title: "Sub Staff Approval", path: "/subcontractors" }],
        },
        // { title: "Roster" ,path:"/rostersettings"},
        // { title: "Timestamp" ,path:"/callsettings"},
        // { title: "Finance" ,path:"/financesettings"},
        // { title: "Data Reports",path:"/datareports" },
      ],
    },

    {
      title: "TIMESTAMP",
      icon: "fa-solid fa-clock",
      role: ["Admin", "Accountant", "Staff"],
      subMenu: [
        { title: "Click to Attendance", path: "/timestamp" },
        { title: "Attendance Report", path: "/attendancereport" },
      ],
    },

    {
      title: "SCREENING",
      icon: "fa-solid fa-user-shield",
      role: ["Admin"],
      onClick: () => handleShowScreening(),
    },
    {
      title: "PROFILE",
      icon: "fa-solid fa-user",
      role: ["Admin", "Staff", "Client", "Accountant"],
      subMenu: [
        { title: "User", path: "/userdetails" },
        { title: "SignOut", onClick: () => handleSignOut() },
      ],
    },
    { title: "FAQ", icon: "fa-solid fa-question-circle", role: ["Admin"] },

    {
      title: "NOTIFICATIONS",
      icon: "fa-solid fa-bell",
      role: ["Admin", "Staff", "Client", "Accountant"],
    },
  ];
  // }else if (role_name == 'accountant' ){

  // }

  const [loggedinrole, SetLoggedinrole] = useState("");

  useEffect(() => {
    const stringrole = localStorage.getItem("role");
    // if (stringrole){
    //   const role = JSON.parse(stringrole)
    SetLoggedinrole(stringrole);
    // }
  }, []);

  const hasaccessItem = (role) => {
    console.log({ loggedinrole });
    return role.some((item) => item === loggedinrole);
  };

  const mobileFooterItems = [
    { title: "Dashboard", icon: faGauge, path: "/dashboardpage" },
    { title: "Roster", icon: faCalendarDays, path: "/addshift" },
    { title: "Timestamp", icon: faClock, path: "/timestamp" },
    {
      title: "Screening",
      icon: faUserShield,
      onClick: () => handleShowScreening(),
    },
  ];

  const handleMenuClick = (item) => {
    if (item.path) {
      navigate(item.path);
    } else if (item.title === "Screening") {
      handleShowScreening();
    }
  };

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  const [showClientReport, setShowClientReport] = useState(false);
  const [showScreening, setShowScreening] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showApplication, setShowApplication] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showSubcontractors, setShowSubcontractors] = useState(false);
  const [showClientRoster, setShowClientRoster] = useState(false);
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showDistance, setShowDistance] = useState(false);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [showClientList, setShowClientList] = useState(false);
  const [showSiteDetails, setShowSiteDetails] = useState(false);
  const [showSiteList, setShowSiteList] = useState(false);
  const [showEmployeeRosterForm, setShowEmployeeRosterForm] = useState(false);
  const [showLatness, setShowLatness] = useState(false);
  const [showSubcontractorReport, setShowSubcontractorReport] = useState(false);
  const [showSiteRoster, setShowSiteRoster] = useState(false);
  const [showShiftReport, setShowShiftReport] = useState(false);
  const [showWeekTime, setShowWeekTime] = useState(false);
  const [showWeekRoster, setShowWeekRoster] = useState(false);
  const [showMasterRoster, setShowMasterRoster] = useState(false);
  const [showContracted, setShowContracted] = useState(false);
  const [showChaseReport, setShowChaseReport] = useState(false);
  const [showTimestampReport, setShowTimestampReport] = useState(false);

  const handleShowTimestampReport = () => setShowTimestampReport(true);
  const handleCloseTimestampReport = () => setShowTimestampReport(false);

  const handleShowChaseReport = () => setShowChaseReport(true);
  const handleCloseChaseReport = () => setShowChaseReport(false);

  const handleShowContracted = () => setShowContracted(true);
  const handleCloseContracted = () => setShowContracted(false);

  const handleShowMasterRoster = () => setShowMasterRoster(true);
  const handleCloseMasterRoster = () => setShowMasterRoster(false);

  const handleShowWeekRoster = () => setShowWeekRoster(true);
  const handleCloseWeekRoster = () => setShowWeekRoster(false);

  const handleShowWeekTime = () => setShowWeekTime(true);
  const handleCloseWeekTime = () => setShowWeekTime(false);

  const handleShowShiftReport = () => setShowShiftReport(true);
  const handleCloseShiftReport = () => setShowShiftReport(false);

  const handleShowSiteRoster = () => setShowSiteRoster(true);
  const handleCloseSiteRoster = () => setShowSiteRoster(false);

  const handleShowSubcontractorReport = () => setShowSubcontractorReport(true);
  const handleCloseSubcontractorReport = () =>
    setShowSubcontractorReport(false);

  const handleShowLatness = () => setShowLatness(true);
  const handleCloseLatness = () => setShowLatness(false);

  const handleShowEmployeeRosterForm = () => setShowEmployeeRosterForm(true);
  const handleCloseEmployeeRosterForm = () => setShowEmployeeRosterForm(false);

  const handleShowSiteList = () => setShowSiteList(true);
  const handleCloseSiteList = () => setShowSiteList(false);

  const handleShowSiteDetails = () => setShowSiteDetails(true);
  const handleCloseSiteDetails = () => setShowSiteDetails(false);

  const handleShowClientList = () => setShowClientList(true);
  const handleCloseClientList = () => setShowClientList(false);

  const handleShowClientDetails = () => setShowClientDetails(true);
  const handleCloseClientDetails = () => setShowClientDetails(false);

  const handleShowDistance = () => setShowDistance(true);
  const handleCloseDistance = () => setShowDistance(false);

  const handleShowEmployeeForm = () => setShowEmployeeForm(true);
  const handleCloseEmployeeForm = () => setShowEmployeeForm(false);

  const handleShowClientRoster = () => setShowClientRoster(true);
  const handleCloseClientRoster = () => setShowClientRoster(false);

  const handleShowSubcontractors = () => setShowSubcontractors(true);
  const handleCloseSubcontractors = () => setShowSubcontractors(false);

  const handleShowDisclaimer = () => setShowDisclaimer(true);
  const handleCloseDisclaimer = () => setShowDisclaimer(false);

  const handleShowApplication = () => setShowApplication(true);
  const handleCloseApplication = () => setShowApplication(false);

  const handleShowStaff = () => setShowStaff(true);
  const handleCloseStaff = () => setShowStaff(false);

  const handleShowScreening = () => setShowScreening(true);
  const handleCloseScreening = () => setShowScreening(false);

  const handleShowClientReport = () => setShowClientReport(true);
  const handleCloseClientReport = () => setShowClientReport(false);

  const handleSubMenuClick = (e, item) => {
    e.stopPropagation();
    if (item.path) {
      navigate(item.path);
    } else if (item.onClick) {
      item.onClick();
    }
  };
  const handleSignOut = () => {
    localStorage.removeItem("token");
    Swal.fire({
      icon: "success",
      title: "Logout successful",
      text: "You will be redirected to the sign in.",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
    navigate("/signin");
  };

  const handleMenuHover = (menuTitle) => {
    setActiveMenu(menuTitle);
    setActiveSubMenus([]);
    setShowEmployeeDropdown(false);
    setShowSubcontractorDropdown(false);
  };

  const handleSubMenuHover = (subMenuPath) => {
    setActiveSubMenus(subMenuPath);
  };

  const renderSubMenu = (items, currentPath = [], level = 0) => {
    if (!items) return null;

    return (
      <ul className={`submenu level-${level}`}>
        {items.map((item, index) => {
          const itemPath = [...currentPath, item.title];
          const isActive =
            activeSubMenus.length >= itemPath.length &&
            itemPath.every((item, i) => item === activeSubMenus[i]);

          return (
            <li
              key={index}
              onMouseEnter={() => handleSubMenuHover(itemPath)}
              onClick={(e) => {
                handleSubMenuClick(e, item);
              }}>
              <span style={{ fontWeight: "bold" }}>
                {level === 0 && (
                  <i
                    className="fas fa-caret-right me-2"
                    style={{ color: "black" }}></i>
                )}
                {level === 1 && (
                  <i
                    className="fas fa-circle-arrow-right me-2"
                    style={{ color: "black" }}></i>
                )}
                {level >= 2 && (
                  <i
                    className="fas fa-long-arrow-alt-right me-2"
                    style={{ color: "black" }}></i>
                )}
                {item.title}
              </span>{" "}
              {isActive &&
                item.subMenu &&
                renderSubMenu(item.subMenu, itemPath, level + 1)}
              {isActive && item.sunMenu && (
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    onClick={() => {
                      if (item.title === "Employees") {
                        setShowEmployeeDropdown(!showEmployeeDropdown);
                        setShowSubcontractorDropdown(false);
                      } else if (item.title === "Subcontractor") {
                        setShowSubcontractorDropdown(
                          !showSubcontractorDropdown
                        );
                        setShowEmployeeDropdown(false);
                      }
                    }}>
                    ▼
                  </button>
                  {(item.title === "Employees" && showEmployeeDropdown) ||
                  (item.title === "Subcontractor" &&
                    showSubcontractorDropdown) ? (
                    <div className="dropdown-menu show">
                      {item.sunMenu.map((sunItem, index) => (
                        <a
                          style={{ marginLeft: "0" }}
                          key={index}
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            if (sunItem.title === "General Settings") {
                              navigate("/generalsetting");
                            } else if (sunItem.title === "Sub Staff Approval") {
                              navigate("/subcontractors");
                            }
                          }}>
                          <i
                            className="fas fa-circle-arrow-right me-2"
                            style={{ color: "black" }}></i>

                          {sunItem.title}
                        </a>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const customFontFace = `
  @font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  }
`;

  const Sidebar = ({ menuItems, showSidebar, toggleSidebar }) => {
    const [activeKeys, setActiveKeys] = useState([]);

    const toggleAccordion = (eventKey) => {
      setActiveKeys((prevKeys) =>
        prevKeys.includes(eventKey)
          ? prevKeys.filter((key) => key !== eventKey)
          : [...prevKeys, eventKey]
      );
    };

    const renderMenuItems = (items, level = 0) => {
      return items.map((item, index) => (
        <NavItem key={`${level}-${index}`}>
          {item.subMenu ? (
            <Accordion activeKey={activeKeys}>
              <Accordion.Item eventKey={`${level}-${index}`}>
                <Accordion.Header
                  onClick={() => toggleAccordion(`${level}-${index}`)}>
                  <i className={`${item.icon} me-2`}></i>
                  {item.title}
                </Accordion.Header>
                <Accordion.Body>
                  <Nav className="flex-column">
                    {renderMenuItems(item.subMenu, level + 1)}
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : item.sunMenu ? (
            <Accordion activeKey={activeKeys}>
              <Accordion.Item eventKey={`${level}-${index}`}>
                <Accordion.Header
                  onClick={() => toggleAccordion(`${level}-${index}`)}>
                  <i className={`${item.icon} me-2`}></i>
                  {item.title}
                </Accordion.Header>
                <Accordion.Body>
                  <Nav className="flex-column">
                    {item.sunMenu.map((sunItem, sunIndex) => (
                      <NavLink
                        key={`${level}-${index}-${sunIndex}`}
                        href={sunItem.path}
                        className="d-flex align-items-center">
                        {sunItem.title}
                      </NavLink>
                    ))}
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <NavLink href={item.path} className="d-flex align-items-center">
              {item.icon && <i className={`${item.icon} me-2`}></i>}
              {item.title}
            </NavLink>
          )}
        </NavItem>
      ));
    };

    return (
      <Offcanvas
        show={showSidebar}
        onHide={toggleSidebar}
        placement="start"
        className="w-75 w-sm-50">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">{renderMenuItems(menuItems)}</Nav>
        </Offcanvas.Body>
      </Offcanvas>
    );
  };

  return (
    <>
      <Modal show={showScreening} onHide={handleCloseScreening}>
        <Modal.Header closeButton>
          <Modal.Title>Screening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "red", fontSize: "15px" }}>
            Restricted to Authorise Users Only
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseScreening}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApplication} onHide={handleCloseApplication}>
        <Modal.Header closeButton>
          <Modal.Title>Application Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex mb-3">
              <Form.Check
                type="radio"
                id="activeStaff"
                label="Active Staff"
                className="me-3"
              />
              <Form.Check
                type="radio"
                id="onlineApplicants"
                label="Online Applicant's (Submitted)"
              />
            </div>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Guard Group</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Guard Group...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Staff...</option>
                <option>Deenl</option>
                <option>Deenl</option>
                <option>lione</option>
                <option>Mark H</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="secondary" onClick={handleCloseApplication}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDistance} onHide={handleCloseDistance}>
        <Modal.Header closeButton>
          <Modal.Title>Site Distance To Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Client...</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>All Site Group</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Site Name</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Site Name</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Distance Range</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>With In Range</option>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
                <option>35</option>
                <option>40</option>
                <option>45</option>
                <option>50</option>
                <option>55</option>
                <option>60</option>
                <option>65</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="secondary" onClick={handleCloseDistance}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showStaff} onHide={handleCloseStaff}>
        <Modal.Header closeButton>
          <Modal.Title>Staff List (Current,Applicants,Deleted)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Guard Group</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Guard Group...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Report Type</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Active Staff List</option>
                <option>Online Applicants(Application Submitted)</option>
                <option>Online Applicants(Application Not Submitted)</option>
                <option>Deleted Staff List</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>
                Visa Requirments
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>All Staff</option>
                <option>Visa Required Staff</option>
                <option>Not Visa Required Staff</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Order By</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>By Name</option>
                <option>By Joining Date/Submission Date</option>
                <option>By DOB</option>
                <option>By SIA Expiry</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>
                Driving License Type
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select License Type</option>
                <option>Full</option>
                <option>Provisional</option>
                <option>None</option>
              </Form.Select>{" "}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="secondary" onClick={handleCloseStaff}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSubcontractors} onHide={handleCloseSubcontractors}>
        <Modal.Header closeButton>
          <Modal.Title>Subcontractors List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Report Type</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Type...</option>
                <option>Active Subcontractors</option>
                <option>Deleted Subcontractors</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseSubcontractors}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDisclaimer} onHide={handleCloseDisclaimer}>
        <Modal.Header closeButton>
          <Modal.Title>Disclaimer Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Guard Group</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Guard Group...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Staff...</option>
                <option>Deenl</option>
                <option>Deenl</option>
                <option>lione</option>
                <option>Mark H</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                type="date"
                placeholder="Start Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                type="date"
                placeholder="End Date"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="secondary" onClick={handleCloseDisclaimer}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showClientRoster} onHide={handleCloseClientRoster}>
        <Modal.Header closeButton>
          <Modal.Title>Client Roster Employee's List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Client</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Client...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Status</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Status...</option>
                <option>Active Staff</option>
                <option>Inactive Staff</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                type="date"
                placeholder="Start Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control
                style={{ width: "100%" }}
                type="date"
                placeholder="End Date"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseClientRoster}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEmployeeForm} onHide={handleCloseEmployeeForm}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Staff...</option>
                <option>lione </option>
                <option>Mark H </option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="secondary" onClick={handleCloseEmployeeForm}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showClientReport} onHide={handleCloseClientReport}>
        <Modal.Header closeButton>
          <Modal.Title>Client Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Clients...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Site Group...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control
                type="Date"
                style={{ width: "100%" }}></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" style={{ width: "100%" }} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Order By
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Date & Time</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
            <h4 style={{ color: "red", marginTop: "40px" }}>
              Print Report with the following information
            </h4>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Company information (INDEL Settings)"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Staff PIN Number" />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Guard Name" />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Staff Contact Number" />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="SIA Information" />
            </Form.Group>
            <h4 style={{ color: "red", marginTop: "20px" }}>
              Extended Option(will Display only in excel report)
            </h4>
            <Form.Group>
              <Form.Check type="checkbox" label="Employee Email ID" />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Employee EDBS" />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Employee Address" />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Post Code" />
            </Form.Group>
            <Form.Group className="mb-3 mt-5">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Shift with Remarks
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseClientReport}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showClientDetails} onHide={handleCloseClientDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Client Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Clients...</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseClientDetails}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showClientList} onHide={handleCloseClientList}>
        <Modal.Header closeButton>
          <Modal.Title>Client List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Client Status</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Active Clients</option>
                <option>Deleted Clients</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Search By VAT</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Active Clients</option>
                <option>VAT Registered</option>
                <option>Not Registered</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>
                Search By Invoice Term
              </Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Invoice Term...</option>
                <option>Weekly Invoice</option>
                <option>Fortnightly Invoice</option>
                <option>Monthly Invoice</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseClientList}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSiteDetails} onHide={handleCloseSiteDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Site Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Clients...</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Site Group</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Sites</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Sites...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseSiteDetails}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSiteList} onHide={handleCloseSiteList}>
        <Modal.Header closeButton>
          <Modal.Title>Site List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Sites Status</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Status...</option>
                <option>Active Sites</option>
                <option>Deleted Sites</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseSiteList}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEmployeeRosterForm}
        onHide={handleCloseEmployeeRosterForm}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Client Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Guard Groups</Form.Label>
              <Form.Select as="select">
                <option>All Guard groups</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formEmployees">
              <Form.Label style={{ float: "left" }}>Employees</Form.Label>
              <Form.Select as="select">
                <option>All Employee</option>
                <option>lione</option>
                <option>Mark H</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>All Clients</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formStartDate">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Select type="date" placeholder="Start Date" />
            </Form.Group>

            <Form.Group controlId="formEndDate">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" placeholder="End Date" />
            </Form.Group>

            <Form.Group controlId="formOrderBy">
              <Form.Label style={{ float: "left" }}>Order By</Form.Label>
              <Form.Select as="select">
                <option>By Date & Time</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Send Report Via Email?" />
            </Form.Group>
            <h4 style={{ color: "red", marginTop: "20px" }}>
              Print Report with the following information
            </h4>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Company information (INDEL Settings)"
              />
            </Form.Group>

            <Form.Group>
              <Form.Check type="checkbox" label="Staff PIN number." />
            </Form.Group>

            <Form.Group>
              <Form.Check type="checkbox" label="Guard name." />
            </Form.Group>

            <Form.Group>
              <Form.Check type="checkbox" label="Staff contact number." />
            </Form.Group>

            <Form.Group>
              <Form.Check type="checkbox" label="SIA information." />
            </Form.Group>

            <Form.Group>
              <Form.Check type="checkbox" label="SIN" />
            </Form.Group>

            <Form.Group>
              <Form.Check type="checkbox" label="Shift With Remarks." />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseEmployeeRosterForm}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLatness} onHide={handleCloseLatness}>
        <Modal.Header closeButton>
          <Modal.Title>Shifts Latness Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Clients...</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Site Groups</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Groups...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Sites</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Sites...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select style={{ width: "100%" }}>
                <option>Select Staff...</option>
                <option>Employee</option>
                <option>Subcontractor</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control style={{ width: "100%" }} type="date" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control style={{ width: "100%" }} type="date" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check style={{}}></Form.Check>
              <Form.Label style={{ float: "left", marginRight: 70 }}>
                Send Report Via Email ?
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseLatness}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubcontractorReport}
        onHide={handleCloseSubcontractorReport}>
        <Modal.Header closeButton>
          <Modal.Title>Subcontractors Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>Select Clients...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formEmployees">
              <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
              <Form.Select as="select">
                <option>Select Site Group...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Subcontractors</Form.Label>
              <Form.Select as="select">
                <option>All Subcontractors</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Sub Employee</Form.Label>
              <Form.Select as="select">
                <option>All Sub Employee</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formStartDate">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Select type="date" placeholder="Start Date" />
            </Form.Group>

            <Form.Group controlId="formEndDate">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" placeholder="End Date" />
            </Form.Group>

            <Form.Group controlId="formOrderBy">
              <Form.Label style={{ float: "left" }}>Order By</Form.Label>
              <Form.Select as="select">
                <option>Select Sorting</option>
                <option>By Date & Time</option>
                <option>By Guard Name</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Send Report Via Email?" />
            </Form.Group>
            <h4 style={{ color: "red", marginTop: "20px" }}>
              Print Report with the following information
            </h4>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Company information (INDEL Settings)"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check type="checkbox" label="Show Client Name" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseSubcontractorReport}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSiteRoster} onHide={handleCloseSiteRoster}>
        <Modal.Header closeButton>
          <Modal.Title>Site Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>Select Clients...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formEmployees">
              <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
              <Form.Select as="select">
                <option>Select Site Group...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Sites</Form.Label>
              <Form.Select as="select">
                <option>Select Sites...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formStartDate">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Select type="date" placeholder="Start Date" />
            </Form.Group>

            <Form.Group controlId="formEndDate">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" placeholder="End Date" />
            </Form.Group>

            <Form.Group controlId="formOrderBy">
              <Form.Label style={{ float: "left" }}>Order By</Form.Label>
              <Form.Select as="select">
                <option>Select Sorting</option>
                <option>By Date & Time</option>
                <option>By Guard Name</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Send Report Via Email?" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseSiteRoster}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showShiftReport} onHide={handleCloseShiftReport}>
        <Modal.Header closeButton>
          <Modal.Title>Shift Status Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>Select Clients...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formEmployees">
              <Form.Label style={{ float: "left" }}>Site Group</Form.Label>
              <Form.Select as="select">
                <option>Select Site Group...</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Sites</Form.Label>
              <Form.Select as="select">
                <option>Select Sites...</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select as="select">
                <option>Select Staff...</option>
                <option>Employee</option>
                <option>Subcontractors</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formClients">
              <Form.Label style={{ float: "left" }}>Status</Form.Label>
              <Form.Select as="select">
                <option>Select Status...</option>
                <option>Confirmed</option>
                <option>Un Confirmed</option>
                <option>Un Assigned</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formStartDate">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Select type="date" placeholder="Start Date" />
            </Form.Group>

            <Form.Group controlId="formEndDate">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" placeholder="End Date" />
            </Form.Group>

            <Form.Group controlId="formOrderBy">
              <Form.Label style={{ float: "left" }}>Order By</Form.Label>
              <Form.Select as="select">
                <option>Select Sorting</option>
                <option>By Date & Time</option>
                <option>By Guard Name</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Send Report Via Email?" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseShiftReport}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWeekTime} onHide={handleCloseWeekTime}>
        <Modal.Header closeButton>
          <Modal.Title>Client Employee Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Employee</Form.Label>
              <Form.Select as="select">
                <option>Select Employee...</option>
                <option>Deenl</option>
                <option>Deenl</option>
                <option>lione</option>
                <option>Mark H</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>Select Clients...</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formEmployees">
              <Form.Label style={{ float: "left" }}>Week</Form.Label>
              <Form.Control type="date" />{" "}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseWeekTime}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWeekRoster} onHide={handleCloseWeekRoster}>
        <Modal.Header closeButton>
          <Modal.Title>Weekly Roster View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Client Name</Form.Label>
              <Form.Select as="select">
                <option>All Clients</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Site Groups</Form.Label>
              <Form.Select as="select">
                <option>All Site Groups</option>
                <option>Site Group</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Site Name</Form.Label>
              <Form.Select as="select">
                <option>All Sites</option>
                <option>Site</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select as="select">
                <option>Select Staff...</option>
                <option>Employee</option>
                <option>Subcontractors</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Send Email?" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">PDF</Button>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseWeekRoster}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMasterRoster} onHide={handleCloseMasterRoster}>
        <Modal.Header closeButton>
          <Modal.Title>Master Roster Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Clients</Form.Label>
              <Form.Select as="select">
                <option>All Clients</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Site Groups</Form.Label>
              <Form.Select as="select">
                <option>All Site Groups</option>
                <option>Site Group</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Sites</Form.Label>
              <Form.Select as="select">
                <option>All Sites</option>
                <option>Site</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Staff</Form.Label>
              <Form.Select as="select">
                <option>Select Staff...</option>
                <option>Employee</option>
                <option>Subcontractors</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Show Remarks" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseMasterRoster}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showContracted} onHide={handleCloseContracted}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Contracted Hours Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Employees</Form.Label>
              <Form.Select as="select">
                <option>All Employees</option>
                <option>SGS</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>

            <Form.Group controlId="formSendReportVia">
              <Form.Check type="checkbox" label="Send Report Via Email ?" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseContracted}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showChaseReport} onHide={handleCloseChaseReport}>
        <Modal.Header closeButton>
          <Modal.Title>Chase Up History Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Search For</Form.Label>
              <Form.Select as="select">
                <option>Select...</option>
                <option>Client</option>
                <option>Site</option>
                <option>Employee</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Call Status</Form.Label>
              <Form.Select as="select">
                <option>All Calls</option>
                <option>Successful Calls</option>
                <option>Un Successful Calls</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}> Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseChaseReport}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTimestampReport} onHide={handleCloseTimestampReport}>
        <Modal.Header closeButton>
          <Modal.Title>Book On Off / Timestamp Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Search For</Form.Label>
              <Form.Select as="select">
                <option>Select...</option>
                <option>Client</option>
                <option>Site</option>
                <option>Employee</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Status</Form.Label>
              <Form.Select as="select">
                <option>Select Status...</option>
                <option>Completed</option>
                <option>Missed</option>
                <option>Skipped</option>
                <option>Upcoming</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>Start Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
            <Form.Group controlId="formGuardGroups">
              <Form.Label style={{ float: "left" }}>End Date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">EXCEL</Button>
          <Button variant="secondary" onClick={handleCloseTimestampReport}>
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <style>{customFontFace}</style>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700"
          rel="stylesheet"
          type="text/css"
        />
        <div
          id="kt_body"
          className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled aside-enabled dashboard-header"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
          <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
              <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper">
                <header
                  id="kt_header"
                  className="header align-items-stretch"
                  data-kt-sticky="true"
                  data-kt-sticky-name="header"
                  data-kt-sticky-offset='{"default": "200px", "lg": "300px"}'>
                  <div className="container-xxl d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center d-lg-none me-2">
                      <button
                        className="btn btn-icon btn-active-color-primary"
                        id="kt_aside_mobile_toggle"
                        onClick={toggleSidebar}>
                        <i className="fas fa-bars fs-1"></i>
                      </button>
                    </div>
                    <div
                      className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0"
                      style={{ marginRight: "20px" }}>
                      <img
                        alt="Logo"
                        src={SGS_LogoImg}
                        className="h-20px h-lg-35px "
                      />
                    </div>
                    {isMobile && (
                      <div className="mobile-notification">
                        <FontAwesomeIcon icon={faBell} className="text-white" />
                      </div>
                    )}

                    <div className="d-none d-lg-flex  align-items-stretch justify-content-between flex-lg-grow-1">
                      <nav
                        className="header-menu align-items-stretch"
                        data-kt-drawer="true">
                        <div
                          className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                          id="kt_header_menu"
                          data-kt-menu="true">
                          {menuItems.map((item) => {
                            return (
                              <>
                                {" "}
                                {hasaccessItem(item.role) && (
                                  <div
                                    key={item.title}
                                    style={{ marginRight: "8px" }}
                                    className="menu-item menu-lg-down-accordion me-lg-1"
                                    onMouseEnter={() =>
                                      handleMenuHover(item.title)
                                    }
                                    onMouseLeave={() => setActiveMenu(null)}
                                    onClick={() => handleMenuClick(item)}>
                                    <span className="menu-link py-3">
                                      <i
                                        className={`${item.icon} menu-icon`}></i>
                                      <span
                                        className="menu-title"
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "bold",
                                          marginLeft:'10px'
                                        }}>
                                        {item.title}
                                      </span>
                                      <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                    {activeMenu === item.title &&
                                      renderSubMenu(item.subMenu, [item.title])}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </nav>
                    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        menuItems={menuItems}
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
      />

      <div className="mobile-footer">
        {isMobile &&
          mobileFooterItems.map((item, index) => (
            <Button
              key={index}
              variant="outline-light"
              onClick={() =>
                item.onClick ? item.onClick() : navigate(item.path)
              }
              className="mobile-footer-item">
              <FontAwesomeIcon icon={item.icon} />
              <div>{item.title}</div>
            </Button>
          ))}
      </div>
    </>
  );
};
export default Dashboard;
