import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Employee/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "../../Dashboard/Dashboard";
import "./Add.css";
import { useLocation } from "react-router-dom";
import { Modal, Card, Form, Nav, Row, Col,  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faList, faTrash, faUsers, faCalendar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Tooltip,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    IconButton,
    TextField,
    Box,
    Button,
  } from '@mui/material';
  import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    ListAlt as ListAltIcon,
    Search as SearchIcon,
    FileDownload as FileDownloadIcon,
  } from '@mui/icons-material';


const Holiday = () => {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const [showHoliday, setShowHoliday] = useState(false);
    const [holidays, setHolidays] = useState([]);
    const [holidayForm, setHolidayForm] = useState({
        employee_id: '1',
        employee: '',
        start_date: '',
        end_date: '',
        rate_hrs: '',
        hrs_leave: '',
        status: '',
        company_branch: '1' 
    });

    const token = '115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258';

    useEffect(() => {
        fetchHolidays();
    }, []);
    
    const fetchHolidays = async () => {
        try {
            const response = await axios.post('https://srltd.megworld.in/api/show_holidays',
                {},{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            console.log("Response data:", response.data);
            
            if (response.data && Array.isArray(response.data.data)) {
                setHolidays(response.data.data);
            } else if (response.data && Array.isArray(response.data)) {
                setHolidays(response.data);
            } else {
                console.error("Unexpected data format:", response.data);
                setHolidays([]);
            }
        } catch (error) {
            console.error("Error fetching holidays:", error);
            setHolidays([]);
        }
    };
    
    const handleFormChange = (e) => {
        setHolidayForm({
            ...holidayForm,
            [e.target.name]: e.target.value
        });
    };

    const handleAddHoliday = async () => {
        try {
           const response= await axios.post('https://srltd.megworld.in/api/add_holidays', holidayForm, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log("Response data:",response)
            if(response.data.success){
                Swal.fire({
                    icon: 'success',
                    title: 'Holidays Updated',
                    text: 'Holidays has been updated successfully.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  });
            }
            setShowHoliday(false);
            fetchHolidays(); 
        } catch (error) {
            console.error("Error adding holiday:", error);
        }
    };

    const handleShowHoliday = () => {
        setShowHoliday(true);
    };

    const handleCloseHoliday = () => {
        setShowHoliday(false);
    };
    const location = useLocation();

    const getNavigationPath = () => {
      const pathParts = location.pathname.split('/').filter(Boolean);
      return [
        { name: 'Employee', bold: false },
        { name: 'Payroll', bold: false },
        { name: 'Holiday', bold: false },
        { name: 'Employee Holidays Details', bold: true }
      ];
    };
  
    const navigationPath = getNavigationPath();
    
  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
    return (
        <>
            <Dashboard />
            <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
     <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>    


<Box display="flex" justifyContent="flex-end" className="col-auto">
  <Button 
    onClick={handleShowHoliday} 
    variant="contained" 
    sx={{ 
      backgroundColor: '#3f51b5', 
      color: 'white', 
      padding: '8px 10px',
      marginLeft:'60px',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#303f9f',
      }
    }}
  >
    ADD HOLIDAYS
  </Button>
</Box>
            </div>

            <Modal show={showHoliday} onHide={handleCloseHoliday}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Holidays</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Employee</Form.Label>
                            <Form.Select
                                name="employee"
                                value={holidayForm.employee}
                                onChange={handleFormChange}
                            >
                                <option>Select Employee</option>
                                <option>Deenl Mario</option>
                                <option>Lione M</option>
                                <option>Mark Nudd H</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Holiday Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                value={holidayForm.start_date}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Holiday End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="end_date"
                                value={holidayForm.end_date}

                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Pay Rate Per Hour</Form.Label>
                            <Form.Control
                                type="number"
                                name="rate_hrs"
                                value={holidayForm.rate_hrs}

                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Total Hours Per Leave</Form.Label>
                            <Form.Control
                                type="number"
                                name="hrs_leave"
                                value={holidayForm.hrs_leave}

                                onChange={handleFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ float: "left" }}>Holiday Status</Form.Label>
                            <Form.Select
                                name="status"
                                value={holidayForm.status}

                                onChange={handleFormChange}
                            >
                                <option>Select Status</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Reject">Reject</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHoliday}>
                        CLOSE
                    </Button>
                    <Button variant="primary" onClick={handleAddHoliday}>
                        ADD HOLIDAYS
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card className="mb-4">
                <Card.Body>
                    <Form className="mb-4">
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        value={selectedEmployee}
                                        onChange={(e) => setSelectedEmployee(e.target.value)}
                                    >
                                        <option>Select employee</option>
                                        {/* Placeholder options for employee */}
                                        <option value="1">Employee 1</option>
                                        <option value="2">Employee 2</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        value={currentYear}
                                        onChange={(e) => setCurrentYear(e.target.value)}
                                    >
                                        <option>Current Year</option>
                                        {/* Placeholder options for year */}
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Button variant="primary" block>SEARCH HOLIDAYS</Button>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <h5 className="card-title">HOLIDAYS OVERVIEW</h5>
                                    <Nav className="flex-column">
                                        <Nav.Link>Total Approved</Nav.Link>
                                        <Nav.Link>Approved & Taken</Nav.Link>
                                        <Nav.Link>Approved & Untaken Yet</Nav.Link>
                                        <Nav.Link>Remaining Holidays</Nav.Link>
                                        <Nav.Link>Entitlement</Nav.Link>
                                    </Nav>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={9}>
    {/* <Table striped bordered hover>
        <thead>
            <tr>
                <th>S. No</th>
                <th>EMPLOYEE</th>
                <th>START DATE</th>
                <th>END DATE</th>
                <th>TOTAL HOURS</th>
                <th>HOURS LEAVE</th>
                <th>STATUS</th>
                <th>ACTIONS</th>
            </tr>
        </thead>
        <tbody>
            {holidays.length > 0 ? (
                holidays.map((holiday, index) => (  
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{holiday.employee}</td>
                        <td>{holiday.start_date}</td>
                        <td>{holiday.end_date}</td>
                        <td>{holiday.rate_hrs}</td>
                        <td>{holiday.hrs_leave}</td>
                        <td>{holiday.status}</td>
                        <td>
                            <button className="btn-sm btn-success me-1">
                                <i className="fas fa-pen" style={{ color: "white" }}></i>
                            </button>
                            <button className="btn-sm btn-danger me-2">
                                <i className="fas fa-trash" style={{ color: "white" }}></i>
                            </button>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="8" className="text-center">No holidays found</td>
                </tr>
            )}
        </tbody>
    </Table> */}

    <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Employee</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Start Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>End Date</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Total Hours</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Hours Leave</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Status</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{padding:'8px'}}>
              { holidays.map((holiday, index) => ( 
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                                        <TableCell sx={{ padding: '5px' }}>{index+1}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.employee}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.start_date}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.end_date}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.rate_hrs}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.hrs_leave}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{holiday.status}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>

                    <Tooltip 
    title="Edit Holiday" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    className="me-2"

    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'blue', 
      '&:hover': { backgroundColor: 'darkgreen' } 
    }}
  >
    <EditIcon />
  </IconButton>
  </Tooltip>
  <Tooltip 
    title="Delete Holiday" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                    
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
</Col>

                    </Row>

                    <Button variant="info" block>VIEW YEARLY CALENDAR</Button>
                </Card.Body>
            </Card>
        </>
    );
};




const SidebarIcon = ({ icon, label, active }) => (
    <Nav.Link
        className={`text-center py-3 ${active ? 'bg-secondary' : ''}`}
        title={label}
    >
        <FontAwesomeIcon icon={icon} size="lg" color="white" />
    </Nav.Link>
);

export default Holiday;
