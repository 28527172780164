import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboard from "../../../Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import "./Subcontro.css";
import { Modal, Form } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';

const SubView = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   filteredData();
  // }, [data, searchTerm]);

  const fetchData = async (id) => {
    try {
      const response = await axios.post("https://srltd.megworld.in/api/sub_contract_show", {id:id}, {
        headers: {
          Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
        }
      });
      setData(response.data.data);
      setFilteredData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = data.filter((datas) =>
      Object.values(datas).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  }, [searchTerm, data]);


  // const filterData = () => {
  //   const filtered = data.filter(item =>
  //     Object.values(item).some(value =>
  //       value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredData(filtered);
  // };

  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  // };
  
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/sub_contract_store",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        console.log("Response:",response.data)
        if (response.data.success) {
          Swal.fire(
            'Deleted!',
            'The Employee has been deleted.',
            'success'
          );
          fetchData();
        } else {
          throw new Error(response.data.message || "Failed to delete Employee");
        }
      }
    } catch (error) {
      console.error("Delete Employee Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Employee.'
      });
    }
  };


  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Employee', bold: false },
      { name: 'Subcontractors', bold: false },
      { name: 'Current Subcontractors', bold: true }
    ];
  };

  const navigationPath = getNavigationPath();

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subcontractors");
    XLSX.writeFile(workbook, "subemployees_data.xlsx");
  };

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>          
      </div>

      {/* <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                Show
                <select
                  className="form-select d-inline-block w-auto mx-2"
                  value={entriesPerPage}
                  onChange={(e) => setEntriesPerPage(Number(e.target.value))}
                >
                  {[10,50,100,500].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                entries
              </div>
              <div className="d-flex align-items-center">
              <button
              style={{padding:'5px 10px',borderRadius:'5px'}}
                  className="btn-success me-2"
                  onClick={exportToExcel}
                >
                  <i className="fas fa-file-excel" style={{color:'white',fontSize:'25px'}}></i>
                </button>
              <Button
                  variant="primary"
                  className="m-0"
                  onClick={handleSearch}>
                  Search
                </Button>
                <input
                  type="text"
                  className="form-control ms-2"
                  style={{ width: "200px" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={isSearching}
                  placeholder="Search..."
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Company Name</th>
                    <th>Company Address</th>
                    <th>Contact Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Contact Department</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">Loading...</td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">{error}</td>
                    </tr>
                  ) : filteredData.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center" style={{ fontSize: "15px" }}>
                        No data available in table....
                      </td>
                    </tr>
                  ) : (
                    filteredData.slice(0, entriesPerPage).map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.subcon_company_name}</td>
                        <td>{item.subcon_company_address}</td>
                        <td>{item.subcon_contact_person}</td>
                        <td>{item.subcon_contact_number}</td>
                        <td>{item.subcon_email}</td>
                        <td>{item.subcon_contact_depart}</td>
                        <td>
                        <Link to="/subviewdetails">
                            <button className="btn-sm btn-success me-1">
                              <i
                                className="fas fa-file"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                          </Link>
                          <button className="btn-sm btn-danger me-1" 
                            onClick={() => handleDelete(item.id)}

                          >
                              <i
                                className="fas fa-trash"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                        </td>
                      </tr>
                      
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p>
                Showing 1 to {Math.min(entriesPerPage, filteredData.length)} of {filteredData.length} entries
              </p>
              <nav>
                <ul className="pagination mb-0">
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div> */}


      <Box sx={{ width: '100%', mt: 5 , display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'90%', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '15px'}}>S.NO</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Company Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Company  Address</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Name</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Number</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Email</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Contact Department</TableCell>
                  <TableCell sx={{ fontSize: '15px'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{padding:'8px'}}>
                {filteredData
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((item, index) => (
                  <TableRow key={index} sx={{textAlign:'center'}} hover>
                    <TableCell  sx={{ padding: '5px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{item.subcon_company_name}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{item.subcon_company_address}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{item.subcon_contact_person}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{item.subcon_contact_number}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{item.subcon_email}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>{item.subcon_contact_depart}</TableCell>
                    <TableCell sx={{ padding: '5px' }}>
                    <Tooltip 
    title="Subcontractor View" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >                 
  <IconButton 
    component={Link} 
    to="/subviewdetails" 
    className="me-2"
    size="small" 
    sx={{ 
      color: 'white', 
      backgroundColor: 'green', 

      '&:hover': { backgroundColor: 'darkblue' } 
    }}
  >
    <VisibilityIcon />
  </IconButton>
  </Tooltip>  
  
  <Tooltip 
    title="Subcontractor Delete" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
  <IconButton 
onClick={() => handleDelete(item.id)}    size="small" 
    className="me-2"
    sx={{ 
      color: 'white', 
      backgroundColor: 'red', 
      '&:hover': { backgroundColor: 'darkred' } 
    }}
  >
    <DeleteIcon />
  </IconButton>
  </Tooltip>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10,50,100,500]}
            component="div"
            count={filteredData.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default SubView;