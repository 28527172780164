import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  FormControl,
  InputGroup,
  Table,
  Alert,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import "./SiaType.css";

const AwardingBodies = () => {
  const [awardingType, setAwardingType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [newAwardingType, setNewAwardingType] = useState("");

  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";
  // const apiUrl = "https://srltd.megworld.in/api";

  useEffect(() => {
    fetchAwardingTypes();
  }, []);

  const fetchAwardingTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.post("https://srltd.megworld.in/api/awarding_show", {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      
      console.log("Fetch Response:", response.data);
  
      if (response.data && response.data.success && Array.isArray(response.data.data)) {
        // Filter out any items that don't have the required properties
        const validData = response.data.data.filter(item => 
          item && typeof item === 'object' && 'id' in item && 'awarding_bodies' in item && 'company_branch' in item
        );
        setTableData(validData);
        if (validData.length === 0) {
          setError("No valid awarding types found in the response");
        }
      } else {
        setError("Failed to fetch awarding types or invalid data format");
      }
      
    } catch (err) {
      console.error("Error fetching awarding types:", err);
      setError("Error fetching awarding types: " + (err.response?.data?.message || err.message));
    } finally {
      setLoading(false);
    }
  };


  const handleAddAwardingType = async () => {
    if (awardingType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://srltd.megworld.in/api/awarding_store",
          {
            awarding_bodies: awardingType,
            company_branch: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        console.log("API Response:", response.data); // Log the entire response for debugging
  
        if (response.data && response.data.success) {
          if (response && typeof response === 'object') {
            setTableData(prevData => [...prevData, response]);
            setAwardingType("");
            Swal.fire({
              icon: 'success',
              title: 'Awarding Body Added',
              text: 'New Awarding Body has been added successfully.',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            });
            fetchAwardingTypes();
          } else {
            setError("Failed to add Awarding Body: Response data is missing or invalid");
          }
        } else {
          setError("Failed to add Awarding Body: " + (response.data.message || "Unknown error"));
        }
      } catch (err) {
        console.error("Error adding Awarding Body:", err);
        setError("Error adding Awarding Body: " + (err.response?.data?.message || err.message));
      } finally {
        setLoading(false);
      }
    }
  };


  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const response = await axios.post("https://srltd.megworld.in/api/awarding_store", {
            delete_id: id,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data && response.data.success) {
            Swal.fire(
              'Deleted!',
              'The awarding body has been deleted.',
              'success'
            );
            setTableData(prevData => prevData.filter(item => item.id !== id));
          } else {
            setError("Failed to delete awarding body");
          }
        } catch (err) {
          setError("Error deleting awarding body");
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (data) => {
    setEditData(data);
    setNewAwardingType(data.awarding_bodies);
    setEditModalShow(true);
  };

  const handleSaveEdit = async () => {
    if (newAwardingType.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.post("https://srltd.megworld.in/api/awarding_store", {
          id: editData.id,
          awarding_bodies:newAwardingType,
          company_branch: editData.company_branch,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.success) {
          setEditModalShow(false);
          Swal.fire({
            icon: 'success',
            title: 'Awarding Body Updated',
            text: 'The awarding body has been updated successfully.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
          setTableData(prevData => 
            prevData.map(item => 
              item.id === editData.id ? {...item, awarding_bodies: newAwardingType} : item
            )
          );
        } else {
          setError("Failed to update awarding body");
        }
      } catch (err) {
        setError("Error updating awarding body");
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Empty Field',
        text: 'Please enter a new awarding body name.'
      });
    }
  };

  return (
    <div className="page-container">
      <div className="common-container">
        <h3>Awarding Bodies</h3>
        <InputGroup className="Group mb-3">
          <FormControl
            placeholder="Awarding Body Name"
            value={awardingType}
            onChange={(e) => setAwardingType(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddAwardingType} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "ADD"}
          </Button>
        </InputGroup>
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
      <div className="table-container">
  {tableData.length > 0 ? (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Awarding Body</th>
          <th>Branch</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((data, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data.awarding_bodies}</td>
            <td>{data.company_branch}</td>
            <td>
              <Button
                variant="primary"
                size="sm"
                className="me-1"
                onClick={() => handleEdit(data)}
              >
                <i className="fas fa-edit" style={{ color: "white" }}></i>
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDelete(data.id)}
              >
                <i className="fas fa-trash" style={{ color: "white" }}></i>
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <p>No awarding bodies found.</p>
  )}
</div>

      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Awarding Body</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formAwardingBody">
            <Form.Label>Awarding Body Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new awarding body name"
              value={newAwardingType}
              onChange={(e) => setNewAwardingType(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AwardingBodies;