import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, ListGroup, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./SiaType.css";

const Threatlevel = () => {
  const [threatLevel, setThreatLevel] = useState("");
  const [threatLevelsList, setThreatLevelsList] = useState([]);

  const threatLevelOptions = [
    "LOW",
    "Moderate",
    "Substantial",
    "Severe",
    "Critical",
  ];

  const handleAddThreatLevel = () => {
    if (threatLevel !== "") {
      setThreatLevelsList([...threatLevelsList, threatLevel]);
      setThreatLevel("");
    }
  };

  return (
    <div className="page-container">
      <div className="common-container">
        <h3>Threat Level</h3>
        <Form.Group className="mb-3">
          <Form.Select
            value={threatLevel}
            onChange={(e) => setThreatLevel(e.target.value)}
          >
            <option value="">Select Threat Level</option>
            {threatLevelOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Button variant="primary" onClick={handleAddThreatLevel}>
          ADD
        </Button>
        <ListGroup className="mt-3">
          {threatLevelsList.map((level, index) => (
            <ListGroup.Item key={index}>{level}</ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div className="table-container">
        <h3>Threat Level</h3>
        <Table striped bordered hover>
          <thead>
            <tr style={{ color: "red" }}>No Threat level selected...</tr>
          </thead>
        </Table>
      </div>
    </div>
  );
};

export default Threatlevel;
