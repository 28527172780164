import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import axios from "axios";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
} from '@mui/material';
import {
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import Dashboard from "../../../Dashboard/Dashboard";
import 'react-datepicker/dist/react-datepicker.css';

const EventsReport = () => {
  const location = useLocation();
  const componentRef = useRef();
  const [eventData, setEventData] = useState([]);
  const [filteredEventData, setFilteredEventData] = useState([]);
  const [eventType, setEventType] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  useEffect(() => {
    fetchEventData();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [eventType, startDate, endDate, eventData]);

  const fetchEventData = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/event_report', {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      if (response.data.success) {
        setEventData(response.data.data);
        setFilteredEventData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const handleFilter = () => {
    const filtered = eventData.filter(event => {
      const eventDate = new Date(event.event_date);
      const typeMatch = eventType === "" || event.event_type.toLowerCase().includes(eventType.toLowerCase());
      const dateMatch = (!startDate || eventDate >= startDate) && (!endDate || eventDate <= endDate);
      return typeMatch && dateMatch;
    });
    setFilteredEventData(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setEventType("");
    setDateRange([null, null]);
    setFilteredEventData(eventData);
    setCurrentPage(0);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Events Report',
    onAfterPrint: () => alert('Print successful!'),
  });

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEventData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Events");
    XLSX.writeFile(workbook, "EventsReport.xlsx");
  };

  const getNavigationPath = () => {
    return [
      { name: 'Report', bold: false },
      { name: 'EventsReport', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  return (
    <>
      <Dashboard />
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      <div className="container w-100">
      <Form>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Event Type</Form.Label>
                <Form.Control 
                  type="text" 
                  value={eventType}
                  onChange={(e) => setEventType(e.target.value)}
                  style={{width:'200px',padding:'5px'}} 
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label style={{float:'left'}}>Date</Form.Label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  style={{width:'200px'}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
            <Button type="button" variant="contained" color="secondary" className="me-2" style={{padding:'5px 20px'}}>Filter</Button>
              <Button type="button" variant="contained" color="secondary" onClick={handleReset} className="me-2" style={{padding:'5px 20px'}}>Reset</Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Box sx={{ width: '100%', mt:5, display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <Paper sx={{width:'84%', mb: 2 }} ref={componentRef}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  {['S.NO', 'Event Type', 'Date', 'Time', 'Event Details'].map((header) => (
                    <TableCell key={header} sx={{ fontSize: '13px', padding: '4px 8px' }}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEventData
                  .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
                  .map((event, index) => (
                    <TableRow key={index} sx={{fontSize:'13px'}} hover>
                      <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{event.event_type}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{event.event_date}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{event.event_time}</TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>{event.event_details}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredEventData.length}
            rowsPerPage={entriesPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setEntriesPerPage(parseInt(event.target.value, 10));
              setCurrentPage(0);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default EventsReport;