import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Checklist.css";


const InterviewCheck = () => {
  const [checklistData, setChecklistData] = useState(null);
  const token = "115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258";

  useEffect(() => {
    fetchChecklistData();
  }, []);

  const fetchChecklistData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/show_interview_checklist",
        { employee_id: "1" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response Data:", response.data);

      if (response.data && response.data.success) {
        setChecklistData(response.data.data);
      } else {
        console.error("Failed to fetch interview checklist data");
      }
    } catch (error) {
      console.error("Error fetching interview checklist data:", error);
    }
  };

  const renderChecklistItem = (item, index) => (
    <div key={index} className="checklist-item">
      <div className="characteristic">
        <strong>Characteristic:</strong>
        <span style={{color:'black'}}>{item.characteristics}</span>
      </div>
      <div className="assessment">
        <strong>Assessment:</strong>
        <span  style={{color:'black'}}>{item.assignment}</span>
      </div>
      <div className="comments">
        <strong>Comments:</strong>
        <span  style={{color:'black'}}>{item.comments}</span>
      </div>
    </div>
  );

  return (
    <div className="interview-checklist">
    <h3>Interview Checklist</h3>
    {checklistData ? (
      <div className="checklist-container">
        {Array.isArray(checklistData) ? (
          checklistData.map((item, index) => renderChecklistItem(item, index))
        ) : (
          <p>Checklist data is not in the expected format.</p>
        )}
        {checklistData.suitable && (
          <div className="suitable"><strong>Suitable:</strong> {checklistData.suitable}</div>
        )}
        {checklistData.additional_comments && (
          <div className="additional-comments">
            <strong>Additional Comments:</strong> {checklistData.additional_comments}
          </div>
        )}
      </div>
    ) : (
      <p className="no-data">No Interview checklist found...</p>
    )}
  </div>
  );
};

export default InterviewCheck;