import React, { useState } from "react";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';


const SIAKnowledge = () => {
  const [answers, setAnswers] = useState(Array(11).fill(""));

  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  };

  const headerStyle = {
    color: "#333",
    marginBottom: "20px",
  };

  const questionRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  };

  const questionStyle = {
    flex: "1",
    marginRight: "20px",
    fontSize: "15px",
  };

  const inputStyle = {
    width: "200px",
    padding: "5px 10px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
  };

  const questions = [
    "What do you call the Private Security Industry's Governing Body ?",
    "Can you carry out Licensable activities without your SIA Licence badge ?",
    "How long does an SIA licence valid for",
    "What should you do if you have lost your licence",
    "What should you tell if you move address",
    "What should you do with your SIA Licence when you are at work",
    "What should you do if you see a person attempt to steal from your site",
    "What should you do if someone shouting or threatening you whilst at work",
    "What is your main role as a Security Officer",
    "Write down a Hazard that you might expect to experience every day at work",
    "What do you call the document that tells you what to do in the event of an Incident on site",
  ];

  const handleInputChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = questions.map((question, index) => ({
      questions: question,
      answers: answers[index],
    }));

    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/add_sia_knowledge",
        {
          employee_id: "1",
          data: data,
        },
        {
          headers: {
            Authorization:
              "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258",
          },
        }
      );
      console.log("Data submitted successfully:", response);
      if(response.data.success){
        Swal.fire({
          icon: 'success',
          title: 'SIAKnowledge Added',
          text: 'SIAKnowledge  has been Added successfully.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
      // You can add a success message or redirect here
    } catch (error) {
      console.error("Error submitting data:", error);
      // You can add an error message here
    }
  };

  return (
    <Container style={containerStyle}>
      <h3 style={headerStyle}>SIA Knowledge Questionnaire</h3>
      <h4 style={headerStyle}>
        Manned Guarding Licence Linked Qualification Test:
      </h4>

      <Form onSubmit={handleSubmit}>
        <div style={questionRowStyle}>
          <h5 style={questionStyle}>Question</h5>
          <h5 style={{ width: "200px", textAlign: "center" }}>Answer Given</h5>
        </div>

        {questions.map((question, index) => (
          <div key={index} style={questionRowStyle}>
            <p style={questionStyle}>
              {index + 1}. {question}
            </p>
            <Form.Control
              type="text"
              style={inputStyle}
              value={answers[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          </div>
        ))}

        <Button type="submit" variant="primary" style={{ marginTop: "20px" }}>
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default SIAKnowledge;