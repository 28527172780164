import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import headerBackground from "../Invoice/patterns/header-bg.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "../../Dashboard/Dashboard";
import { Modal, Form } from "react-bootstrap";
import {useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
const PayrollPayments = () => {
  const [showPayments, setShowPayments] = useState(false);

  const handleShowPayments = () => setShowPayments(true);
  const handleClosePayments = () => setShowPayments(false);
  const location = useLocation();

  const getNavigationPath = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return [
      { name: 'Accounts', bold: false },
      { name: 'Payroll', bold: false },
      { name: 'Manage Staff Payments', bold: true },
    ];
  };
  
  const navigationPath = getNavigationPath();
  return (
    <>
      <Dashboard />
      <div
        className="toolbar py-2"
        id="kt_toolbar"
        style={{backgroundColor: '#BF0404' }}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}> {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal',color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}  
            </React.Fragment>
          ))}</h3>         
          <div className="col-auto d-flex flex-wrap gap-2">
            
            <Button
  variant="contained"
  color="primary"
  onClick={handleShowPayments}
  sx={{ padding: '8px', marginLeft: '130px' }}
>
Show Records
</Button>
          </div>
        <Modal show={showPayments} onHide={handleClosePayments} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Search Payments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: "left" }}>Search By</Form.Label>
                <Form.Select>
                  <option>Select...</option>
                  <option>Payrolls</option>
                  <option>Employees</option>
                  <option>Clients</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary">SEARCH</Button>
            <Button variant="secondary" onClick={handleClosePayments}>
              CLOSE
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PayrollPayments;
