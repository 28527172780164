import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import Dashboard from '../../Dashboard/Dashboard';

const ExportEmployee = () => {
    const location = useLocation();

    const getNavigationPath = () => {
      const pathParts = location.pathname.split('/').filter(Boolean);
      return [
        { name: 'Master', bold: false },
        { name: 'Employee', bold: false },
        { name: 'Import Employee', bold: true }
      ];
    };

    const [file, setFile] = useState(null);
  
    const navigationPath = getNavigationPath();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleImport = () => {
        if (file) {
            console.log('File to be imported:', file);
            // Implement your import logic here
        } else {
            alert('Please select a file before importing.');
        }
    };

    const handleClose = () => {
        // Implement your close logic here
        console.log('Close button clicked');
    };

    const handleDownloadFormat = () => {
        // Implement your download logic here
        console.log('Download Example Format clicked');
    };

    return (
        <>
            <Dashboard />
            <div className="toolbar py-2" id="kt_toolbar" style={{ backgroundColor: '#BF0404' }}>
                <div className="">
                    <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
                        {navigationPath.map((part, index) => (
                            <React.Fragment key={index}>
                                <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                                    {part.name}
                                </span>
                                {index < navigationPath.length - 1 && " / "}  
                            </React.Fragment>
                        ))}
                    </h3>
                </div>
            </div>
            <div className="container mt-5">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h4 className="card-title mb-0">Please Enter Valid Data</h4>
                        <button onClick={handleDownloadFormat} className="btn btn-primary">
                            Download Example Format
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="importCustomers" className="form-label" style={{float:'left'}}>Import Customers*</label>
                            <div className="input-group">
                                <input 
                                    type="file" 
                                    className="form-control" 
                                    id="importCustomers" 
                                    accept=".csv"
                                    onChange={handleFileChange}
                                />
                                <label className="input-group-text" htmlFor="importCustomers">Choose File</label>
                            </div>
                            <div className="form-text text-danger">Note: File must be in CSV format.</div>
                        </div>
                        <div className="mt-4">
                            <button onClick={handleImport} className="btn btn-success me-2">Import</button>
                            <button onClick={handleClose} className="btn btn-warning">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExportEmployee;