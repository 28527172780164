import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form,Button as BootstrapButton, Modal } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Clock as ClockIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
  Print as PrintIcon,} from '@mui/icons-material';
import Dashboard from "../../../Dashboard/Dashboard";


const ClientHoursAnalysis = () => {
    const location = useLocation();
    const componentRef = useRef();
  const getNavigationPath = () => {
    return [
      { name: 'Report', bold: false },
      { name: 'Finance Report (Invoice)', bold: false },
      { name: 'Client Hours Analysis', bold: true },

    ];
  };

  const navigationPath = getNavigationPath();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Client Hours Analysis',
    onAfterPrint: () => alert('Print successful!'),
  });

//   const handleFilter = (e) => {
//     e.preventDefault();
//     const filtered = shiftData.filter(shift => 
//       shift &&
//       (clientName === "" || (shift.shift_client_name && shift.shift_client_name.toLowerCase().includes(clientName.toLowerCase()))) &&
//       (siteName === "" || (shift.shift_site_name && shift.shift_site_name.toLowerCase().includes(siteName.toLowerCase()))) &&
//       (date === "" || shift.shift_start_date === date)
//     );
//     setFilteredShiftData(filtered);
//   };
//   const handleReset = () => {
//     setClientName("");
//     setSiteName("");
//     setDate("");
//     setFilteredShiftData(shiftData);
//   };


// const exportToExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(shiftData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Roster");
//     XLSX.writeFile(workbook, "ClientRoster.xlsx");
//   };

  return (
    <>
    <Dashboard/>
    <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
      <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
        {navigationPath.map((part, index) => (
          <React.Fragment key={index}>
            <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
              {part.name}
            </span>
            {index < navigationPath.length - 1 && " / "}
          </React.Fragment>
        ))}
      </h3>
    </div>
<div className="container w-100">
    <Form>
      <Row className="mb-3">
  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>Clients</Form.Label>
      <Form.Control 
        type="text" 
        // value={clientName}
        // onChange={(e) => setClientName(e.target.value)}
        style={{width:'200px',padding:'5px'}} 
      />
    </Form.Group>
  </Col>

  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>Sites</Form.Label>
      <Form.Control 
        type="text" 
        // value={clientName}
        // onChange={(e) => setClientName(e.target.value)}
        style={{width:'200px',padding:'5px'}} 
      />
    </Form.Group>
  </Col>


  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>Date</Form.Label>
      <Form.Control 
        type="date" 
        // value={date}
        // onChange={(e) => setDate(e.target.value)}
        style={{width:'200px',padding:'5px'}} 
      />
    </Form.Group>
  </Col>
</Row>
        <Row className="mb-3">
          <Col>
            <BootstrapButton type="submit" variant="primary" className="me-2" style={{padding:'5px 20px'}}>Filter</BootstrapButton>
            <BootstrapButton type="button" variant="secondary" className="me-2" style={{padding:'5px 20px'}}>Reset</BootstrapButton>
            {/* <BootstrapButton variant="success" style={{padding:'8px 5px',paddingLeft:'8px'}}><i className="fas fa-file-excel" style={{fontSize:'25px'}}></i></BootstrapButton> */}
          </Col>
        </Row>
      </Form>
     
    {/* <TablePagination
      rowsPerPageOptions={[10, 50, 100, 500]}
      component="div"
      count={filteredShiftData.length}
      rowsPerPage={entriesPerPage}
      page={currentPage}
      onPageChange={(event, newPage) => setCurrentPage(newPage)}
      onRowsPerPageChange={(event) => {
        setEntriesPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
      }}
    /> */}
      </div>
      <Box sx={{ width: '100%', mt:5, display: 'flex', alignItems:'center',justifyContent:'center'}}>
      <Paper sx={{width:'84%', mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
     
      <Button
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        // onClick={exportToExcel}
      >
        Export to Excel
      </Button>
      <Button
              variant="contained"
              color="secondary"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
            </Button>
    </Box>
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {['S.NO','Clients','Site Group','Sites','Start Date','End Date'].map((header) => (
              <TableCell key={header} sx={{ fontSize: '13px', padding: '4px 8px' }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {filteredShiftData
            .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
            .map((shift, index) => (
              <TableRow key={index} hover>
                <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_client_name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_site_name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_assign_staff}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_type}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_break ? 'Yes' : 'No'}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_comments}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_instructions}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                  <IconButton
                    onClick={() => handleEdit(shift)}
                    className="me-2"
                    size="small"
                    sx={{
                      color: 'white',
                      backgroundColor: 'blue',
                      '&:hover': { backgroundColor: 'darkgreen' }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleView(shift)}
                    className="me-2"
                    size="small"
                    sx={{
                      color: 'white',
                      backgroundColor: 'green',
                      '&:hover': { backgroundColor: 'darkblue' }
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    className="me-2"
                    size="small"
                    sx={{
                      color: 'white',
                      backgroundColor: 'green',
                      '&:hover': { backgroundColor: 'darkblue' }
                    }}
                  >
                    <AccessTimeIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(shift.id)}
                    size="small"
                    className="me-2"
                    sx={{
                      color: 'white',
                      backgroundColor: 'red',
                      '&:hover': { backgroundColor: 'darkred' }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))} */}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
    </Box>
    </>
  )
}

export default ClientHoursAnalysis;
