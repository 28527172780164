import React from 'react'
import { FormSelect , InputGroup} from 'react-bootstrap'

const UpdateRates = () => {
  return (
    <div className="common-container">
    <h3>make Selection For Rate Changes</h3>
    <InputGroup className="Group">
      <FormSelect
      type="text">
       <option>Select Section</option>
       <option>Client</option>
       <option>Site Group</option>
       <option>Sites</option>
       <option>Employees</option>

       </FormSelect>
    </InputGroup>

  </div>
  )
}

export default UpdateRates
