import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import Dashboard from "../Dashboard/Dashboard";
import "./Roster.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Select from 'react-select';
import {
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Clock as ClockIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';


const ClientRoster = () => {
  const location = useLocation();
  // ... other code

  useEffect(() => {
    fetchShiftData();

    // Check if there's updated data from EditClientRoster
    if (location.state && location.state.updatedShift) {
      const updatedShift = location.state.updatedShift;
      setShiftData(prevData => 
        prevData.map(shift => 
          shift.id === updatedShift.id ? updatedShift : shift
        )
      );
      setFilteredShiftData(prevData => 
        prevData.map(shift => 
          shift.id === updatedShift.id ? updatedShift : shift
        )
      );
      // Clear the state to avoid unnecessary updates
      window.history.replaceState({}, document.title);
    }
  }, [location]);
  
  const [shiftData, setShiftData] = useState([]);
  const [filteredShiftData, setFilteredShiftData] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientName, setClientName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [date, setDate] = useState("");
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const getNavigationPath = () => {
    return [
      { name: 'Roster', bold: false },
      { name: 'Client Roster', bold: true },
    ];
  };

  const navigationPath = getNavigationPath();

  useEffect(() => {
    const filtered = shiftData.filter((shift) =>
      Object.values(shift).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredShiftData(filtered);
    setCurrentPage(0);
  }, [searchTerm, shiftData]);

  useEffect(() => {
    fetchShiftData();
  }, []);
  
  const fetchShiftData = async () => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/client_roaster', {}, {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
        }
      });
      console.log("Client Roster:", response);
      
      if (response.data && response.data.data) {
        const newShiftData = JSON.parse(localStorage.getItem('newShiftData'));
        
        if (newShiftData) {
          const updatedShiftData = [newShiftData, ...response.data.data];
          setShiftData(updatedShiftData);
          setFilteredShiftData(updatedShiftData);
          localStorage.removeItem('newShiftData');
        } else {
          setShiftData(response.data.data);
          setFilteredShiftData(response.data.data);
        }
      } else {
        console.error('Invalid response format:', response);
      }
    } catch (error) {
      console.error('Error fetching shift data:', error);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const filtered = shiftData.filter(shift => 
      shift &&
      (clientName === "" || (shift.shift_client_name && shift.shift_client_name.toLowerCase().includes(clientName.toLowerCase()))) &&
      (siteName === "" || (shift.shift_site_name && shift.shift_site_name.toLowerCase().includes(siteName.toLowerCase()))) &&
      (date === "" || shift.shift_start_date === date)
    );
    setFilteredShiftData(filtered);
  };
  const handleReset = () => {
    setClientName("");
    setSiteName("");
    setDate("");
    setFilteredShiftData(shiftData);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(shiftData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Roster");
    XLSX.writeFile(workbook, "ClientRoster.xlsx");
  };

  const handleView = async (shift) => {
    try {
      const response = await axios.post('https://srltd.megworld.in/api/view_client_roaster_new', 
        { id: shift.id },
        {
          headers: {
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          }
        }
      );
      if (response.data && response.data.success && response.data.data) {
        setSelectedShift(response.data.data);
        setSelectedShiftId(shift.id);
        setShowViewModal(true);
      } else {
        throw new Error(response.data?.message || 'Failed to fetch shift details');
      }
    } catch (error) {
      console.error('Error fetching shift details:', error);
      Swal.fire('Error', 'Failed to fetch shift details', 'error');
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          "https://srltd.megworld.in/api/add_shift",
          { delete_id: id },
          {
            headers: {
              Authorization: `Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258`,
            },
          }
        );
        console.log("Response:", response.data);
        if (response.data.success) {
          Swal.fire('Deleted!', 'The Client has been deleted.', 'success');
          fetchShiftData();
        } else {
          throw new Error(response.data.message || "Failed to delete Client Roster");
        }
      }
    } catch (error) {
      console.error("Delete Client Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while deleting Client Roster.'
      });
    }
  };

  const handleEdit = (shift) => {
    navigate('/editclientroster', { state: { id: shift } });
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteStaff = async (id) => {
    // if (isDeleting) return; // Prevent multiple delete requests

    setIsDeleting(true);

    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/staff_delete',
        { delete_id: id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          },
        }
      );

      if (response.data.success) {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
        // onStaffDeleted(staffId); // Callback to update parent component's state
      } else {
        throw new Error(response.data.message || 'Failed to delete staff');
      }
    } catch (error) {
      console.error('Error deleting staff:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the staff member.',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const [formData, setFormData] = useState({
    id: '',
    shift_title: '',
    shift_client_name: '',
    shift_site_name: '',
    shift_assign_staff: '',
    shift_assign_subemp: '',
    shift_pay_amount: '',
    shift_pay_expenses: '',
    shift_bill_rate: '',
    shift_bill_amount: '',
    shift_bill_expenses: '',
    shift_type: '',
    shift_penalty: '',
    shift_start_date: '',
    shift_end_date: '',
    shift_start_time: '',
    shift_end_time: '',
    shift_break: false,
    shift_check_call: false,
    shift_unpaid: false,
    shift_training: false,
    shift_confirm: false,
    shift_unconfirm: false,
    shift_comments: '',
    shift_instructions: '',
    company_branch: '1',
  });

  const [selectedStaffData,setSelectedStaffData] = useState([]);
  const [showStaffModal,setShowStaffModal] = useState(false);
  const [subStaffData,setSubStaffData] = useState([]);

  const handleShowStaffModal = ()=>{
    setShowStaffModal(true);
  }

  const handleCloseStaffModal = ()=>{
    setShowStaffModal(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (id) => {
    try {
      const response = await axios.post(
        'https://srltd.megworld.in/api/view_client_roaster_new',
        { id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258'
          },
        }
      );
      
         console.log("View Staff Response:",response)
         if (response.data && response.data.success) {
          setSelectedStaffData(response.data.data);
          setSubStaffData(response.data.data.staff || []);
      } else {
        console.error('Received data is not an array:', response.data.data);
        setSubStaffData([]);
      }
    } catch (error) {
      console.error('Error fetching staff data:', error);
      setSubStaffData([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // useEffect(() => {
  //   if (selectedShift && selectedShift.id) {
  //     addStaffData(prev => ({
  //       ...prev,
  //       id: selectedShift.id
  //     }));
  //   }
  // }, [selectedShift]);

const [addStaffData,setAddStaffData] = useState({
  id:'',
  shift_assign_staff: '',
  shift_assign_subemp: '',
})



const handleAddStaff = async () => {
  try {
    const staffData = {
      id: selectedShiftId, // Use the stored ID from the viewed shift
      shift_assign_staff: addStaffData.shift_assign_staff,
      shift_assign_subemp: addStaffData.shift_assign_subemp
    };

    const response = await axios.post(
      'https://srltd.megworld.in/api/staff_delete',
      staffData,
      {
        headers: {
          'Authorization': 'Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258',
          "Content-Type": "application/json",
        }
      }
    );

    if (response.data.success) {
      Swal.fire({
        icon: 'success',
        title: 'Staff Added',
        text: 'Staff has been added successfully.',
      });
      fetchData(selectedShiftId); // Refresh the staff data
      handleCloseStaffModal();
    }
  } catch (error) {
    console.error('Error adding staff:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while adding staff.',
    });
  }
};



  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);

  useEffect(() => {
    fetchStaffData();
  }, []);

  const fetchStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/shift_staff",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Staff Data:", response);
      setStaffOptions(response.data.data);
      staffOptions.map(staff=>({
        value: staff.name,
        label: staff.name,     
      }))
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const handleStaffChange = (selectedOptions) => {
    setSelectedStaff(selectedOptions);
    setAddStaffData(prevState => ({
      ...prevState,
      shift_assign_staff: selectedOptions.map(option => option.label).join(', ')
    }));
  };

  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubStaff, setSelectedSubStaff] = useState([]);
  
  useEffect(() => {
    fetchSubStaffData();
  }, []);
  
  const fetchSubStaffData = async () => {
    try {
      const response = await axios.post(
        "https://srltd.megworld.in/api/sub_employee_show",
        {},
        {
          headers: {
            Authorization: "Bearer 115|sKlxNjiXPYraSRbaCAEuqYkh470isCOk1mXk4STOc8b65258"
          }
        }
      );
      console.log("Subcontractor Data:", response);
      const formattedOptions = response.data.data.map(staff => ({
        value: staff.subcon_company_name,
        label: staff.subcon_company_name,
      }));
      setSubOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };
  
  const handleSubStaffChange = (selectedSubOptions) => {
    setSelectedSubStaff(selectedSubOptions);
    setAddStaffData(prevState => ({
      ...prevState,
      shift_assign_subemp: selectedSubOptions.map(option => option.label).join(', ')
    }));
  };

  const tooltipStyles = {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 12px',
      borderRadius: '4px',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  };
  return (
    <>
      <Dashboard />
      <div className="toolbar py-2" id="kt_toolbar" style={{backgroundColor: '#BF0404'}}>
        <h3 className="text-white" style={{fontSize:'15px',color:'white'}}>
          {navigationPath.map((part, index) => (
            <React.Fragment key={index}>
              <span style={{ fontWeight: part.bold ? 'bold' : 'normal', color:'white', fontSize: part.bold ? '18px' : '15px' }}>
                {part.name}
              </span>
              {index < navigationPath.length - 1 && " / "}
            </React.Fragment>
          ))}
        </h3>
      </div>
      {/* <Container fluid className="mt-4">
      <Form onSubmit={handleFilter}>
      <Row className="mb-3">
  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>CLIENT NAME</Form.Label>
      <Form.Control 
        type="text" 
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
        className="rounded-input"
      />
    </Form.Group>
  </Col>
  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>SITE NAME</Form.Label>
      <Form.Control 
        type="text" 
        value={siteName}
        onChange={(e) => setSiteName(e.target.value)}
        className="rounded-input"
      />
    </Form.Group>
  </Col>
  <Col md={4}>
    <Form.Group>
      <Form.Label style={{float:'left'}}>DATE</Form.Label>
      <Form.Control 
        type="date" 
        value={date}
        onChange={(e) => setDate(e.target.value)}
        className="rounded-input"
      />
    </Form.Group>
  </Col>
</Row>
        <Row className="mb-3">
          <Col>
            <Button type="submit" variant="primary" className="me-2" style={{padding:'8px 30px'}}>FILTER</Button>
            <Button type="button" variant="secondary" className="me-2" style={{padding:'8px 30px'}} onClick={handleReset}>RESET</Button>
            <Button variant="success" style={{padding:'8px 5px',paddingLeft:'8px'}}   onClick={exportToExcel}><i className="fas fa-file-excel" style={{fontSize:'25px'}}></i></Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col> */}
          {/* <div className="table-responsive"> */}
            {/* <Table striped bordered hover >
              <thead>
                <tr className="bg-primary text-white" style={{fontSize:'13px'}}>
                  <th>S.NO</th>
                  <th>Client Name</th>
                  <th>Site Name</th>
                  <th>Staff Name</th>
                  <th>Shift Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Break</th>
                  <th>Comments</th>
                  <th>Instructions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredShiftData.map((shift, index) => (
                  <tr key={index} style={{fontSize:'13px'}}>
                    <td>{index + 1}</td>
                    <td>{shift.shift_client_name}</td>
                    <td>{shift.shift_site_name}</td>
                    <td>{shift.shift_assign_staff}</td>
                    <td>{shift.shift_type}</td>
                    <td>{shift.shift_start_date}</td>
                    <td>{shift.shift_end_date}</td>
                    <td>{shift.shift_start_time}</td>
                    <td>{shift.shift_end_time}</td>
                    <td>{shift.shift_break ? 'Yes' : 'No'}</td>
                    <td>{shift.shift_comments}</td>
                    <td>{shift.shift_instructions}</td>
                    <td>
            <Button variant="primary" style={{padding:'5px 8px'}} className="me-2" onClick={() => handleEdit(shift)}>
  <i className="fas fa-edit"></i>
</Button>           
             <Button variant="success" onClick={() => handleView(shift)}  style={{padding:'5px 8px'}}  className="me-2"><i className="fas fa-eye"></i></Button>
                        <Button variant="warning" style={{padding:'5px 8px'}} className="me-2"><i className="fas fa-clock" ></i></Button>
                        <Button variant="danger" onClick={() => handleDelete(shift.id)}  style={{padding:'5px 10px'}} className="me-2"><i className="fas fa-trash-alt"></i></Button>

                      </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
            {/* <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Client Name</Form.Label>
              <Form.Control 
                type="text" 
                value={selectedShift?.shift_client_name || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_client_name: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Site Name</Form.Label>
              <Form.Control 
                type="text" 
                value={selectedShift?.shift_site_name || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_site_name: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Staff Name</Form.Label>
              <Form.Control 
                type="text" 
                value={selectedShift?.shift_assign_staff || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_assign_staff: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Shift Type</Form.Label>
              <Form.Control 
                type="text" 
                value={selectedShift?.shift_type || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_type: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Start Date</Form.Label>
              <Form.Control 
                type="date" 
                value={selectedShift?.shift_start_date || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_start_date: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>End Date</Form.Label>
              <Form.Control 
                type="date" 
                value={selectedShift?.shift_end_date || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_end_date: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Start Time</Form.Label>
              <Form.Control 
                type="time" 
                value={selectedShift?.shift_start_time || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_start_time: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>End Time</Form.Label>
              <Form.Control 
                type="time" 
                value={selectedShift?.shift_end_time || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_end_time: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Shift Comments</Form.Label>
              <Form.Control 
                type="text" 
                value={selectedShift?.shift_comments || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_comments: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{float:'left'}}>Shift Instructions</Form.Label>
              <Form.Control 
                type="text" 
                value={selectedShift?.shift_instructions || ''}
                onChange={(e) => setSelectedShift({...selectedShift, shift_instructions: e.target.value})}
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}

          {/* </div> */}

          <Box sx={{ width: '100%', mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Paper sx={{ width: '95%', mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={exportToExcel}
      >
        Export to Excel
      </Button>
    </Box>
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {['S.NO','Shift Title', 'Client Name', 'Site Name', 'Staff Name', 'Shift Type', 'Start Date', 'End Date', 'Start Time', 'End Time', 'Break', 'Comments', 'Instructions', 'Actions'].map((header) => (
              <TableCell key={header} sx={{  fontSize: '15px', padding: '4px 8px' }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredShiftData
            .slice(currentPage * entriesPerPage, currentPage * entriesPerPage + entriesPerPage)
            .map((shift, index) => (
              <TableRow key={index} hover>
                <TableCell sx={{ padding: '4px 8px' }}>{currentPage * entriesPerPage + index + 1}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_title}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_client_name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_site_name}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_assign_staff}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_type}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_date}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_start_time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_end_time}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_break ? 'Yes' : 'No'}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_comments}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>{shift.shift_instructions}</TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                <Tooltip 
    title="Edit Client Roster" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
                  <IconButton
                    onClick={() => handleEdit(shift)}
                    className="me-2"
                    size="small"
                    sx={{
                      color: 'white',
                      backgroundColor: 'blue',
                      '&:hover': { backgroundColor: 'darkgreen' }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  </Tooltip>

                  <Tooltip 
    title="View Client Roster" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
                  <IconButton
                    onClick={() => handleView(shift)}
                    className="me-2"
                    size="small"
                    sx={{
                      color: 'white',
                      backgroundColor: 'green',
                      '&:hover': { backgroundColor: 'darkblue' }
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  </Tooltip>

                  <Link to="/timestamp">
                  <Tooltip 
    title="View Timestamp" 
    arrow 
    placement="top"
    componentsProps={{
      tooltip: { sx: tooltipStyles.tooltip },
      arrow: { sx: tooltipStyles.arrow },
    }}
  >
                  <IconButton
                    className="me-2"
                    size="small"
                    sx={{
                      color: 'white',
                      backgroundColor: 'orange',
                      '&:hover': { backgroundColor: 'darkorange' }
                    }}
                  >
                    <AccessTimeIcon />
                  </IconButton>
                  </Tooltip>

                  </Link>
                 

<Tooltip 
title="Delete Client Roster" 
arrow 
placement="top"
componentsProps={{
tooltip: { sx: tooltipStyles.tooltip },
arrow: { sx: tooltipStyles.arrow },
}}
>
                  <IconButton
                    onClick={() => handleDelete(shift.id)}
                    size="small"
                    className="me-2"
                    sx={{
                      color: 'white',
                      backgroundColor: 'red',
                      '&:hover': { backgroundColor: 'darkred' }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 50, 100, 500]}
      component="div"
      count={filteredShiftData.length}
      rowsPerPage={entriesPerPage}
      page={currentPage}
      onPageChange={(event, newPage) => setCurrentPage(newPage)}
      onRowsPerPageChange={(event) => {
        setEntriesPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
      }}
    />
  </Paper>
</Box>


          <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>View Shift Details</Modal.Title>
    {/* <IconButton aria-label="add"  sx={{ marginRight: 1 }}>
        <AddIcon />
      </IconButton> */}
  </Modal.Header>
  <Modal.Body>
    {selectedShift && selectedShift.main && selectedShift.main[0] && (
      <>
        <div className="row">
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th>Shift Title</th>
                  <td>{selectedShift.main[0].shift_title}</td>
                </tr>
              <tr>
                  <th>Staff Name</th>
                  <td>{selectedShift.main[0].shift_assign_staff}</td>
                </tr>
                <tr>
                  <th>Client Name</th>
                  <td>{selectedShift.main[0].shift_client_name}</td>
                </tr>
                <tr>
                  <th>Site Name</th>
                  <td>{selectedShift.main[0].shift_site_name}</td>
                </tr>
                <tr>
                  <th>Shift Type</th>
                  <td>{selectedShift.main[0].shift_type}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th>Pay Rate</th>
                  <td>{selectedShift.main[0].shift_pay_rate}</td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>{selectedShift.main[0].shift_start_date}</td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>{selectedShift.main[0].shift_end_date}</td>
                </tr>
                <tr>
                  <th>Comments</th>
                  <td>{selectedShift.main[0].shift_comments}</td>
                </tr>
                <tr>
                  <th>Instructions</th>
                  <td>{selectedShift.main[0].shift_instructions}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th>Bill Rate</th>
                  <td>{selectedShift.main[0].shift_bill_rate}</td>
                </tr>
                <tr>
                  <th>Pay Amount</th>
                  <td>{selectedShift.main[0].shift_pay_amount}</td>
                </tr>
                <tr>
                  <th>Pay Expenses</th>
                  <td>{selectedShift.main[0].shift_pay_expenses}</td>
                </tr>
                <tr>
                  <th>Break</th>
                  <td>{selectedShift.main[0].shift_break}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-bordered">
              <tbody>
              <tr>
                  <th>Start Time</th>
                  <td>{selectedShift.main[0].shift_start_time}</td>
                </tr>
                <tr>
                  <th>End Time</th>
                  <td>{selectedShift.main[0].shift_end_time}</td>
                </tr>
                <tr>
                  <th>Bill Amount</th>
                  <td>{selectedShift.main[0].shift_bill_amount}</td>
                </tr>
                <tr>
                  <th>Bill Expenses</th>
                  <td>{selectedShift.main[0].shift_bill_expenses}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="position-relative">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4 className="m-0">Staff Details</h4>
        <IconButton
          size="small"
          onClick={handleShowStaffModal}
          sx={{
            color: 'white',
            backgroundColor: 'green',
            '&:hover': { backgroundColor: 'darkgreen' }
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Staff Name</TableCell>
            <TableCell>SubContractor Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subStaffData.map((staffMember, index) => (
            <TableRow key={index}>
              <TableCell>{staffMember.shift_assign_staff || 'Unnamed Staff'}</TableCell>
              <TableCell>{staffMember.shift_assign_staff || 'Unnamed Staff'}</TableCell>
              <TableCell>{staffMember.status}</TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  className="me-2"
                  sx={{
                    color: 'white',
                    backgroundColor: 'red',
                    '&:hover': { backgroundColor: 'darkred' }
                  }}
                  onClick={() => handleDeleteStaff(staffMember.id)}
                  disabled={isDeleting}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Modal show={showStaffModal} onHide={handleCloseStaffModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Control 
            type="hidden" 
            name="id" 
            value={selectedShiftId} 
          />
        <Form.Group  controlId="staff">
              <Form.Label style={{float:'left' }}>Staff</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}         name="shift_assign_staff"
value={selectedStaff}
onChange={handleStaffChange}
options={staffOptions}
isMulti
                isSearchable
                placeholder="Select Staff Name..."
              />
            </Form.Group>
        <Form.Group controlId="SubContractor">
              <Form.Label style={{ float:'left'}}>SubContractor</Form.Label>
              <Select
 styles={{
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
 
}}      

name="shift_assign_subemp"
value={selectedSubStaff}
onChange={handleSubStaffChange}
options={subOptions}
isMulti
                isSearchable
                placeholder="Select Staff Name..."
              />
            </Form.Group>
         
          
       
     
       
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button  variant="contained"  onClick={handleAddStaff}
  color="primary">
          Add 
        </Button>
        <Button variant="secondary" onClick={handleCloseStaffModal}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
      </>
    )}
  </Modal.Body>
</Modal>
        {/* </Col>
      </Row>
      </Container> */}
    </>
  );
};

export default ClientRoster;